:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #121212;
  --toastify-color-info: #3498db;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-color-transparent: #ffffffb3;
  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);
  --toastify-toast-width: 320px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;
  --toastify-text-color-light: #757575;
  --toastify-text-color-dark: #fff;
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;
  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;
  --toastify-color-progress-light: linear-gradient(to right, #4cd964, #5ac8fa, #007aff, #34aadc, #5856d6, #ff2d55);
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}

.Toastify__toast-container {
  z-index: var(--toastify-z-index);
  -webkit-transform: translate3d(0, 0, var(--toastify-z-index));
  width: var(--toastify-toast-width);
  box-sizing: border-box;
  color: #fff;
  padding: 4px;
  position: fixed;
}

.Toastify__toast-container--top-left {
  top: 1em;
  left: 1em;
}

.Toastify__toast-container--top-center {
  top: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--top-right {
  top: 1em;
  right: 1em;
}

.Toastify__toast-container--bottom-left {
  bottom: 1em;
  left: 1em;
}

.Toastify__toast-container--bottom-center {
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

.Toastify__toast-container--bottom-right {
  bottom: 1em;
  right: 1em;
}

@media only screen and (width <= 480px) {
  .Toastify__toast-container {
    width: 100vw;
    margin: 0;
    padding: 0;
    left: 0;
  }

  .Toastify__toast-container--top-left, .Toastify__toast-container--top-center, .Toastify__toast-container--top-right {
    top: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--bottom-left, .Toastify__toast-container--bottom-center, .Toastify__toast-container--bottom-right {
    bottom: 0;
    transform: translateX(0);
  }

  .Toastify__toast-container--rtl {
    right: 0;
    left: initial;
  }
}

.Toastify__toast {
  min-height: var(--toastify-toast-min-height);
  box-sizing: border-box;
  max-height: var(--toastify-toast-max-height);
  font-family: var(--toastify-font-family);
  cursor: default;
  direction: ltr;
  z-index: 0;
  border-radius: 4px;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: 0 1px 10px #0000001a, 0 2px 15px #0000000d;
}

.Toastify__toast--rtl {
  direction: rtl;
}

.Toastify__toast--close-on-click {
  cursor: pointer;
}

.Toastify__toast-body {
  flex: auto;
  align-items: center;
  margin: auto 0;
  padding: 6px;
  display: flex;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
  flex: 1;
}

.Toastify__toast-icon {
  -webkit-margin-end: 10px;
  flex-shrink: 0;
  width: 20px;
  margin-inline-end: 10px;
  display: flex;
}

.Toastify--animate {
  animation-duration: .7s;
  animation-fill-mode: both;
}

.Toastify--animate-icon {
  animation-duration: .3s;
  animation-fill-mode: both;
}

@media only screen and (width <= 480px) {
  .Toastify__toast {
    border-radius: 0;
    margin-bottom: 0;
  }
}

.Toastify__toast-theme--dark {
  background: var(--toastify-color-dark);
  color: var(--toastify-text-color-dark);
}

.Toastify__toast-theme--light, .Toastify__toast-theme--colored.Toastify__toast--default {
  background: var(--toastify-color-light);
  color: var(--toastify-text-color-light);
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  color: var(--toastify-text-color-info);
  background: var(--toastify-color-info);
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  color: var(--toastify-text-color-success);
  background: var(--toastify-color-success);
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  color: var(--toastify-text-color-warning);
  background: var(--toastify-color-warning);
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  color: var(--toastify-text-color-error);
  background: var(--toastify-color-error);
}

.Toastify__progress-bar-theme--light {
  background: var(--toastify-color-progress-light);
}

.Toastify__progress-bar-theme--dark {
  background: var(--toastify-color-progress-dark);
}

.Toastify__progress-bar--info {
  background: var(--toastify-color-progress-info);
}

.Toastify__progress-bar--success {
  background: var(--toastify-color-progress-success);
}

.Toastify__progress-bar--warning {
  background: var(--toastify-color-progress-warning);
}

.Toastify__progress-bar--error {
  background: var(--toastify-color-progress-error);
}

.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning, .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
  background: var(--toastify-color-transparent);
}

.Toastify__close-button {
  color: #fff;
  cursor: pointer;
  opacity: .7;
  background: none;
  border: none;
  outline: none;
  align-self: flex-start;
  padding: 0;
  transition: all .3s;
}

.Toastify__close-button--light {
  color: #000;
  opacity: .3;
}

.Toastify__close-button > svg {
  fill: currentColor;
  width: 14px;
  height: 16px;
}

.Toastify__close-button:hover, .Toastify__close-button:focus {
  opacity: 1;
}

@keyframes Toastify__trackProgress {
  0% {
    transform: scaleX(1);
  }

  100% {
    transform: scaleX(0);
  }
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: .7;
  transform-origin: 0;
  width: 100%;
  height: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.Toastify__progress-bar--animated {
  animation: linear forwards Toastify__trackProgress;
}

.Toastify__progress-bar--controlled {
  transition: transform .2s;
}

.Toastify__progress-bar--rtl {
  right: 0;
  left: initial;
  transform-origin: 100%;
}

.Toastify__spinner {
  box-sizing: border-box;
  border: 2px solid;
  border-color: var(--toastify-spinner-color-empty-area);
  border-right-color: var(--toastify-spinner-color);
  border-radius: 100%;
  width: 20px;
  height: 20px;
  animation: .65s linear infinite Toastify__spin;
}

@keyframes Toastify__bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0);
  }

  75% {
    transform: translate3d(10px, 0, 0);
  }

  90% {
    transform: translate3d(-5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0);
  }

  75% {
    transform: translate3d(-10px, 0, 0);
  }

  90% {
    transform: translate3d(5px, 0, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

@keyframes Toastify__bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

@keyframes Toastify__bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0);
  }

  75% {
    transform: translate3d(0, -10px, 0);
  }

  90% {
    transform: translate3d(0, 5px, 0);
  }

  to {
    transform: none;
  }
}

@keyframes Toastify__bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.Toastify__bounce-enter--top-left, .Toastify__bounce-enter--bottom-left {
  animation-name: Toastify__bounceInLeft;
}

.Toastify__bounce-enter--top-right, .Toastify__bounce-enter--bottom-right {
  animation-name: Toastify__bounceInRight;
}

.Toastify__bounce-enter--top-center {
  animation-name: Toastify__bounceInDown;
}

.Toastify__bounce-enter--bottom-center {
  animation-name: Toastify__bounceInUp;
}

.Toastify__bounce-exit--top-left, .Toastify__bounce-exit--bottom-left {
  animation-name: Toastify__bounceOutLeft;
}

.Toastify__bounce-exit--top-right, .Toastify__bounce-exit--bottom-right {
  animation-name: Toastify__bounceOutRight;
}

.Toastify__bounce-exit--top-center {
  animation-name: Toastify__bounceOutUp;
}

.Toastify__bounce-exit--bottom-center {
  animation-name: Toastify__bounceOutDown;
}

@keyframes Toastify__zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes Toastify__zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.Toastify__zoom-enter {
  animation-name: Toastify__zoomIn;
}

.Toastify__zoom-exit {
  animation-name: Toastify__zoomOut;
}

@keyframes Toastify__flipIn {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

@keyframes Toastify__flipOut {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.Toastify__flip-enter {
  animation-name: Toastify__flipIn;
}

.Toastify__flip-exit {
  animation-name: Toastify__flipOut;
}

@keyframes Toastify__slideInRight {
  from {
    visibility: visible;
    transform: translate3d(110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-110%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -110%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@keyframes Toastify__slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(110%, 0, 0);
  }
}

@keyframes Toastify__slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-110%, 0, 0);
  }
}

@keyframes Toastify__slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 500px, 0);
  }
}

@keyframes Toastify__slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -500px, 0);
  }
}

.Toastify__slide-enter--top-left, .Toastify__slide-enter--bottom-left {
  animation-name: Toastify__slideInLeft;
}

.Toastify__slide-enter--top-right, .Toastify__slide-enter--bottom-right {
  animation-name: Toastify__slideInRight;
}

.Toastify__slide-enter--top-center {
  animation-name: Toastify__slideInDown;
}

.Toastify__slide-enter--bottom-center {
  animation-name: Toastify__slideInUp;
}

.Toastify__slide-exit--top-left, .Toastify__slide-exit--bottom-left {
  animation-name: Toastify__slideOutLeft;
}

.Toastify__slide-exit--top-right, .Toastify__slide-exit--bottom-right {
  animation-name: Toastify__slideOutRight;
}

.Toastify__slide-exit--top-center {
  animation-name: Toastify__slideOutUp;
}

.Toastify__slide-exit--bottom-center {
  animation-name: Toastify__slideOutDown;
}

@keyframes Toastify__spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  -webkit-animation-duration: var(--animate-duration);
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.animate__animated.animate__infinite {
  animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
  animation-iteration-count: 1;
  -webkit-animation-iteration-count: var(--animate-repeat);
  animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
  animation-iteration-count: 2;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
  animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
  animation-iteration-count: 3;
  -webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
  animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
  animation-delay: 1s;
  -webkit-animation-delay: var(--animate-delay);
  animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
  animation-delay: 2s;
  -webkit-animation-delay: calc(var(--animate-delay) * 2);
  animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
  animation-delay: 3s;
  -webkit-animation-delay: calc(var(--animate-delay) * 3);
  animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
  animation-delay: 4s;
  -webkit-animation-delay: calc(var(--animate-delay) * 4);
  animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
  animation-delay: 5s;
  -webkit-animation-delay: calc(var(--animate-delay) * 5);
  animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
  animation-duration: .5s;
  -webkit-animation-duration: calc(var(--animate-duration) / 2);
  animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
  animation-duration: .8s;
  -webkit-animation-duration: calc(var(--animate-duration) * .8);
  animation-duration: calc(var(--animate-duration) * .8);
}

.animate__animated.animate__slow {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
  animation-duration: 3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 3);
  animation-duration: calc(var(--animate-duration) * 3);
}

@media print, (prefers-reduced-motion: reduce) {
  .animate__animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }

  .animate__animated[class*="Out"] {
    opacity: 0;
  }
}

@keyframes bounce {
  from, 20%, 53%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0);
  }

  40%, 43% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -30px, 0)scaleY(1.1);
  }

  70% {
    animation-timing-function: cubic-bezier(.755, .05, .855, .06);
    transform: translate3d(0, -15px, 0)scaleY(1.05);
  }

  80% {
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
    transform: translate3d(0, 0, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -4px, 0)scaleY(1.02);
  }
}

.animate__bounce {
  transform-origin: bottom;
  animation-name: bounce;
}

@keyframes flash {
  from, 50%, to {
    opacity: 1;
  }

  25%, 75% {
    opacity: 0;
  }
}

.animate__flash {
  animation-name: flash;
}

@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1);
  }

  30% {
    transform: scale3d(1.25, .75, 1);
  }

  40% {
    transform: scale3d(.75, 1.25, 1);
  }

  50% {
    transform: scale3d(1.15, .85, 1);
  }

  65% {
    transform: scale3d(.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, .95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__rubberBand {
  animation-name: rubberBand;
}

@keyframes shakeX {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0);
  }
}

.animate__shakeX {
  animation-name: shakeX;
}

@keyframes shakeY {
  from, to {
    transform: translate3d(0, 0, 0);
  }

  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(0, -10px, 0);
  }

  20%, 40%, 60%, 80% {
    transform: translate3d(0, 10px, 0);
  }
}

.animate__shakeY {
  animation-name: shakeY;
}

@keyframes headShake {
  0% {
    transform: translateX(0);
  }

  6.5% {
    transform: translateX(-6px)rotateY(-9deg);
  }

  18.5% {
    transform: translateX(5px)rotateY(7deg);
  }

  31.5% {
    transform: translateX(-3px)rotateY(-5deg);
  }

  43.5% {
    transform: translateX(2px)rotateY(3deg);
  }

  50% {
    transform: translateX(0);
  }
}

.animate__headShake {
  animation-name: headShake;
  animation-timing-function: ease-in-out;
}

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg);
  }

  40% {
    transform: rotate3d(0, 0, 1, -10deg);
  }

  60% {
    transform: rotate3d(0, 0, 1, 5deg);
  }

  80% {
    transform: rotate3d(0, 0, 1, -5deg);
  }

  to {
    transform: rotate3d(0, 0, 1, 0);
  }
}

.animate__swing {
  transform-origin: top;
  animation-name: swing;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9)rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1)rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__tada {
  animation-name: tada;
}

@keyframes wobble {
  from {
    transform: translate3d(0, 0, 0);
  }

  15% {
    transform: translate3d(-25%, 0, 0)rotate3d(0, 0, 1, -5deg);
  }

  30% {
    transform: translate3d(20%, 0, 0)rotate3d(0, 0, 1, 3deg);
  }

  45% {
    transform: translate3d(-15%, 0, 0)rotate3d(0, 0, 1, -3deg);
  }

  60% {
    transform: translate3d(10%, 0, 0)rotate3d(0, 0, 1, 2deg);
  }

  75% {
    transform: translate3d(-5%, 0, 0)rotate3d(0, 0, 1, -1deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__wobble {
  animation-name: wobble;
}

@keyframes jello {
  from, 11.1%, to {
    transform: translate3d(0, 0, 0);
  }

  22.2% {
    transform: skewX(-12.5deg)skewY(-12.5deg);
  }

  33.3% {
    transform: skewX(6.25deg)skewY(6.25deg);
  }

  44.4% {
    transform: skewX(-3.125deg)skewY(-3.125deg);
  }

  55.5% {
    transform: skewX(1.5625deg)skewY(1.5625deg);
  }

  66.6% {
    transform: skewX(-.78125deg)skewY(-.78125deg);
  }

  77.7% {
    transform: skewX(.390625deg)skewY(.390625deg);
  }

  88.8% {
    transform: skewX(-.195313deg)skewY(-.195313deg);
  }
}

.animate__jello {
  transform-origin: center;
  animation-name: jello;
}

@keyframes heartBeat {
  0% {
    transform: scale(1);
  }

  14% {
    transform: scale(1.3);
  }

  28% {
    transform: scale(1);
  }

  42% {
    transform: scale(1.3);
  }

  70% {
    transform: scale(1);
  }
}

.animate__heartBeat {
  animation-name: heartBeat;
  animation-duration: 1.3s;
  -webkit-animation-duration: calc(var(--animate-duration) * 1.3);
  animation-duration: calc(var(--animate-duration) * 1.3);
  animation-timing-function: ease-in-out;
}

@keyframes backInDown {
  0% {
    opacity: .7;
    transform: translateY(-1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInDown {
  animation-name: backInDown;
}

@keyframes backInLeft {
  0% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInLeft {
  animation-name: backInLeft;
}

@keyframes backInRight {
  0% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInRight {
  animation-name: backInRight;
}

@keyframes backInUp {
  0% {
    opacity: .7;
    transform: translateY(1200px)scale(.7);
  }

  80% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__backInUp {
  animation-name: backInUp;
}

@keyframes backOutDown {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(700px)scale(.7);
  }
}

.animate__backOutDown {
  animation-name: backOutDown;
}

@keyframes backOutLeft {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(-2000px)scale(.7);
  }
}

.animate__backOutLeft {
  animation-name: backOutLeft;
}

@keyframes backOutRight {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateX(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateX(2000px)scale(.7);
  }
}

.animate__backOutRight {
  animation-name: backOutRight;
}

@keyframes backOutUp {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  20% {
    opacity: .7;
    transform: translateY(0)scale(.7);
  }

  100% {
    opacity: .7;
    transform: translateY(-700px)scale(.7);
  }
}

.animate__backOutUp {
  animation-name: backOutUp;
}

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  20% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  40% {
    transform: scale3d(.9, .9, .9);
  }

  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03);
  }

  80% {
    transform: scale3d(.97, .97, .97);
  }

  to {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}

.animate__bounceIn {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceIn;
}

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0)scaleY(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, -10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, 5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInDown {
  animation-name: bounceInDown;
}

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(-10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInLeft {
  animation-name: bounceInLeft;
}

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0)scaleX(3);
  }

  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0)scaleX(1);
  }

  75% {
    transform: translate3d(10px, 0, 0)scaleX(.98);
  }

  90% {
    transform: translate3d(-5px, 0, 0)scaleX(.995);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInRight {
  animation-name: bounceInRight;
}

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0)scaleY(5);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  75% {
    transform: translate3d(0, 10px, 0)scaleY(.95);
  }

  90% {
    transform: translate3d(0, -5px, 0)scaleY(.985);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__bounceInUp {
  animation-name: bounceInUp;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.animate__bounceOut {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: bounceOut;
}

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0)scaleY(3);
  }
}

.animate__bounceOutDown {
  animation-name: bounceOutDown;
}

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutLeft {
  animation-name: bounceOutLeft;
}

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0)scaleX(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0)scaleX(2);
  }
}

.animate__bounceOutRight {
  animation-name: bounceOutRight;
}

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0)scaleY(.985);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0)scaleY(.9);
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0)scaleY(3);
  }
}

.animate__bounceOutUp {
  animation-name: bounceOutUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animate__fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInDownBig {
  animation-name: fadeInDownBig;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInLeftBig {
  animation-name: fadeInLeftBig;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInRightBig {
  animation-name: fadeInRightBig;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUp {
  animation-name: fadeInUp;
}

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInUpBig {
  animation-name: fadeInUpBig;
}

@keyframes fadeInTopLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopLeft {
  animation-name: fadeInTopLeft;
}

@keyframes fadeInTopRight {
  from {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInTopRight {
  animation-name: fadeInTopRight;
}

@keyframes fadeInBottomLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomLeft {
  animation-name: fadeInBottomLeft;
}

@keyframes fadeInBottomRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__fadeInBottomRight {
  animation-name: fadeInBottomRight;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.animate__fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes fadeOutDownBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

.animate__fadeOutDownBig {
  animation-name: fadeOutDownBig;
}

@keyframes fadeOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__fadeOutLeft {
  animation-name: fadeOutLeft;
}

@keyframes fadeOutLeftBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0);
  }
}

.animate__fadeOutLeftBig {
  animation-name: fadeOutLeftBig;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__fadeOutRight {
  animation-name: fadeOutRight;
}

@keyframes fadeOutRightBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0);
  }
}

.animate__fadeOutRightBig {
  animation-name: fadeOutRightBig;
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__fadeOutUp {
  animation-name: fadeOutUp;
}

@keyframes fadeOutUpBig {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0);
  }
}

.animate__fadeOutUpBig {
  animation-name: fadeOutUpBig;
}

@keyframes fadeOutTopLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, -100%, 0);
  }
}

.animate__fadeOutTopLeft {
  animation-name: fadeOutTopLeft;
}

@keyframes fadeOutTopRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, -100%, 0);
  }
}

.animate__fadeOutTopRight {
  animation-name: fadeOutTopRight;
}

@keyframes fadeOutBottomRight {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 100%, 0);
  }
}

.animate__fadeOutBottomRight {
  animation-name: fadeOutBottomRight;
}

@keyframes fadeOutBottomLeft {
  from {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 100%, 0);
  }
}

.animate__fadeOutBottomLeft {
  animation-name: fadeOutBottomLeft;
}

@keyframes flip {
  from {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, -360deg);
  }

  40% {
    animation-timing-function: ease-out;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -190deg);
  }

  50% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 150px)rotate3d(0, 1, 0, -170deg);
  }

  80% {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(.95, .95, .95)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }

  to {
    animation-timing-function: ease-in;
    transform: perspective(400px)scale3d(1, 1, 1)translate3d(0, 0, 0)rotate3d(0, 1, 0, 0);
  }
}

.animate__animated.animate__flip {
  backface-visibility: visible;
  animation-name: flip;
}

@keyframes flipInX {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(1, 0, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInX {
  animation-name: flipInX;
  backface-visibility: visible !important;
}

@keyframes flipInY {
  from {
    opacity: 0;
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }

  40% {
    animation-timing-function: ease-in;
    transform: perspective(400px)rotate3d(0, 1, 0, -20deg);
  }

  60% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, 10deg);
  }

  80% {
    transform: perspective(400px)rotate3d(0, 1, 0, -5deg);
  }

  to {
    transform: perspective(400px);
  }
}

.animate__flipInY {
  animation-name: flipInY;
  backface-visibility: visible !important;
}

@keyframes flipOutX {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(1, 0, 0, -20deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(1, 0, 0, 90deg);
  }
}

.animate__flipOutX {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutX;
  backface-visibility: visible !important;
}

@keyframes flipOutY {
  from {
    transform: perspective(400px);
  }

  30% {
    opacity: 1;
    transform: perspective(400px)rotate3d(0, 1, 0, -15deg);
  }

  to {
    opacity: 0;
    transform: perspective(400px)rotate3d(0, 1, 0, 90deg);
  }
}

.animate__flipOutY {
  animation-duration: .75s;
  -webkit-animation-duration: calc(var(--animate-duration) * .75);
  animation-duration: calc(var(--animate-duration) * .75);
  animation-name: flipOutY;
  backface-visibility: visible !important;
}

@keyframes lightSpeedInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(-30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(20deg);
  }

  80% {
    transform: skewX(-5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInRight {
  animation-name: lightSpeedInRight;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(30deg);
  }

  60% {
    opacity: 1;
    transform: skewX(-20deg);
  }

  80% {
    transform: skewX(5deg);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__lightSpeedInLeft {
  animation-name: lightSpeedInLeft;
  animation-timing-function: ease-out;
}

@keyframes lightSpeedOutRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)skewX(30deg);
  }
}

.animate__lightSpeedOutRight {
  animation-name: lightSpeedOutRight;
  animation-timing-function: ease-in;
}

@keyframes lightSpeedOutLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)skewX(-30deg);
  }
}

.animate__lightSpeedOutLeft {
  animation-name: lightSpeedOutLeft;
  animation-timing-function: ease-in;
}

@keyframes rotateIn {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -200deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateIn {
  transform-origin: center;
  animation-name: rotateIn;
}

@keyframes rotateInDownLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateInDownLeft;
}

@keyframes rotateInDownRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateInDownRight;
}

@keyframes rotateInUpLeft {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateInUpLeft;
}

@keyframes rotateInUpRight {
  from {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -90deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rotateInUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateInUpRight;
}

@keyframes rotateOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 200deg);
  }
}

.animate__rotateOut {
  transform-origin: center;
  animation-name: rotateOut;
}

@keyframes rotateOutDownLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 45deg);
  }
}

.animate__rotateOutDownLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutDownLeft;
}

@keyframes rotateOutDownRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutDownRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutDownRight;
}

@keyframes rotateOutUpLeft {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, -45deg);
  }
}

.animate__rotateOutUpLeft {
  transform-origin: 0 100%;
  animation-name: rotateOutUpLeft;
}

@keyframes rotateOutUpRight {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: rotate3d(0, 0, 1, 90deg);
  }
}

.animate__rotateOutUpRight {
  transform-origin: 100% 100%;
  animation-name: rotateOutUpRight;
}

@keyframes hinge {
  0% {
    animation-timing-function: ease-in-out;
  }

  20%, 60% {
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 80deg);
  }

  40%, 80% {
    opacity: 1;
    animation-timing-function: ease-in-out;
    transform: rotate3d(0, 0, 1, 60deg);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 700px, 0);
  }
}

.animate__hinge {
  animation-duration: 2s;
  -webkit-animation-duration: calc(var(--animate-duration) * 2);
  animation-duration: calc(var(--animate-duration) * 2);
  transform-origin: 0 0;
  animation-name: hinge;
}

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform-origin: bottom;
    transform: scale(.1)rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  70% {
    transform: rotate(3deg);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animate__jackInTheBox {
  animation-name: jackInTheBox;
}

@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0)rotate3d(0, 0, 1, -120deg);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__rollIn {
  animation-name: rollIn;
}

@keyframes rollOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0)rotate3d(0, 0, 1, 120deg);
  }
}

.animate__rollOut {
  animation-name: rollOut;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes zoomInDown {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, -1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }
}

.animate__zoomInDown {
  animation-name: zoomInDown;
}

@keyframes zoomInLeft {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(-1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(10px, 0, 0);
  }
}

.animate__zoomInLeft {
  animation-name: zoomInLeft;
}

@keyframes zoomInRight {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(1000px, 0, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(-10px, 0, 0);
  }
}

.animate__zoomInRight {
  animation-name: zoomInRight;
}

@keyframes zoomInUp {
  from {
    opacity: 0;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.1, .1, .1)translate3d(0, 1000px, 0);
  }

  60% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }
}

.animate__zoomInUp {
  animation-name: zoomInUp;
}

@keyframes zoomOut {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  to {
    opacity: 0;
  }
}

.animate__zoomOut {
  animation-name: zoomOut;
}

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, -60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, 2000px, 0);
  }
}

.animate__zoomOutDown {
  transform-origin: bottom;
  animation-name: zoomOutDown;
}

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(-2000px, 0, 0);
  }
}

.animate__zoomOutLeft {
  transform-origin: 0;
  animation-name: zoomOutLeft;
}

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(.475, .475, .475)translate3d(-42px, 0, 0);
  }

  to {
    opacity: 0;
    transform: scale(.1)translate3d(2000px, 0, 0);
  }
}

.animate__zoomOutRight {
  transform-origin: 100%;
  animation-name: zoomOutRight;
}

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    animation-timing-function: cubic-bezier(.55, .055, .675, .19);
    transform: scale3d(.475, .475, .475)translate3d(0, 60px, 0);
  }

  to {
    opacity: 0;
    animation-timing-function: cubic-bezier(.175, .885, .32, 1);
    transform: scale3d(.1, .1, .1)translate3d(0, -2000px, 0);
  }
}

.animate__zoomOutUp {
  transform-origin: bottom;
  animation-name: zoomOutUp;
}

@keyframes slideInDown {
  from {
    visibility: visible;
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInDown {
  animation-name: slideInDown;
}

@keyframes slideInLeft {
  from {
    visibility: visible;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    visibility: visible;
    transform: translate3d(100%, 0, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInUp {
  from {
    visibility: visible;
    transform: translate3d(0, 100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInUp {
  animation-name: slideInUp;
}

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0);
  }
}

.animate__slideOutDown {
  animation-name: slideOutDown;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.animate__slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.animate__slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0);
  }
}

.animate__slideOutUp {
  animation-name: slideOutUp;
}

div.screen-too-small {
  background-color: #0009;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
}

div.screen-too-small h2 {
  text-align: center;
  max-width: 600px;
  padding: 0 20px;
  font-size: 36px;
}

div.screen-too-small h2 span {
  color: red;
  font-size: 56px;
}

@media screen and (width <= 3840px), (height <= 2160px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 65px !important;
    transform: translateZ(1px)scale(2.2) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: 0;
    transform: translateZ(1px)scale(1.8) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.how-to-play-panel {
    transform: translateZ(1px)scale(2) !important;
  }

  div.settings-panel {
    transform: translateZ(1px)scale(2.5) !important;
  }

  div.stats-panel, div.inventory-panel, div.vendors-panel, div.rankings-panel, div.change-username-panel, div.runinprogress-panel, div.register-panel, div.login-panel {
    transform: translateZ(1px)scale(2) !important;
  }

  div.partners-panel, div.patch-notes-panel {
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(2.5) !important;
  }

  div.game-hud div.server-event-feed {
    transform: translateZ(1px)scale(1.7) !important;
  }

  div.game-hud div.you-died-panel, div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.game-hud div.top-left-hud {
    transform-origin: 0 0;
    transform: translateZ(1px)scale(2) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: 18px !important;
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(2) !important;
  }

  div.game-hud div.extraction-bar {
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 175px !important;
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(2)translateY(-60%) !important;
  }
}

@media screen and (width <= 2560px), (height <= 1440px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 65px !important;
    transform: translateZ(1px)scale(1.5) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: 0;
    transform: translateZ(1px)scale(1.4) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.settings-panel {
    transform: translateZ(1px)scale(2) !important;
  }

  div.vendors-panel, div.rankings-panel, div.change-username-panel, div.inventory-panel, div.how-to-play-panel, div.stats-panel, div.runinprogress-panel, div.register-panel, div.login-panel {
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.partners-panel, div.patch-notes-panel {
    transform: translateZ(1px)scale(1.8) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(2.2) !important;
  }

  div.game-hud div.server-event-feed {
    transform: translateZ(1px)scale(1.3) !important;
  }

  div.game-hud div.you-died-panel, div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(1.8) !important;
  }

  div.game-hud div.top-left-hud {
    transform-origin: 0 0;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: 18px !important;
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(1.25) !important;
  }

  div.game-hud div.extraction-bar {
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 175px !important;
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(1.25)translateY(-60%) !important;
  }
}

@media screen and (width <= 1920px), (height <= 1080px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 50px !important;
    transform: translateZ(1px)scale(1.25) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: 0;
    transform: translateZ(1px)scale(1) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(1.3) !important;
  }

  div.settings-panel {
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.vendors-panel, div.rankings-panel, div.change-username-panel, div.inventory-panel, div.stats-panel, div.runinprogress-panel, div.register-panel, div.login-panel {
    transform: translateZ(1px)scale(1.3) !important;
  }

  div.partners-panel, div.patch-notes-panel {
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.how-to-play-panel {
    transform: translateZ(1px)scale(1.3) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(1.8) !important;
  }

  div.game-hud div.server-event-feed {
    transform: translateZ(1px)scale(.9) !important;
  }

  div.game-hud div.you-died-panel {
    transform: translateZ(1px)scale(1.4) !important;
  }

  div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(1.6) !important;
  }

  div.game-hud div.top-left-hud {
    transform-origin: 0 0;
    transform: translateZ(1px)scale(.75) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    transform: translateZ(1px)scale(1.15) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: 7px !important;
    transform: translateZ(1px)scale(1.15) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.game-hud div.extraction-bar {
    transform: translateZ(1px)scale(1.15) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 175px !important;
    transform: translateZ(1px)scale(1.5) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(1.1)translateY(-60%) !important;
  }
}

@media screen and (width <= 1600px), (height <= 900px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 50px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: 0;
    transform: translateZ(1px)scale(.9) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.settings-panel {
    transform: translateZ(1px)scale(1.3) !important;
  }

  div.vendors-panel, div.rankings-panel, div.change-username-panel, div.inventory-panel {
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.stats-panel {
    transform: translateZ(1px)scale(1.2) !important;
  }

  div.runinprogress-panel {
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.register-panel, div.login-panel {
    transform: translateZ(1px)scale(1.15) !important;
  }

  div.partners-panel {
    transform: translateZ(1px)scale(1.4) !important;
  }

  div.patch-notes-panel {
    transform: translateZ(1px)scale(1.2) !important;
  }

  div.how-to-play-panel {
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(1.3) !important;
  }

  div.game-hud div.server-event-feed {
    transform: translateZ(1px)scale(.9) !important;
  }

  div.game-hud div.you-died-panel {
    transform: translateZ(1px)scale(1.2) !important;
  }

  div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(1.4) !important;
  }

  div.game-hud div.top-left-hud {
    transform-origin: 0 0;
    transform: translateZ(1px)scale(.65) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: 5px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.extraction-bar {
    top: 175px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 165px !important;
    transform: translateZ(1px)scale(1.25) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(.9)translateY(-60%) !important;
  }
}

@media screen and (width <= 1376px), (height <= 768px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 50px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: -20px;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.settings-panel {
    transform: translateZ(1px)scale(1.2) !important;
  }

  div.vendors-panel, div.rankings-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.change-username-panel {
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.inventory-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.how-to-play-panel {
    transform: translateZ(1px)scale(.8) !important;
  }

  div.stats-panel, div.patch-notes-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(1.3) !important;
  }

  div.game-hud div.server-event-feed {
    transform: translateZ(1px)scale(.8) !important;
  }

  div.game-hud div.you-died-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(1.2) !important;
  }

  div.game-hud div.top-left-hud {
    transform-origin: 0 0;
    transform: translateZ(1px)scale(.6) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(.75) !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: 5px !important;
    transform: translateZ(1px)scale(.9) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.game-hud div.extraction-bar {
    top: 125px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 125px !important;
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(.8)translateY(-60%) !important;
  }
}

@media screen and (width <= 1129px), (height <= 616px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 50px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: 0;
    transform: translateZ(1px)scale(.7) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(.8) !important;
  }

  div.settings-panel {
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.vendors-panel, div.rankings-panel, div.change-username-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.inventory-panel {
    transform: translateZ(1px)scale(.8) !important;
  }

  div.how-to-play-panel {
    transform: translateZ(1px)scale(.75) !important;
  }

  div.stats-panel {
    transform: translateZ(1px)scale(.9) !important;
  }

  div.runinprogress-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.register-panel {
    transform: translateZ(1px)scale(.85) !important;
  }

  div.patch-notes-panel {
    transform: translateZ(1px)scale(.9) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(1.1) !important;
  }

  div.game-hud div.server-event-feed {
    transform: translateZ(1px)scale(.7) !important;
  }

  div.game-hud div.you-died-panel {
    transform: translateZ(1px)scale(.75) !important;
  }

  div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.top-left-hud {
    transform-origin: 0 0;
    transform: translateZ(1px)scale(.5) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(.65) !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: 5px !important;
    transform: translateZ(1px)scale(.9) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(.65) !important;
  }

  div.game-hud div.extraction-bar {
    top: 70px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 85px !important;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(.7)translateY(-60%) !important;
  }
}

@media screen and (width <= 939px), (height <= 504px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 50px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: 0;
    transform: translateZ(1px)scale(.6) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(.78) !important;
  }

  div.settings-panel, div.vendors-panel, div.rankings-panel, div.change-username-panel {
    transform: translateZ(1px)scale(.9) !important;
  }

  div.inventory-panel {
    transform: translateZ(1px)scale(.7) !important;
  }

  div.how-to-play-panel {
    transform: translateZ(1px)scale(.6) !important;
  }

  div.stats-panel {
    transform: translateZ(1px)scale(.75) !important;
  }

  div.runinprogress-panel {
    transform: translateZ(1px)scale(.9) !important;
  }

  div.login-panel {
    transform: translateZ(1px)scale(.85) !important;
  }

  div.register-panel, div.patch-notes-panel {
    transform: translateZ(1px)scale(.7) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(.89) !important;
  }

  div.game-hud div.server-event-feed {
    width: 500px !important;
    transform: translateZ(1px)scale(.6) !important;
  }

  div.game-hud div.you-died-panel {
    transform: translateZ(1px)scale(.65) !important;
  }

  div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(.85) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    transform: translateZ(1px)scale(.7) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(.65) !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: 5px !important;
    transform: translateZ(1px)scale(.9) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(.65) !important;
  }

  div.game-hud div.extraction-bar {
    top: 55px !important;
    transform: translateZ(1px)scale(1) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 85px !important;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(.6)translateY(-60%) !important;
  }
}

@media screen and (width <= 750px), (height <= 440px) {
  .Toastify__toast-container--top-center {
    transform-origin: 0 0;
    margin-top: 20px !important;
    transform: translateZ(1px)scale(.7) !important;
  }

  .Toastify__toast-container--top-center .Toastify__toast {
    position: relative;
    left: -155px;
  }

  div.main-menu div.center-content {
    position: relative;
    top: 0;
    transform: translateZ(1px)scale(.5) !important;
  }

  div.main-menu-right-container {
    transform-origin: 100%;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.main-menu-left-container {
    transform-origin: 0;
    transform: translateZ(1px)scale(.8) !important;
  }

  div.runprep-panel {
    transform: translateZ(1px)scale(.65) !important;
  }

  div.settings-panel, div.vendors-panel, div.rankings-panel, div.change-username-panel {
    transform: translateZ(1px)scale(.9) !important;
  }

  div.inventory-panel {
    transform: translateZ(1px)scale(.65) !important;
  }

  div.how-to-play-panel {
    transform: translateZ(1px)scale(.57) !important;
  }

  div.stats-panel {
    transform: translateZ(1px)scale(.65) !important;
  }

  div.runinprogress-panel, div.login-panel {
    transform: translateZ(1px)scale(.85) !important;
  }

  div.register-panel {
    transform: translateZ(1px)scale(.65) !important;
  }

  div.patch-notes-panel {
    transform: translateZ(1px)scale(.6) !important;
  }

  div.collection-log-panel {
    transform: translateZ(1px)scale(.7) !important;
  }

  div.game-hud div.server-event-feed {
    width: 500px !important;
    transform: translateZ(1px)scale(.5) !important;
  }

  div.game-hud div.you-died-panel {
    transform: translateZ(1px)scale(.62) !important;
  }

  div.game-hud div.extraction-successful-panel {
    transform: translateZ(1px)scale(.75) !important;
  }

  div.game-hud div.top-right-hud {
    transform-origin: 100% 0;
    display: none;
    transform: translateZ(1px)scale(.7) !important;
  }

  div.game-hud div.bottom-left-hud {
    transform-origin: 0 100%;
    transform: translateZ(1px)scale(.65) !important;
  }

  div.game-hud div.bottom-left-hud .inventory-texts {
    display: none !important;
  }

  div.game-hud div.extraction-time-remaining {
    top: -5px !important;
    transform: translateZ(1px)scale(.6) !important;
  }

  div.game-hud div.gear-and-weapon-hud {
    transform-origin: bottom;
    transform: translateZ(1px)scale(.5) !important;
  }

  div.game-hud div.extraction-bar {
    top: 55px !important;
    transform: translateZ(1px)scale(.6) !important;
  }

  div.game-hud div.region-pop-up {
    transform-origin: bottom;
    bottom: 80px !important;
    transform: translateZ(1px)scale(.6) !important;
  }

  div.game-hud div.left-inventory-containers {
    transform: translateZ(1px)scale(.6)translateY(-60%) !important;
  }
}

@media screen and (width <= 350px), screen and (height <= 350px) {
  div.screen-router {
    display: none !important;
  }

  div.screen-too-small {
    display: flex !important;
  }
}

html.mobile div.screen-router {
  display: none !important;
}

html.mobile div.screen-too-small {
  display: flex !important;
}

body.en div.main-menu-profile-panel button.neutral-button.inventory-btn, body.en div.main-menu-profile-panel button.neutral-button.stats-btn, body.en div.main-menu-profile-panel button.destructive-button {
  font-size: 34px !important;
}

body.ru div.main-menu-profile-panel button.inventory-btn {
  font-size: 20px !important;
}

body.ru div.main-menu-profile-panel button.change-username-btn {
  font-size: 14px !important;
}

body.ru div.main-menu-profile-panel button.stats-btn {
  font-size: 20px !important;
}

body.ru div.main-menu-profile-panel button.inventory-btn.destructive-button {
  font-size: 32px !important;
}

body.ru div.runprep-panel div.runprep-loadout button.neutral-button.insure-btn {
  font-size: 24px;
}

body.ru div.item-container-panel h3 {
  font-size: 18px !important;
}

body.ru div.runinprogress-panel div.runinprogress-header {
  font-size: 20px !important;
}

body.ru div.runinprogress-panel button.destructive-button {
  font-size: 32px !important;
}

body.ru div.extraction-notification p {
  font-size: 16px !important;
}

body.ru div.you-died-panel p.nothing-insured {
  font-size: 15px !important;
}

body.ru div.inventory-panel p.item-category-title {
  font-size: 14px !important;
}

body.ru div.change-username-panel div.change-username-header {
  font-size: 20px;
}

body.ru div.runprep-panel div.runprep-stats div.stat-category-title, body.ru div.runprep-panel div.runprep-insurance p.smol {
  font-size: 16px !important;
}

body.ru div.runprep-panel div.runprep-insurance button.destructive-button {
  line-height: 30px !important;
}

body.ru div.runprep-panel div.runprep-actions button span {
  position: relative;
  top: -5px;
}

body.es div.main-menu-primary-panel button.neutral-button.menu-btn.rankings-btn, body.es div.main-menu-primary-panel button.neutral-button.menu-btn.settings-btn {
  font-size: 28px;
}

body.es div.change-username-panel div.change-username-header {
  font-size: 24px;
}

body.es div.change-username-panel button.change-username-btn {
  font-size: 21px;
}

body.es div.in-run-inventory-panel div.extraction-notification p {
  font-size: 18px !important;
}

body.es div.item-container-panel h3, body.es div.runinprogress-panel div.runinprogress-header {
  font-size: 30px !important;
}

body.es div.runinprogress-panel button.destructive-button {
  font-size: 26px;
}

body.pt div.main-menu-profile-panel button.change-username-btn {
  font-size: 17px !important;
}

body.pt div.main-menu-primary-panel button.neutral-button.menu-btn.rankings-btn, body.pt div.main-menu-primary-panel button.neutral-button.menu-btn.settings-btn {
  font-size: 26px;
}

body.pt div.runinprogress-panel div.runinprogress-header {
  font-size: 32px !important;
}

body.pt div.runinprogress-panel button.destructive-button {
  font-size: 24px !important;
}

body.pt div.rankings-panel div.rankings-header, body.pt div.vendors-panel div.vendors-header {
  font-size: 32px !important;
}

body.pt div.runprep-insurance p.smol {
  font-size: 16px !important;
}

body.pt div.runprep-insurance button.destructive-button {
  line-height: 30px !important;
}

body.pt div.runprep-actions button span {
  position: relative;
  top: -5px;
}

body.pt div.settings-header {
  font-size: 26px !important;
}

body.pt div.change-username-panel div.change-username-header {
  font-size: 20px;
}

body.pt div.change-username-panel button.affirmative-button {
  font-size: 28px;
}

body.pt div.inventory-panel p.item-category-title {
  font-size: 18px !important;
}

body.pt div.extraction-notification p {
  position: relative;
  font-size: 16px !important;
  top: 15px !important;
}

body.pt div.extraction-successful-panel h2 {
  font-size: 32px !important;
}

.bottom-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  pointer-events: all !important;
}

.bottom-container .bottom-container-inner {
  width: 728px;
  height: 90px;
  pointer-events: all !important;
}

.left-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  left: 0;
  pointer-events: all !important;
}

.left-container .left-container-inner {
  width: 160px;
  height: 600px;
  pointer-events: all !important;
}

.right-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  right: 0;
  pointer-events: all !important;
}

.right-container .right-container-inner {
  width: 160px;
  height: 600px;
  pointer-events: all !important;
}

.main-menu-left-panel-container {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 250px;
  min-height: 200px;
  margin: 0;
  padding: 0;
  display: flex;
  pointer-events: all !important;
}

.main-menu-left-panel-container button.none-button {
  background: none;
  border: none;
  outline: none;
  margin: 10px 20px;
  font-size: 16px;
  cursor: pointer !important;
}

.main-menu-left-panel-container button.patch-notes-btn {
  transform: scale(.75);
}

.main-menu-left-panel-container h1 {
  margin: 0 0 20px;
  padding: 0;
}

.main-menu-left-panel-container a {
  opacity: .75;
  margin-bottom: 10px;
  text-decoration: none;
  transition: all .3s;
}

.main-menu-left-panel-container a:last-of-type {
  margin-bottom: 0;
}

.main-menu-left-panel-container a:hover {
  opacity: 1;
}

.main-menu-left-panel-container img {
  max-width: 250px;
}

.main-menu-left-panel-container img.crazygames {
  height: 51px;
}

.main-menu-left-panel-container img.kongregate {
  border-radius: 20px;
  height: 151px;
}

.main-menu-left-panel-container img.iogames {
  max-width: 200px;
}

.main-menu-left-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  left: 0;
  pointer-events: all !important;
}

.main-menu-left-container .main-menu-left-container-inner {
  width: 160px;
  height: 600px;
  pointer-events: all !important;
}

.main-menu-right-container {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  right: 1px;
  pointer-events: all !important;
}

.main-menu-right-container .main-menu-right-container-inner {
  width: 160px;
  height: 600px;
  pointer-events: all !important;
}

.bundle-details-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 750px;
  padding: 0 40px;
  display: flex;
  position: relative;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.bundle-details-panel .bundle-details-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.bundle-details-panel h3.bundle-title {
  color: green;
  text-align: center;
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
}

.bundle-details-panel p.bundle-description {
  margin: 0;
  padding: 0;
  font-size: 22px;
}

.bundle-details-panel ul.bundle-features li {
  font-size: 22px;
}

.bundle-details-panel ul.bundle-features li span.rare-blue {
  color: #1e90ff;
}

.bundle-details-panel ul.bundle-features li span.epic-purple {
  color: #a020f0;
}

.bundle-details-panel p.not-registered {
  text-align: center;
  color: red;
  font-size: 24px;
}

.bundle-details-panel div.skins {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
  display: flex;
}

.bundle-details-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
  position: relative;
  top: -10px;
}

.confirm-skin-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  width: 600px;
  padding: 0 40px;
  display: flex;
  position: relative;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.confirm-skin-panel .confirm-skin-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.confirm-skin-panel .confirmation {
  text-align: center;
  font-size: 24px;
}

.confirm-skin-panel .confirmation span {
  color: green;
}

.confirm-skin-panel p.not-registered {
  text-align: center;
  color: red;
  font-size: 24px;
}

.confirm-skin-panel .skin-preview {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 5px 0;
  display: flex;
}

.confirm-skin-panel button {
  align-self: center;
  width: auto;
  position: relative;
}

.confirm-skin-panel button:last-of-type {
  margin-bottom: 30px;
}

.purchase-successful-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 0 40px;
  display: flex;
  position: relative;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.purchase-successful-panel .purchase-successful-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.purchase-successful-panel p {
  text-align: center;
  margin: 0 0 20px;
  padding: 0;
  font-size: 24px;
}

.purchase-successful-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
  position: relative;
  top: -10px;
}

.insurance-purchase-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 0 40px;
  display: flex;
  position: relative;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.insurance-purchase-panel .insurance-purchase-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.insurance-purchase-panel p.not-registered {
  text-align: center;
  color: red;
  font-size: 24px;
}

.insurance-purchase-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
  position: relative;
  top: -10px;
}

html .joystick-hud {
  display: none;
}

html.mobile .joystick-hud {
  display: block;
}

.joystick-hud {
  pointer-events: all;
  width: 45vw;
  height: 80vh;
  position: absolute;
  bottom: 5vw;
}

#left-joystick-zone {
  left: 2vw;
}

#right-joystick-zone {
  right: 2vw;
}

@font-face {
  font-family: alagardmedium;
  src: url("alagard-webfont.1757bfe8.woff2") format("woff2"), url("alagard-webfont.1e2d0903.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

body.en * {
  font-family: alagardmedium, monospace, Helvetica, Arial, sans-serif;
}

body.es *, body.ru *, body.pt * {
  font-family: Roboto, sans-serif;
}

*, html, body {
  text-shadow: 2px 2px #000;
  color: #fff;
}

.metal-panel {
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  padding: 30px 40px;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.basic-panel {
  border-image: url("basic-frame.79f9f824.png") 29% 28% fill / 40px / 0 repeat;
  padding: 25px;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.panel-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  transform: translateZ(1px);
}

.social-btn {
  text-align: center;
  letter-spacing: 1px;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 40px;
  margin-bottom: 10px;
  padding-top: 3px;
  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  transition: all .15s linear;
  display: flex;
  transform: translateZ(1px);
}

.social-btn.discord-oauth-btn {
  background-color: #5865f2;
}

.social-btn.discord-oauth-btn:hover {
  background-color: #2939ee;
}

.social-btn.discord-oauth-btn:active {
  background-color: #1225eb;
}

.social-btn.facebook-oauth-btn {
  background-color: #4267b2;
}

.social-btn.facebook-oauth-btn:hover {
  background-color: #34518d;
}

.social-btn.facebook-oauth-btn:active {
  background-color: #2d477a;
}

.social-btn.google-oauth-btn {
  background-color: #db4437;
}

.social-btn.google-oauth-btn:hover {
  background-color: #bd2e22;
}

.social-btn.google-oauth-btn:active {
  background-color: #a7291e;
}

.ui-button {
  opacity: .9;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 60px;
  margin-bottom: 10px;
  padding: 0 35px 10px;
  font-size: 36px;
  line-height: 55px;
  overflow: hidden;
  transform: translateZ(1px);
  border: 0 !important;
}

.ui-button.btn-large {
  letter-spacing: 6px;
  height: 84px;
  margin: 10px 0;
  padding: 0;
  font-size: 56px;
}

.ui-button.btn-large:active, .ui-button.btn-large:disabled:active {
  padding-top: 10px;
}

.ui-button:hover {
  opacity: 1;
}

.ui-button:active {
  padding-top: 3px;
}

.ui-button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.ui-button:disabled:hover {
  opacity: .5;
}

.ui-button:disabled:active {
  padding: 0 12px 10px;
}

.affirmative-button {
  opacity: .9;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-image: url("button-affirmative.4067a342.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 60px;
  margin-bottom: 10px;
  padding: 0 35px 10px;
  font-size: 36px;
  line-height: 55px;
  overflow: hidden;
  transform: translateZ(1px);
  border: 0 !important;
}

.affirmative-button.btn-large {
  letter-spacing: 6px;
  height: 84px;
  margin: 10px 0;
  padding: 0;
  font-size: 56px;
}

.affirmative-button.btn-large:active, .affirmative-button.btn-large:disabled:active {
  padding-top: 10px;
}

.affirmative-button:hover {
  opacity: 1;
}

.affirmative-button:active {
  padding-top: 3px;
}

.affirmative-button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.affirmative-button:disabled:hover {
  opacity: .5;
}

.affirmative-button:disabled:active {
  padding: 0 12px 10px;
}

.affirmative-button:active {
  background-image: url("button-affirmative-active.4dd583a8.png");
}

.affirmative-button:disabled:active {
  background-image: url("button-affirmative.4067a342.png");
}

.destructive-button {
  opacity: .9;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-image: url("button-destructive.5aa70fff.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 60px;
  margin-bottom: 10px;
  padding: 0 35px 10px;
  font-size: 36px;
  line-height: 55px;
  overflow: hidden;
  transform: translateZ(1px);
  border: 0 !important;
}

.destructive-button.btn-large {
  letter-spacing: 6px;
  height: 84px;
  margin: 10px 0;
  padding: 0;
  font-size: 56px;
}

.destructive-button.btn-large:active, .destructive-button.btn-large:disabled:active {
  padding-top: 10px;
}

.destructive-button:hover {
  opacity: 1;
}

.destructive-button:active {
  padding-top: 3px;
}

.destructive-button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.destructive-button:disabled:hover {
  opacity: .5;
}

.destructive-button:disabled:active {
  padding: 0 12px 10px;
}

.destructive-button:active {
  background-image: url("button-destructive-active.a75ef933.png");
}

.destructive-button:disabled:active {
  background-image: url("button-destructive.5aa70fff.png");
}

.neutral-button {
  opacity: .9;
  text-align: center;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-blend-mode: multiply;
  background-color: #02fbfb;
  background-image: url("button-neutral.e1202432.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 60px;
  margin-bottom: 10px;
  padding: 0 35px 10px;
  font-size: 36px;
  line-height: 55px;
  overflow: hidden;
  transform: translateZ(1px);
  border: 0 !important;
}

.neutral-button.btn-large {
  letter-spacing: 6px;
  height: 84px;
  margin: 10px 0;
  padding: 0;
  font-size: 56px;
}

.neutral-button.btn-large:active, .neutral-button.btn-large:disabled:active {
  padding-top: 10px;
}

.neutral-button:hover {
  opacity: 1;
}

.neutral-button:active {
  padding-top: 3px;
}

.neutral-button:disabled {
  opacity: .5;
  cursor: not-allowed;
}

.neutral-button:disabled:hover {
  opacity: .5;
}

.neutral-button:disabled:active {
  padding: 0 12px 10px;
}

.neutral-button:active {
  background-image: url("button-neutral-active.26a11024.png");
}

.neutral-button:disabled:active {
  background-image: url("button-neutral.e1202432.png");
  padding: 0 35px 10px;
}

.character-skin-slot-bg {
  border-image: url("slot-character-skin.d4cc69b3.png") 43% fill / 20px / 0;
  padding: 25px;
  background-color: none !important;
  background: none !important;
}

.gear-slot {
  background-image: url("slot-gear.7e5496af.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.weapon-slot {
  background-image: url("slot-weapon.5eef40e5.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.Toastify__toast-container--top-center {
  margin-top: 150px;
}

.Toastify {
  pointer-events: all;
}

.Toastify__toast {
  text-align: center;
  border-style: solid;
  border-image: url("toast.77043b09.png") 31% 18% fill / 40px / 0;
  font-size: 24px;
}

.Toastify__toast .Toastify__progress-bar {
  background-color: #b29c94;
}

div.shop-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  width: 950px;
  height: 800px;
  padding: 70px 40px 0;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

div.shop-panel .shop-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: absolute;
  top: -15px;
  transform: translateZ(1px);
}

div.shop-panel .back {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
  position: absolute;
  top: 25px;
  left: 41px;
}

div.shop-panel .back button {
  margin: 0;
}

div.shop-panel .currencies {
  flex-direction: row;
  align-self: center;
  width: 300px;
  height: 50px;
  display: flex;
  position: absolute;
  top: 25px;
  right: 45px;
}

div.shop-panel .currencies .gems, div.shop-panel .currencies .coins {
  flex: 1;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  display: flex;
}

div.shop-panel .currencies .gems img, div.shop-panel .currencies .coins img {
  height: 40px;
  margin: 0;
  padding: 0;
}

div.shop-panel .insurance-actions {
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 35px;
  display: flex;
  position: relative;
  left: 18px;
}

div.shop-panel .insurance-actions button {
  margin: 0 10px;
  font-size: 25px;
}

div.shop-panel .top-shop-content {
  flex-direction: row;
  width: 100%;
  height: 64%;
  margin-top: 20px;
  display: flex;
}

div.shop-panel .top-shop-content .left-shop-content {
  flex-direction: column;
  flex: 2;
  height: 100%;
  display: flex;
}

div.shop-panel .top-shop-content .left-shop-content .bundles {
  flex-direction: column;
  flex: 2;
  margin-right: 10px;
  display: flex;
}

div.shop-panel .top-shop-content .left-shop-content .bundles .bundle {
  cursor: pointer;
  opacity: 1;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: row;
  width: 100%;
  height: 160px;
  padding: 30px 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

div.shop-panel .top-shop-content .left-shop-content .bundles .bundle:hover {
  opacity: .75;
}

div.shop-panel .top-shop-content .left-shop-content .bundles .bundle .bundle-info {
  flex: 3;
}

div.shop-panel .top-shop-content .left-shop-content .bundles .bundle .bundle-info .bundle-title {
  color: green;
  margin-bottom: 9px;
  font-size: 20px;
  font-weight: bold;
}

div.shop-panel .top-shop-content .left-shop-content .bundles .bundle .bundle-info .bundle-description {
  font-size: 18px;
}

div.shop-panel .top-shop-content .left-shop-content .bundles .bundle .bundle-img {
  flex: 1;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.shop-panel .top-shop-content .left-shop-content .bundles .bundle .bundle-img img {
  height: 75px;
}

div.shop-panel .top-shop-content .right-shop-content {
  flex-direction: column;
  flex: 2;
  height: 100%;
  display: flex;
}

div.shop-panel .top-shop-content .right-shop-content .skins {
  flex-direction: row;
  flex: 2;
  width: 100%;
  display: flex;
  position: relative;
}

div.shop-panel .top-shop-content .right-shop-content .skins .skins-title {
  pointer-events: none;
  font-size: 36px;
  position: absolute;
  top: 0;
  left: 10px;
}

div.shop-panel .top-shop-content .right-shop-content .skins .skins-content {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  display: flex;
}

div.shop-panel .top-shop-content .right-shop-content .skins .skins-content .skin-purchase {
  flex-direction: column;
  flex: 1;
  width: 100px;
  display: flex;
}

div.shop-panel .top-shop-content .right-shop-content .skins .skins-content .skin-purchase .price {
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
  position: relative;
}

div.shop-panel .top-shop-content .right-shop-content .skins .skins-content .skin-purchase .price img {
  height: 45px;
  display: inline;
  position: absolute;
  top: -11px;
  left: 0;
}

div.shop-panel .top-shop-content .right-shop-content .gems {
  flex-direction: row;
  flex: 1;
  margin-bottom: 35px;
  display: flex;
  position: relative;
}

div.shop-panel .top-shop-content .right-shop-content .gems .gems-title {
  pointer-events: none;
  font-size: 36px;
  position: absolute;
  top: -37px;
  left: 10px;
}

div.shop-panel .top-shop-content .right-shop-content .gems .gem-bundle {
  cursor: pointer;
  opacity: 1;
  border: 1px solid #fff;
  border-radius: 5px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 3px;
  display: flex;
}

div.shop-panel .top-shop-content .right-shop-content .gems .gem-bundle:hover {
  opacity: .75;
}

div.shop-panel .top-shop-content .right-shop-content .gems .gem-bundle img {
  height: 75px;
}

div.shop-panel .top-shop-content .right-shop-content .gems .gem-bundle .gems-text {
  font-size: 20px;
}

div.shop-panel .top-shop-content .right-shop-content .gems .gem-bundle .gems-price {
  font-size: 24px;
  font-weight: bold;
}

div.shop-panel .bottom-shop-content {
  width: 100%;
  height: 100px;
  padding-top: 45px;
  padding-left: 10px;
  display: flex;
  position: relative;
  top: 25px;
  left: 25px;
}

div.shop-panel .bottom-shop-content button {
  position: absolute;
  bottom: -13px;
  right: 50px;
}

div.shop-panel .bottom-shop-content .insurance-title {
  pointer-events: none;
  font-size: 36px;
  position: absolute;
  top: 0;
  left: 10px;
}

div.shop-panel .bottom-shop-content .insurance-title span {
  color: green;
}

div.shop-panel .bottom-shop-content .insurance-title img {
  height: 30px;
  margin: 0;
  padding: 0;
  transform: scale(1.75);
}

div.shop-panel .bottom-shop-content .insurance-description {
  font-size: 20px;
  position: relative;
}

div.shop-panel .bottom-shop-content .insurance-description span {
  color: green;
  font-size: 22px;
}

.login-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 600px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.login-panel .login-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.login-panel div.login-socials {
  flex-direction: row;
  display: flex;
}

.login-panel div.login-socials a {
  margin: 0 10px;
}

.login-panel div.login-or-separator {
  text-align: center;
  margin: 20px 0 5px;
  font-size: 36px;
}

.login-panel .login-form {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.login-panel .login-form p {
  margin: 0;
  padding: 0;
}

.login-panel .login-form div.errors {
  margin-bottom: 30px;
}

.login-panel .login-form div.errors p {
  color: red;
  font-size: 24px;
}

.login-panel .login-form div.form-row-wrapper {
  flex-direction: column;
  margin-bottom: 10px;
  display: flex;
}

.login-panel .login-form div.form-row-wrapper div.form-label {
  align-items: flex-start;
  font-size: 24px;
  display: flex;
}

.login-panel .login-form div.form-row-wrapper div.form-input {
  align-items: flex-start;
  display: flex;
}

.login-panel .login-form div.form-row-wrapper div.form-input input {
  color: #000;
  text-shadow: 0 0;
  width: 300px;
  height: 30px;
  padding: 2px 10px 0 5px;
  font-size: 24px;
}

.login-panel .login-actions {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  display: flex;
}

.login-panel .login-actions button:last-of-type {
  width: 150px;
}

.login-panel button {
  align-self: center;
  width: 200px;
  margin: 0 15px 30px;
}

.login-panel .login-btn {
  width: 300px;
}

.stats-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 0 40px;
  display: flex;
  position: relative;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.stats-panel .stats-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.stats-panel div.overlay {
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  inset: 60px 20px 20px;
}

.stats-panel div.overlay span.delete-acc-cta {
  color: red;
  max-width: 560px;
  font-size: 28px;
  position: relative;
}

.stats-panel div.overlay button.affirmative-button {
  font-size: 32px;
  position: relative;
  top: 20px;
}

.stats-panel div.stats-container {
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 700px;
  height: 445px;
  display: flex;
}

.stats-panel div.stats-container div.stat {
  width: 141px;
  height: 110px;
}

.stats-panel div.stats-container div.stat div.stat-value {
  text-align: center;
  font-size: 36px;
}

.stats-panel div.stats-container div.stat div.stat-label {
  text-align: center;
  font-size: 17px;
}

.stats-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
  position: relative;
  top: -10px;
}

div.kittest-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  width: 500px;
  height: 500px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

div.kittest-panel .kittest-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.vendors-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  text-align: center;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 625px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.vendors-panel .vendors-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.vendors-panel h1 {
  margin: 0 40px 20px;
  font-size: 42px;
}

.vendors-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
}

div.runprep-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 70px 40px 0;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

div.runprep-panel .runprep-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: absolute;
  top: -15px;
  transform: translateZ(1px);
}

div.runprep-panel button.fullscreen-button {
  text-overflow: unset;
  width: 150px;
  height: 35px;
  font-size: 18px;
  position: absolute;
  top: 25px;
  left: 25px;
}

div.runprep-panel button.fullscreen-button.is-full-screen {
  font-size: 14px;
}

div.runprep-panel button.fullscreen-button span {
  position: relative;
  top: -10px;
  left: -10px;
}

div.runprep-panel .runprep-content {
  flex-direction: row;
  display: flex;
}

div.runprep-panel .runprep-content img.divider {
  height: 250px;
  position: relative;
  top: 95px;
}

div.runprep-panel .runprep-content img.divider.divider-one {
  left: -5px;
}

div.runprep-panel .runprep-content img.divider.divider-two {
  margin-left: 5px;
}

div.runprep-panel .runprep-content .runprep-loadout {
  flex-direction: column;
  align-items: center;
  width: 300px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-loadout p {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
}

div.runprep-panel .runprep-content .runprep-loadout button {
  margin: 0;
}

div.runprep-panel .runprep-content .runprep-loadout div.character {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-loadout div.weapons p.weps-title {
  margin-bottom: 2px;
}

div.runprep-panel .runprep-content .runprep-loadout div.weapons div.weapon-slots {
  flex-direction: row;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-loadout div.weapons div.weapon-slots div.weapon-parent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 125px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-loadout div.weapons div.weapon-slots div.weapon-parent button.insure-btn {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  position: relative;
  top: -12px;
  transform: scale(.5);
}

div.runprep-panel .runprep-content .runprep-loadout div.weapons div.weapon-slots div.weapon-parent button.insure-btn img {
  color: #fff;
  width: 50px;
  height: 50px;
  position: relative;
  top: 0;
  left: -5px;
}

div.runprep-panel .runprep-content .runprep-loadout div.weapons div.weapon-slots div.weapon-parent button.insure-btn.insured {
  opacity: 0;
}

div.runprep-panel .runprep-content .runprep-loadout div.gear {
  position: relative;
  top: -20px;
}

div.runprep-panel .runprep-content .runprep-loadout div.gear p.insurance-credits-label {
  font-size: 18px;
  position: absolute;
  bottom: 4px;
}

div.runprep-panel .runprep-content .runprep-loadout div.gear p.gear-title {
  margin-bottom: 2px;
}

div.runprep-panel .runprep-content .runprep-loadout div.gear div.gear-slots {
  flex-direction: row;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-loadout div.gear div.gear-slots div.gear-parent {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-loadout div.gear div.gear-slots div.gear-parent button.insure-btn {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  position: relative;
  top: -12px;
  transform: scale(.4);
}

div.runprep-panel .runprep-content .runprep-loadout div.gear div.gear-slots div.gear-parent button.insure-btn img {
  color: #fff;
  width: 50px;
  height: 50px;
  position: relative;
  top: 0;
  left: -5px;
}

div.runprep-panel .runprep-content .runprep-loadout div.gear div.gear-slots div.gear-parent button.insure-btn.insured {
  opacity: 0;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector {
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #0009;
  width: 300px;
  height: 300px;
  padding: 8px;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector::-webkit-scrollbar {
  width: 0 !important;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector h4 {
  text-align: center;
  width: 100%;
  margin: 0 0 7px;
  padding: 0 0 0 8px;
  font-size: 24px;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector .no-selection {
  background-color: none;
  justify-content: center;
  align-items: center;
  height: 100%;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector .no-selection h4 {
  text-align: center;
  width: 250px;
  font-size: 24px;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector .character-skin-select {
  flex-flow: wrap;
  justify-content: space-evenly;
  height: 200px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector .character-skin-select div.character-skin-slot {
  margin: 5px 0;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector div.gear-select div.item-container div.item-slot {
  margin: 4px;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector div.weapon-select {
  flex-direction: column;
  height: 200px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector div.weapon-select div.item-container {
  flex-wrap: wrap;
  height: 200px;
  padding-left: 3px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-inventory .runprep-selector div.weapon-select div.item-container div.item-slot {
  margin: 4px;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 300px;
  height: 175px;
  display: flex;
  position: relative;
  top: 30px;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 24px;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p span.item-rarity-common {
  color: #bfbfbf;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p span.item-rarity-uncommon {
  color: #1eff00;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p span.item-rarity-rare {
  color: #4d79ff;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p span.item-rarity-epic {
  color: #b366ff;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p span.item-rarity-legendary {
  color: #f90;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p.nothing {
  color: red;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p.smol {
  text-align: center;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-width: 250px;
  max-height: 50px;
  margin-top: 10px;
  margin-bottom: 7px;
  font-size: 16px;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance p.smol::-webkit-scrollbar {
  width: 0 !important;
}

div.runprep-panel .runprep-content .runprep-inventory div.runprep-insurance button {
  transform: scale(.75);
}

div.runprep-panel .runprep-content .runprep-summary {
  flex-direction: column;
  justify-content: space-evenly;
  margin-right: 10px;
  display: flex;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-stats {
  width: 235px;
  height: 350px;
  margin-bottom: 20px;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-stats p {
  margin: 0;
  padding: 0;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-stats p.health {
  text-align: center;
  color: green;
  font-size: 26px;
  position: relative;
  top: -15px;
  left: 10px;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: relative;
  left: 5px;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats div.stats-labels .stat-category-title {
  opacity: 0;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats div.defensive-stats div.stat-category-title, div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats div.offensive-stats div.stat-category-title, div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats div.stats-labels div.stat-category-title {
  text-align: center;
  font-size: 20px;
  text-decoration: underline;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats div.defensive-stats div.stats-row p, div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats div.offensive-stats div.stats-row p, div.runprep-panel .runprep-content .runprep-summary .runprep-stats div.stats div.stats-labels div.stats-row p {
  text-align: center;
}

div.runprep-panel .runprep-content .runprep-summary div.abilities-and-passives {
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #0009;
  border-radius: 5px;
  width: 240px;
  height: 100px;
  margin: 10px 0 0 5px;
  padding: 3px 5px;
  overflow-x: hidden;
  overflow-y: scroll;
}

div.runprep-panel .runprep-content .runprep-summary div.abilities-and-passives .no-abilities {
  text-align: center;
  font-size: 18px;
}

div.runprep-panel .runprep-content .runprep-summary div.abilities-and-passives::-webkit-scrollbar {
  width: 0 !important;
}

div.runprep-panel .runprep-content .runprep-summary div.abilities-and-passives h5 {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 32px;
}

div.runprep-panel .runprep-content .runprep-summary div.abilities-and-passives div.ability {
  margin-bottom: 5px;
}

div.runprep-panel .runprep-content .runprep-summary div.abilities-and-passives div.ability p {
  text-align: center;
}

div.runprep-panel .runprep-content .runprep-summary div.abilities-and-passives div.ability p span {
  font-weight: bold;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-actions {
  flex-direction: column;
  display: flex;
  position: relative;
  left: 10px;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-actions button {
  margin: 0;
  padding: 0;
}

div.runprep-panel .runprep-content .runprep-summary .runprep-actions button.start-btn {
  position: relative;
  top: 0;
  transform: scale(.85);
}

div.runprep-panel .runprep-content .runprep-summary .runprep-actions button.back-btn {
  transform-origin: center;
  position: relative;
  top: -10px;
  transform: scale(.75);
}

.partners-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  text-align: center;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 625px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.partners-panel .partners-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.partners-panel div.partners-list {
  margin-bottom: 10px;
}

.partners-panel div.partners-list a {
  margin-bottom: 10px;
  font-size: 24px;
  display: block;
}

.partners-panel h1 {
  margin: 0 40px 20px;
  font-size: 42px;
}

.partners-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
}

.register-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 600px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.register-panel .register-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.register-panel p.terms-prompt {
  text-align: center;
  font-size: 15px;
}

.register-panel div.checkboxes {
  flex-direction: row;
  margin-bottom: 15px;
  display: flex;
}

.register-panel div.checkboxes .setting-row {
  flex-direction: row;
  margin: 0 10px;
  padding-bottom: 3px;
  display: flex;
}

.register-panel div.checkboxes .setting-row .setting-label {
  text-align: center;
  font-size: 15px;
}

.register-panel div.checkboxes .setting-row .setting-control {
  margin: 5px;
  position: relative;
  top: -10px;
}

.register-panel div.checkboxes .setting-row .setting-control select {
  color: #000;
  text-shadow: none;
  width: 200px;
  font-size: 26px;
}

.register-panel div.checkboxes .setting-row .setting-control div.toggle-switch {
  cursor: pointer;
  background-image: url("item-slot-option-3.2952e6c9.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.register-panel div.checkboxes .setting-row .setting-control div.toggle-switch .toggle-switch-checked {
  background-image: url("checkbox-checked.4cb88f7b.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  position: relative;
  top: -3px;
}

.register-panel div.checkboxes .setting-row .setting-control div.toggle-switch .toggle-switch-unchecked {
  background-image: url("checkbox-unchecked.43498b8e.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
}

.register-panel div.register-socials {
  flex-direction: row;
  display: flex;
}

.register-panel div.register-socials a {
  margin: 0 10px;
}

.register-panel div.register-or-separator {
  text-align: center;
  margin: 20px 0 5px;
  font-size: 36px;
}

.register-panel .register-form {
  text-align: center;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.register-panel .register-form p {
  margin: 0;
  padding: 0;
}

.register-panel .register-form div.errors {
  margin-bottom: 30px;
}

.register-panel .register-form div.errors p {
  color: red;
  font-size: 24px;
}

.register-panel .register-form div.form-row-wrapper {
  flex-direction: column;
  margin-bottom: 10px;
  display: flex;
}

.register-panel .register-form div.form-row-wrapper div.form-label {
  align-items: flex-start;
  font-size: 24px;
  display: flex;
}

.register-panel .register-form div.form-row-wrapper div.form-input {
  align-items: flex-start;
  display: flex;
}

.register-panel .register-form div.form-row-wrapper div.form-input input {
  color: #000;
  text-shadow: 0 0;
  width: 300px;
  height: 30px;
  padding: 2px 10px 0 5px;
  font-size: 24px;
}

.register-panel .register-actions {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  display: flex;
}

.register-panel .register-actions button:last-of-type {
  width: 150px;
}

.register-panel button {
  align-self: center;
  width: 200px;
  margin: 0 15px 30px;
}

.register-panel .register-btn {
  width: 300px;
}

.item-test-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.item-test-panel .item-test-section {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 800px;
  height: 325px;
  display: flex;
}

.item-test-panel .item-test-section .item-test-select {
  color: #000;
  text-shadow: none;
  width: 600px;
  padding: 5px 0 0 5px;
  font-size: 30px;
}

.item-test-panel .item-test-section .selected-item-details {
  flex-direction: column;
  width: 600px;
  margin-top: 10px;
  display: flex;
}

.item-test-panel .item-test-section .selected-item-details .selected-item-details-row {
  flex-direction: row;
  display: flex;
}

.item-test-panel .item-test-section .selected-item-details .selected-item-details-row .selected-item-details-label {
  font-size: 24px;
  font-weight: bold;
}

.item-test-panel .item-test-section .selected-item-details .selected-item-details-row .selected-item-details-value {
  font-size: 24px;
}

.item-test-panel .item-test-section .selected-item-slots {
  justify-content: space-evenly;
  align-items: center;
  width: 600px;
  margin-top: 30px;
  display: flex;
}

.item-test-panel .item-test-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.item-test-panel .item-test-actions {
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  display: flex;
}

.rankings-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  text-align: center;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 625px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.rankings-panel .rankings-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.rankings-panel h1 {
  margin: 0 40px 20px;
  font-size: 42px;
}

.rankings-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
}

.settings-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.settings-panel .settings-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.settings-panel .setting-row {
  border-bottom: 2px solid #1e2819;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 35px;
  padding-bottom: 3px;
  display: flex;
}

.settings-panel .setting-row .setting-label {
  margin-right: 50px;
  font-size: 26px;
}

.settings-panel .setting-row .setting-control select {
  color: #000;
  text-shadow: none;
  width: 200px;
  font-size: 26px;
}

.settings-panel .setting-row .setting-control div.toggle-switch {
  cursor: pointer;
  background-image: url("item-slot-option-3.2952e6c9.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: flex;
}

.settings-panel .setting-row .setting-control div.toggle-switch .toggle-switch-checked {
  background-image: url("checkbox-checked.4cb88f7b.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
  position: relative;
  top: -3px;
}

.settings-panel .setting-row .setting-control div.toggle-switch .toggle-switch-unchecked {
  background-image: url("checkbox-unchecked.43498b8e.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 32px;
  height: 32px;
}

.settings-panel .settings-actions {
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 20px;
  display: flex;
}

.settings-panel .settings-actions button:disabled {
  padding: 0 35px 10px;
}

@keyframes growAndShrink {
  0% {
    transform: scale(1)rotate(-25deg);
  }

  100% {
    transform: scale(1.5)rotate(-15deg);
  }
}

.main-menu {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.main-menu .center-content {
  z-index: 2;
  transform-origin: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.main-menu .center-content .bottom-container {
  position: relative;
  top: 10px;
  left: 20px;
}

.main-menu .center-content div.game-logo {
  text-align: center;
  --animate-duration: 1.5s;
  margin: 0;
  padding: 0;
  position: relative;
}

.main-menu .center-content div.game-logo .game-logo-image {
  height: 300px;
}

.main-menu .center-content div.game-logo span {
  letter-spacing: 3px;
  color: #ff0;
  font-size: 26px;
  animation: .5s ease-in-out infinite alternate growAndShrink;
  position: absolute;
  bottom: 45px;
  right: -20px;
  transform: rotate(-15deg);
}

.main-menu .center-content .maintenance-mode {
  justify-content: center;
  align-items: center;
  width: 1040px;
  height: 409px;
  display: flex;
}

.main-menu .center-content .maintenance-mode h1 {
  text-align: center;
  max-width: 600px;
  font-size: 44px;
}

.main-menu .center-content .maintenance-mode .loading-spinner {
  position: relative;
  top: -50px;
}

.main-menu .center-content .panels {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.main-menu .center-content .panels .main-menu-ad-panel {
  text-align: center;
  border-image: url("basic-frame.79f9f824.png") 29% 28% fill / 40px / 0 repeat;
  padding: 25px;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.main-menu .center-content .panels .main-menu-ad-panel .partners-btn {
  margin-top: 10px;
  margin-bottom: 0;
}

.main-menu .center-content .panels .main-menu-ad-panel .ad {
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 250px;
  display: flex;
}

.main-menu .center-content .panels .main-menu-ad-panel .ad p {
  text-align: center;
  font-size: 36px;
}

.main-menu .center-content .panels .main-menu-primary-panel {
  text-align: center;
  pointer-events: all;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 350px;
  margin: 0 20px;
  padding: 30px 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.main-menu .center-content .panels .main-menu-primary-panel select {
  color: #000;
  text-shadow: none;
  width: 100%;
  font-size: 24px;
  position: relative;
  top: -5px;
}

.main-menu .center-content .panels .main-menu-primary-panel p.cta-header {
  text-align: center;
  max-width: 400px;
  margin: 10px 0;
  padding: 0;
  font-size: 32px;
}

.main-menu .center-content .panels .main-menu-primary-panel button.play-game-btn {
  text-transform: uppercase;
  width: 291px;
}

.main-menu .center-content .panels .main-menu-primary-panel button.menu-btn {
  width: 270px;
  position: relative;
}

.main-menu .center-content .panels .main-menu-primary-panel button.menu-btn span.shop-new-tag {
  color: #ff0;
  font-size: 20px;
  position: absolute;
  top: -10px;
  right: 0;
  transform: rotate(30deg);
}

.main-menu .center-content .panels .main-menu-primary-panel button.menu-btn:last-of-type {
  margin-bottom: 0;
}

.main-menu .center-content .panels .main-menu-profile-panel {
  border-image: url("basic-frame.79f9f824.png") 29% 28% fill / 40px / 0 repeat;
  width: 300px;
  min-height: 200px;
  padding: 15px 25px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.main-menu .center-content .panels .main-menu-profile-panel .loading-user-details {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  display: flex;
  position: relative;
}

.main-menu .center-content .panels .main-menu-profile-panel .loading-user-details .loading-spinner {
  width: 80px;
  height: 80px;
}

.main-menu .center-content .panels .main-menu-profile-panel .anonymous-user-details .welcome-back, .main-menu .center-content .panels .main-menu-profile-panel .registered-user-details .welcome-back {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 266px;
  display: flex;
}

.main-menu .center-content .panels .main-menu-profile-panel .anonymous-user-details .welcome-back .wb-header, .main-menu .center-content .panels .main-menu-profile-panel .registered-user-details .welcome-back .wb-header {
  text-align: center;
  font-size: 24px;
}

.main-menu .center-content .panels .main-menu-profile-panel .anonymous-user-details .welcome-back .wb-signup-cta, .main-menu .center-content .panels .main-menu-profile-panel .registered-user-details .welcome-back .wb-signup-cta {
  text-align: center;
  margin-bottom: 10px;
  font-size: 24px;
}

.main-menu .center-content .panels .main-menu-profile-panel .anonymous-user-details .welcome-back .wb-username, .main-menu .center-content .panels .main-menu-profile-panel .registered-user-details .welcome-back .wb-username {
  text-align: center;
  color: #9cadd7;
  text-overflow: ellipsis;
  text-shadow: 3px 3px #000;
  max-width: 250px;
  font-size: 36px;
  font-weight: bold;
  overflow: hidden;
}

.main-menu .center-content .panels .main-menu-profile-panel button.collection-log-btn {
  font-size: 30px !important;
}

.main-menu .center-content .panels .main-menu-profile-panel button.create-account-btn {
  font-size: 28px !important;
}

.main-menu .center-content .panels .main-menu-profile-panel button.sign-in-btn {
  font-size: 36px !important;
}

.main-menu .center-content .panels .main-menu-profile-panel .anonymous-user-details button {
  transform-origin: 0 0;
  width: 265px;
  font-size: 22px;
  transform: scale(.95);
}

.main-menu .center-content .panels .main-menu-profile-panel .registered-user-details .wb-username {
  text-align: center;
  margin-bottom: 5px;
}

.main-menu .center-content .panels .main-menu-profile-panel .registered-user-details button {
  transform-origin: 0 0;
  width: 275px;
  font-size: 22px;
  transform: scale(.95);
}

.inventory-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 0 20px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.inventory-panel .inventory-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.inventory-panel div.totals {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  display: flex;
}

.inventory-panel div.totals div.rarity-total span.common {
  color: #bfbfbf;
}

.inventory-panel div.totals div.rarity-total span.uncommon {
  color: #1eff00;
}

.inventory-panel div.totals div.rarity-total span.rare {
  color: #4d79ff;
}

.inventory-panel div.totals div.rarity-total span.epic {
  color: #b366ff;
}

.inventory-panel div.totals div.rarity-total span.legendary {
  color: #f90;
}

.inventory-panel div.totals div.all-total {
  margin-bottom: 20px;
  font-size: 32px;
}

.inventory-panel div.totals div.rarity-totals {
  flex-flow: wrap;
  justify-content: space-evenly;
  width: 800px;
  display: flex;
}

.inventory-panel div.totals div.rarity-totals div.rarity-total {
  text-align: center;
  width: 110px;
  height: 75px;
  margin: 0;
  font-size: 20px;
}

.inventory-panel div.items {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: flex-start;
  display: flex;
}

.inventory-panel div.items p.item-category-title {
  margin-bottom: 5px !important;
}

.inventory-panel div.items div.inventory-contents {
  margin: 0 10px 10px;
}

.inventory-panel div.items div.inventory-contents p {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.inventory-panel div.items div.inventory-contents .item-container {
  background-color: #0009;
  border-radius: 5px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 374px;
  height: 135px;
  padding: 5px;
  display: flex;
  overflow-x: hidden;
  overflow-y: scroll;
}

.inventory-panel div.bottom-actions {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  display: flex;
}

.inventory-panel div.bottom-actions span.delete-acc-cta {
  color: red;
  text-align: center;
  max-width: 560px;
  font-size: 20px;
  position: relative;
  top: -15px;
}

.inventory-panel div.bottom-actions button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
}

.how-to-play-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  width: 900px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.how-to-play-panel .how-to-play-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.how-to-play-panel p {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
}

.how-to-play-panel h2 {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 32px;
}

.how-to-play-panel div.control-list-parent {
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  padding-left: 50px;
  display: flex;
}

.how-to-play-panel div.control-list-parent ul.control-list {
  margin: 0;
  padding: 20px;
  list-style: none;
}

.how-to-play-panel div.control-list-parent ul.control-list.chonky-control-list li.control div.control-label {
  width: 100px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control {
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  font-size: 22px;
  display: flex;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control.extraction-control {
  position: relative;
  top: 20px;
  left: 20px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control.extraction-control img.extraction {
  width: 150px;
  height: 150px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control.extraction-control .control-description {
  text-align: center;
  width: 150px;
  position: relative;
  top: 120px;
  left: -90px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control:last-of-type {
  margin-bottom: 0;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label {
  align-items: center;
  width: 75px;
  margin-right: 10px;
  display: flex;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mousedrag {
  margin-right: 50px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse {
  justify-content: center;
  align-items: center;
  width: 50px;
  margin-left: 50px;
  position: relative;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse img {
  width: 40px;
  height: 56px;
  margin-right: 10px;
  position: relative;
  top: -5px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse img.mousearrow {
  width: 28px;
  height: 28px;
  position: absolute;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse img.mousearrow.mar {
  top: 7px;
  right: -35px;
  transform: rotate(180deg);
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse img.mousearrow.mal {
  top: 7px;
  left: -35px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse img.mousearrow.mau {
  top: -38px;
  left: 6px;
  right: 0;
  transform: rotate(90deg);
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse img.mousearrow.mad {
  bottom: -28px;
  left: 6px;
  top: unset;
  transform: rotate(-90deg);
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label.mouse img.active {
  opacity: 1;
  transform: scale(1) !important;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label span {
  padding: 0 10px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label img {
  opacity: .7;
  width: 32px;
  height: 32px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label img.space {
  width: 64px;
  height: 32px;
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-label img.active {
  opacity: 1;
  transform-origin: bottom;
  transform: scaleY(.8);
}

.how-to-play-panel div.control-list-parent ul.control-list li.control div.control-description {
  width: 120px;
  margin-left: 5px;
}

.how-to-play-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
}

.patch-notes-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  height: 600px;
  padding: 0 40px;
  display: flex;
  position: relative;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.patch-notes-panel .patch-notes-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.patch-notes-panel div.patch-notes {
  scrollbar-width: none;
  -ms-overflow-style: none;
  background-color: #0000004d;
  width: 500px;
  height: 425px;
  padding: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.patch-notes-panel div.patch-notes::-webkit-scrollbar {
  width: 0 !important;
}

.patch-notes-panel div.patch-notes div.patch-segment {
  margin-bottom: 50px;
}

.patch-notes-panel div.patch-notes div.patch-segment h3 {
  margin: 0;
  padding: 0;
  font-size: 28px;
}

.patch-notes-panel div.patch-notes div.patch-segment ul li {
  margin-bottom: 8px;
  font-size: 22px;
}

.patch-notes-panel button.neutral-button {
  align-self: center;
  margin-top: 10px;
  width: 150px !important;
}

div.socials {
  z-index: 1;
  text-align: center;
  transform-origin: bottom;
  border-radius: 5px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

div.socials div.social-links-wrapper {
  height: 63px;
  position: relative;
  top: 5px;
}

div.socials div.social-links-wrapper a.social-link {
  pointer-events: all;
  opacity: .7;
  cursor: pointer;
  width: 60px;
  height: 60px;
  margin: 0 10px;
  text-decoration: none;
  transition: all .2s;
  display: inline-block;
  image-rendering: auto !important;
}

div.socials div.social-links-wrapper a.social-link:hover {
  opacity: 1;
}

div.socials div.social-links-wrapper a.social-link.twitter {
  background-image: url("socials_twitter.eea58bcb.png");
  background-size: 100% 100%;
}

div.socials div.social-links-wrapper a.social-link.discord {
  background-image: url("socials_discord.5e6e2988.png");
  background-size: 100% 100%;
}

div.socials div.social-links-wrapper a.social-link.website {
  background-image: url("socials_smg.747a0896.png");
  background-size: 100% 100%;
  width: 80px;
  height: 80px;
  position: relative;
  top: 8px;
  left: -10px;
}

@keyframes topleftanim {
  0% {
    top: 0%;
    left: 0%;
  }

  50% {
    top: 2%;
    left: 2%;
  }

  100% {
    top: 0%;
    left: 0%;
  }
}

@keyframes toprightanim {
  0% {
    top: 0%;
    right: 0%;
  }

  50% {
    top: 2%;
    right: 3%;
  }

  100% {
    top: 0%;
    right: 0%;
  }
}

@keyframes bottomleftanim {
  0% {
    bottom: 0%;
    left: 0%;
  }

  50% {
    bottom: 2%;
    left: 2%;
  }

  100% {
    bottom: 0%;
    left: 0%;
  }
}

@keyframes bottomrightanim {
  0% {
    bottom: 0%;
    right: 0%;
  }

  50% {
    bottom: 2%;
    right: 2%;
  }

  100% {
    bottom: 0%;
    right: 0%;
  }
}

div.item-slot {
  cursor: pointer;
  transform-origin: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
  position: relative;
}

div.item-slot img.damage-type-icon {
  width: 22px;
  height: 22px;
  position: absolute;
  bottom: -5px;
  right: -3px;
}

div.item-slot img.insured-lock {
  z-index: 5;
  height: 30px;
  position: absolute;
  bottom: -7px;
  left: -5px;
}

div.item-slot img {
  position: relative;
}

div.item-slot:hover div.selection-parent {
  display: flex;
}

div.item-slot.slot-rarity-common {
  box-shadow: inset 0 0 5px #bfbfbf;
}

div.item-slot.slot-rarity-uncommon {
  box-shadow: inset 0 0 8px #1eff00;
}

div.item-slot.slot-rarity-rare {
  box-shadow: inset 0 0 10px #4d79ff;
}

div.item-slot.slot-rarity-epic {
  box-shadow: inset 0 0 13px #b366ff;
}

div.item-slot.slot-rarity-legendary {
  box-shadow: inset 0 0 15px #f90;
}

div.item-slot.slot-size-large.slot-rarity-common {
  box-shadow: inset 0 0 15px #bfbfbf;
}

div.item-slot.slot-size-large.slot-rarity-uncommon {
  box-shadow: inset 0 0 15px #1eff00;
}

div.item-slot.slot-size-large.slot-rarity-rare {
  box-shadow: inset 0 0 15px #4d79ff;
}

div.item-slot.slot-size-large.slot-rarity-epic {
  box-shadow: inset 0 0 15px #b366ff;
}

div.item-slot.slot-size-large.slot-rarity-legendary {
  box-shadow: inset 0 0 15px #f90;
}

div.item-slot.selected div.selection-parent {
  display: flex;
}

div.item-slot.disable-hover {
  cursor: default;
}

div.item-slot.disable-hover:hover div.selection-parent {
  display: none !important;
}

div.item-slot div.selection-parent {
  display: none;
}

div.item-slot div.selection-parent div.selection-corner {
  background-image: url("selection-corner.9194430c.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  position: absolute;
}

div.item-slot div.selection-parent div.selection-corner.top-left {
  animation: .8s linear infinite topleftanim;
  top: 0%;
  left: 0%;
}

div.item-slot div.selection-parent div.selection-corner.top-right {
  animation: .8s linear infinite toprightanim;
  top: 0%;
  right: 0%;
  rotate: 90deg;
}

div.item-slot div.selection-parent div.selection-corner.bottom-left {
  animation: .8s linear infinite bottomleftanim;
  bottom: 0%;
  left: 0%;
  rotate: 270deg;
}

div.item-slot div.selection-parent div.selection-corner.bottom-right {
  animation: .8s linear infinite bottomrightanim;
  bottom: 0%;
  right: 0%;
  rotate: 180deg;
}

div.item-slot.slot-type-inventory {
  background-image: url("slot-inventory.60a79a70.png");
  width: 48px;
  height: 48px;
}

div.item-slot.slot-type-gear {
  background-image: url("slot-gear.7e5496af.png");
  width: 48px;
  height: 48px;
}

div.item-slot.slot-type-gear.slot-size-large {
  width: 72px;
  height: 72px;
}

div.item-slot.slot-type-weapon {
  background-image: url("slot-weapon.5eef40e5.png");
  width: 48px;
  height: 48px;
}

div.item-slot.slot-type-weapon.slot-size-large {
  width: 96px;
  height: 96px;
}

div.item-slot.slot-type-weapon.slot-size-large img.damage-type-icon {
  width: 33px;
  height: 33px;
  bottom: 5px;
  right: 7px;
}

div.item-slot span.quantity {
  z-index: 2;
  font-size: 18px;
  position: absolute;
  top: 0;
  right: 5px;
}

div.react-tooltip {
  z-index: 2000;
}

div.react-tooltip.fancy-tooltip {
  opacity: 1;
  z-index: 2000;
  background: none;
  border-style: solid;
  border-image: url("tooltip-frame.fb858f69.png") 48% fill / 40px / 0;
  width: 400px;
  padding: 22px 30px 30px;
}

div.react-tooltip.fancy-tooltip h3, div.react-tooltip.fancy-tooltip p {
  margin: 0;
  padding: 0;
}

div.react-tooltip.fancy-tooltip h3.item-name {
  margin-left: 45px;
  font-size: 24px;
}

div.react-tooltip.fancy-tooltip h3.item-name.common {
  color: #bfbfbf;
}

div.react-tooltip.fancy-tooltip h3.item-name.uncommon {
  color: #1eff00;
}

div.react-tooltip.fancy-tooltip h3.item-name.rare {
  color: #4d79ff;
}

div.react-tooltip.fancy-tooltip h3.item-name.epic {
  color: #b366ff;
}

div.react-tooltip.fancy-tooltip h3.item-name.legendary {
  color: #f90;
}

div.react-tooltip.fancy-tooltip img.item-image {
  position: absolute;
  top: 47px;
  left: 35px;
}

div.react-tooltip.fancy-tooltip p.item-type {
  color: #9aadd8;
  margin-left: 45px;
  font-size: 22px;
}

div.react-tooltip.fancy-tooltip p.item-extract-info {
  text-align: center;
  margin-top: 10px;
  font-size: 20px;
}

div.react-tooltip.fancy-tooltip p.item-click-info {
  text-align: center;
  vertical-align: top;
  font-size: 20px;
}

div.react-tooltip.fancy-tooltip p.item-click-info img {
  height: 30px;
  position: relative;
  top: 7px;
  left: -10px;
}

div.react-tooltip.fancy-tooltip p.item-description, div.react-tooltip.fancy-tooltip div.item-gameplay-info {
  margin-top: 5px;
  font-size: 18px;
}

div.react-tooltip.fancy-tooltip p.item-description span.ability-line-item, div.react-tooltip.fancy-tooltip div.item-gameplay-info span.ability-line-item {
  margin-top: 2px;
  margin-left: 25px;
  display: flex;
}

div.react-tooltip.fancy-tooltip div.damage-type-line {
  align-items: center;
  margin-top: 5px;
  margin-bottom: 3px;
  font-size: 19px;
  font-weight: bold;
  display: flex;
}

div.react-tooltip.fancy-tooltip div.damage-type-line .damage-type-icon {
  width: 22px;
  height: 22px;
  margin-right: 5px;
}

div.react-tooltip.fancy-tooltip div.damage-type-line span {
  position: relative;
  top: 1px;
}

.collection-log-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  padding: 0 40px;
  display: flex;
  position: relative;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.collection-log-panel .collection-log-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 32px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.collection-log-panel div.log {
  flex-direction: row;
  height: 300px;
  display: flex;
}

.collection-log-panel div.log div.log-categories {
  background-color: #0006;
  width: 175px;
  height: 100%;
  padding: 3px 0 0;
}

.collection-log-panel div.log div.log-categories div.category {
  cursor: pointer;
  border-bottom: 1px solid #333;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 3px 5px;
  font-size: 22px;
  display: flex;
}

.collection-log-panel div.log div.log-categories div.category.boss {
  color: #c00;
}

.collection-log-panel div.log div.log-categories div.category.active, .collection-log-panel div.log div.log-categories div.category:hover {
  background-color: #0009;
}

.collection-log-panel div.log div.log-data {
  background-color: #0009;
  border: 1px solid #333;
  flex-direction: column;
  width: 400px;
  height: 100%;
  display: flex;
}

.collection-log-panel div.log div.log-data div.meta {
  background-color: #0000004d;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  max-height: 100px;
  display: flex;
  overflow: hidden;
}

.collection-log-panel div.log div.log-data div.meta img.gronk {
  position: relative;
  top: -13px;
  transform: scale(2);
}

.collection-log-panel div.log div.log-data div.meta img.allenemies {
  transform: scale(3.5);
}

.collection-log-panel div.log div.log-data div.meta img.lootchest {
  transform: scale(2.3);
}

.collection-log-panel div.log div.log-data div.meta img.darkwizard {
  transform: scale(1.8);
}

.collection-log-panel div.log div.log-data div.meta img.ogre {
  position: relative;
  top: -7px;
  transform: scale(1.3);
}

.collection-log-panel div.log div.log-data div.meta img.treant {
  transform: scale(1.5);
}

.collection-log-panel div.log div.log-data div.meta img.skeleton {
  transform: scale(2);
}

.collection-log-panel div.log div.log-data div.meta img.ghost {
  transform: scale(1.8);
}

.collection-log-panel div.log div.log-data div.meta img.giantrat {
  position: relative;
  top: -9px;
  transform: scale(2.5);
}

.collection-log-panel div.log div.log-data div.log-items {
  width: 100%;
  height: 100%;
}

.collection-log-panel button.neutral-button {
  align-self: center;
  width: 150px;
  margin-top: 10px;
  margin-bottom: 25px;
}

div.top-left-hud {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  padding: 5px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

div.top-left-hud .ping, div.top-left-hud .kills {
  justify-content: center;
  align-items: center;
  margin: 10px;
  font-size: 24px;
  display: flex;
}

div.top-left-hud .ping img, div.top-left-hud .kills img {
  width: 30px;
  margin-right: 5px;
  position: relative;
  top: -3px;
}

div.top-left-hud .ping img.bad, div.top-left-hud .kills img.bad {
  filter: hue-rotate(90deg);
}

div.top-left-hud button.fullscreen-button {
  pointer-events: all;
  text-overflow: unset;
  width: 150px;
  height: 35px;
  font-size: 18px;
  position: absolute;
  top: 9px;
  left: 195px;
}

div.top-left-hud button.fullscreen-button.is-full-screen {
  font-size: 14px;
}

div.top-left-hud button.fullscreen-button span {
  position: relative;
  top: -10px;
  left: -10px;
}

.runinprogress-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 600px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.runinprogress-panel .runinprogress-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.runinprogress-panel h3 {
  text-align: center;
  margin: 0 0 20px;
  padding: 0;
  font-size: 24px;
}

.runinprogress-panel p {
  margin: 0 0 20px;
  padding: 0;
  font-size: 20px;
}

.runinprogress-panel .abandon-actions {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.runinprogress-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
}

.runinprogress-panel .abandon-btn {
  width: 300px;
}

div.loading-bar {
  z-index: 999;
  background-color: #000;
  border: 1px solid #eee;
  border-radius: 5px;
  width: 400px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  bottom: 100px;
  left: 0;
  right: 0;
}

div.loading-bar .loading-bar-progress {
  background-color: #b64f4f99;
  border-radius: 5px;
  height: 100%;
  position: absolute;
  top: 0;
}

div.loading-bar .loading-bar-text {
  text-align: center;
  color: #eee;
  z-index: 99999;
  width: 100%;
  font-size: 32px;
  line-height: 40px;
}

div.spectating {
  text-align: center;
  position: absolute;
  bottom: 150px;
}

div.spectating h1 {
  font-size: 56px;
}

div.spectating button {
  pointer-events: all;
}

div.region-pop-up {
  z-index: 1;
  text-align: center;
  transform-origin: bottom;
  border-radius: 5px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  animation-duration: 3s;
  display: flex;
  position: fixed;
  bottom: 145px;
  left: 0;
  right: 0;
}

div.region-pop-up h2 {
  margin: 0;
  padding: 0;
  font-size: 46px;
}

div.region-pop-up div.underline {
  border: 1px solid #fff;
  width: 300px;
  height: 1px;
  box-shadow: 0 0 40px #ffffff1a;
}

div.top-right-hud {
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

div.top-right-hud div.leaderboard {
  transform-origin: 100% 0;
  background-color: #0009;
  border-radius: 3px;
  width: 295px;
  margin: 3px 3px 0 0;
  padding: 5px 10px 5px 25px;
  transform: scale(.9);
}

div.top-right-hud div.leaderboard.hidden {
  background: none;
  width: 350px;
  font-size: 24px;
}

div.top-right-hud div.leaderboard.hidden .leaderboard-close {
  margin-top: 0;
  font-size: 24px;
}

div.top-right-hud div.leaderboard div.leaderboard-title {
  justify-content: center;
  margin-top: 2px;
  margin-bottom: 5px;
  font-size: 24px;
  font-weight: bold;
  text-decoration: underline;
  display: flex;
}

div.top-right-hud div.leaderboard div.leaderboard-legend div.npc-kills, div.top-right-hud div.leaderboard div.leaderboard-legend div.player-kills, div.top-right-hud div.leaderboard div.leaderboard-legend div.items-looted {
  font-size: 14px;
}

div.top-right-hud div.leaderboard div.leaderboard-row, div.top-right-hud div.leaderboard div.leaderboard-you, div.top-right-hud div.leaderboard div.leaderboard-legend {
  flex-direction: row;
  display: flex;
  position: relative;
}

div.top-right-hud div.leaderboard div.leaderboard-row img.registered-user-icon, div.top-right-hud div.leaderboard div.leaderboard-you img.registered-user-icon, div.top-right-hud div.leaderboard div.leaderboard-legend img.registered-user-icon {
  width: 24px;
  height: 24px;
  position: absolute;
  top: -3px;
  left: -25px;
}

div.top-right-hud div.leaderboard div.leaderboard-row div.rank, div.top-right-hud div.leaderboard div.leaderboard-you div.rank, div.top-right-hud div.leaderboard div.leaderboard-legend div.rank {
  flex: 1;
}

div.top-right-hud div.leaderboard div.leaderboard-row div.username, div.top-right-hud div.leaderboard div.leaderboard-you div.username, div.top-right-hud div.leaderboard div.leaderboard-legend div.username {
  text-overflow: ellipsis;
  flex: 3;
  padding-left: 10px;
  overflow: hidden;
  pointer-events: all !important;
}

div.top-right-hud div.leaderboard div.leaderboard-row div.npc-kills, div.top-right-hud div.leaderboard div.leaderboard-you div.npc-kills, div.top-right-hud div.leaderboard div.leaderboard-legend div.npc-kills, div.top-right-hud div.leaderboard div.leaderboard-row div.player-kills, div.top-right-hud div.leaderboard div.leaderboard-you div.player-kills, div.top-right-hud div.leaderboard div.leaderboard-legend div.player-kills, div.top-right-hud div.leaderboard div.leaderboard-row div.items-looted, div.top-right-hud div.leaderboard div.leaderboard-you div.items-looted, div.top-right-hud div.leaderboard div.leaderboard-legend div.items-looted {
  flex: 1;
  justify-content: center;
  display: flex;
}

div.top-right-hud div.leaderboard div.leaderboard-you * {
  color: green !important;
}

div.top-right-hud div.leaderboard div.leaderboard-close {
  text-align: center;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
}

.change-username-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  max-width: 600px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.change-username-panel .change-username-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.change-username-panel .change-username-form {
  text-align: center;
}

.change-username-panel .change-username-form p {
  margin: 0;
  padding: 0;
}

.change-username-panel .change-username-form div.errors {
  margin-bottom: 30px;
}

.change-username-panel .change-username-form div.errors p {
  color: red;
  font-size: 24px;
}

.change-username-panel .change-username-form p.label {
  font-size: 24px;
}

.change-username-panel .change-username-form p.current {
  margin-bottom: 40px;
  font-size: 44px;
}

.change-username-panel .change-username-form input.change-username-input {
  color: #000;
  text-shadow: 0 0;
  width: 300px;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 40px;
  padding: 0 10px;
  font-size: 32px;
}

.change-username-panel .change-username-actions {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.change-username-panel .change-username-actions button:last-of-type {
  width: 150px;
}

.change-username-panel button {
  align-self: center;
  width: 200px;
  margin: 0 15px 30px;
}

.change-username-panel .change-username-btn {
  width: 300px;
}

div.you-died-panel {
  pointer-events: all;
  -webkit-user-select: all;
  user-select: all;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  animation-duration: 1s;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

div.you-died-panel div.socials-wrapper {
  position: absolute;
  top: 30px;
  right: 40px;
}

div.you-died-panel h2 {
  text-shadow: 3px 3px #000;
  text-align: center;
  color: #900;
  margin: 20px 0 0;
  padding: 0;
  font-size: 56px;
}

div.you-died-panel h2 img {
  height: 60px;
  top: 10px;
}

div.you-died-panel h2 img.left-skull {
  position: relative;
  left: -20px;
}

div.you-died-panel h2 img.right-skull {
  position: relative;
  right: -20px;
}

div.you-died-panel div.middle-segment {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

div.you-died-panel div.middle-segment div.left-stats {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 300px;
  margin-right: 20px;
  padding-top: 30px;
  display: flex;
}

div.you-died-panel div.middle-segment div.left-stats div.run-metric {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

div.you-died-panel div.middle-segment div.left-stats div.run-metric p {
  margin: 0;
  padding: 0;
}

div.you-died-panel div.middle-segment div.left-stats div.run-metric p.metric-title {
  text-align: center;
  font-size: 24px;
}

div.you-died-panel div.middle-segment div.left-stats div.run-metric p.metric-value {
  text-align: center;
  font-size: 36px;
}

div.you-died-panel div.middle-segment img.divider {
  height: 200px;
  position: relative;
  left: -40px;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  display: flex;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout p.lost-title, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout p.insured-title {
  margin: 0 0 10px;
  padding: 0;
  font-size: 22px;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout p.lost-title:last-of-type, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout p.insured-title:last-of-type {
  margin: 20px 0;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout p.nothing-insured {
  color: #e60000;
  margin: 0 0 20px;
  padding: 0;
  font-size: 27px;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.character {
  margin: 0 0 10px;
  padding: 0;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.weapons {
  flex-direction: row;
  margin: 0 0 10px;
  padding: 0;
  display: flex;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.weapons div.item-slot {
  margin: 0 25px;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.lost-items, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.gear, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.weapons {
  flex-direction: row;
  margin: 0;
  padding: 0;
  display: flex;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.lost-items div.item-slot, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.gear div.item-slot, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.weapons div.item-slot {
  margin: 0 15px;
}

div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.lost-items div.item-slot div.react-tooltip, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.gear div.item-slot div.react-tooltip, div.you-died-panel div.middle-segment div.right-items-lost .youdied-loadout div.weapons div.item-slot div.react-tooltip {
  z-index: 999;
}

div.you-died-panel div.lost-loot {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 135px;
  margin-bottom: 10px;
  display: flex;
}

div.you-died-panel div.lost-loot h3 {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 32px;
}

div.you-died-panel div.lost-loot .item-container {
  background-color: #0009;
  width: 624px;
  height: 100%;
  overflow-y: scroll;
}

div.you-died-panel div.actions {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

div.you-died-panel div.actions span.delete-acc-cta {
  color: green;
  text-align: center;
  max-width: 375px;
  max-height: 100px;
  font-size: 16px;
  position: relative;
  top: 10px;
  left: -10px;
}

div.you-died-panel div.actions button {
  flex-direction: row;
  max-width: 226px;
  margin: 0 0 10px;
  font-size: 24px;
  display: flex;
}

.finding-match {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.finding-match .finding-match-panel {
  --animate-duration: 1.5s;
  text-align: center;
  border-image: url("basic-frame.79f9f824.png") 29% 28% fill / 40px / 0 repeat;
  max-width: 500px;
  padding: 25px;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.finding-match .finding-match-panel h2 {
  width: 400px;
  margin: 0;
  padding: 0;
  font-size: 36px;
}

div.bottom-left-hud {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  height: 100px;
  padding: 0 0 15px 15px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

div.bottom-left-hud div.bottom-left-inventory {
  flex-direction: row;
  display: flex;
}

div.bottom-left-hud div.bottom-left-inventory div.inventory-sprite {
  width: 50px;
  height: 50px;
  margin-right: 5px;
  position: relative;
}

div.bottom-left-hud div.bottom-left-inventory div.inventory-sprite img {
  width: 75px;
  position: relative;
  top: -20px;
  left: -20px;
}

div.bottom-left-hud div.bottom-left-inventory div.inventory-sprite .item-quantity {
  font-size: 26px;
  position: absolute;
  bottom: 0;
  left: 0;
}

div.bottom-left-hud div.bottom-left-inventory div.inventory-texts {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.bottom-left-hud div.bottom-left-inventory div.inventory-texts div.inventory-text {
  font-size: 20px;
  font-weight: bold;
}

div.item-container {
  scrollbar-width: none;
  -ms-overflow-style: none;
  flex-flow: wrap;
  place-content: flex-start;
  width: 100%;
  display: flex;
}

div.item-container::-webkit-scrollbar {
  width: 0 !important;
}

div.item-container .item-slot {
  margin: 2px;
}

.matchmaking-error-panel {
  pointer-events: all;
  z-index: 2;
  --animate-duration: .8s;
  text-align: center;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px;
  padding: 0 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.matchmaking-error-panel .matchmaking-error-header {
  text-align: center;
  background-image: url("header-banner.612c99a3.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  align-self: center;
  width: 244px;
  height: 76px;
  font-size: 44px;
  line-height: 76px;
  position: relative;
  top: -15px;
  transform: translateZ(1px);
}

.matchmaking-error-panel h3 {
  text-align: center;
  margin: 50px 0 20px;
  padding: 0;
  font-size: 36px;
}

.matchmaking-error-panel p {
  margin: 0 0 20px;
  padding: 0;
  font-size: 24px;
}

.matchmaking-error-panel .abandon-actions {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.matchmaking-error-panel button {
  align-self: center;
  width: 200px;
  margin-bottom: 30px;
}

.lds-ring {
  width: 80px;
  height: 80px;
  display: inline-block;
  position: relative;
}

.lds-ring div {
  box-sizing: border-box;
  border: 8px solid #0000;
  border-top-color: #fff;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: 8px;
  animation: 1.2s cubic-bezier(.5, 0, .5, 1) infinite lds-ring;
  display: block;
  position: absolute;
}

.lds-ring div:first-child {
  animation-delay: -.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

div.in-run-inventory-panel {
  pointer-events: all;
  -webkit-user-select: all;
  user-select: all;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 288px;
  height: 415px;
  padding: 20px 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

div.in-run-inventory-panel div.your-inventory {
  width: 100%;
  height: 285px;
}

div.in-run-inventory-panel div.your-inventory h3 {
  text-align: center;
  margin: 10px 0;
  padding: 0;
  font-size: 20px;
}

div.in-run-inventory-panel div.your-inventory .item-container {
  background-color: #0009;
  width: 100%;
  height: 188px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

div.in-run-inventory-panel div.your-inventory div.extraction-notification {
  flex-direction: row;
  margin-top: 10px;
  display: flex;
  position: relative;
}

div.in-run-inventory-panel div.your-inventory div.extraction-notification p {
  text-align: center;
  margin: 0;
  padding: 0 8px;
  font-size: 20px;
  position: relative;
  top: 3px;
}

div.in-run-inventory-panel div.your-inventory div.extraction-notification img.extraction-pointer {
  height: 40px;
  position: absolute;
  top: 65px;
  left: 65px;
}

div.in-run-inventory-panel div.your-inventory div.extraction-notification img.extraction-preview {
  height: 100px;
}

div.server-event-feed {
  transform-origin: 100% 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 600px;
  max-height: 300px;
  display: flex;
  position: absolute;
  bottom: 0;
  right: 0;
  overflow: hidden;
}

div.server-event-feed ul {
  text-align: right;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

div.server-event-feed ul li {
  text-align: right;
  color: #ccc;
  margin: 0;
  padding: 0;
  font-size: 22px;
  animation-duration: 30s;
}

div.server-event-feed ul li span.boss {
  text-shadow: 2px 2px #000;
  color: #900 !important;
}

div.server-event-feed ul li span.location {
  color: #ff0;
}

div.server-event-feed ul li span.username {
  vertical-align: top;
  color: #fff;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  max-width: 150px !important;
}

div.server-event-feed ul li span.common {
  color: #bfbfbf;
}

div.server-event-feed ul li span.uncommon {
  color: #1eff00;
}

div.server-event-feed ul li span.rare {
  color: #4d79ff;
}

div.server-event-feed ul li span.epic {
  color: #b366ff;
}

div.server-event-feed ul li span.legendary {
  color: #f90;
}

div.full-screen-error {
  pointer-events: all;
  background-color: #000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  inset: 0;
}

div.full-screen-error h2 {
  margin: 0;
  padding: 0;
  font-size: 100px;
}

div.full-screen-error p {
  text-align: center;
  max-width: 400px;
  margin: 10px 0;
  padding: 0;
  font-size: 24px;
}

div.full-screen-error button {
  text-align: center;
  max-width: 400px;
  margin: 10px 0;
  padding: 7px 14px;
  font-size: 24px;
}

div.gear-and-weapon-hud {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  bottom: 0;
}

div.gear-and-weapon-hud div.gear-slots {
  pointer-events: all;
  background-image: url("in-game-gear-display.56423dfc.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 246px;
  height: 81px;
  position: relative;
  top: 15px;
}

div.gear-and-weapon-hud div.gear-slots div.hud-gear-slot.on-cooldown {
  opacity: .45;
}

div.gear-and-weapon-hud div.gear-slots div.gear-slot-one p.hotkey, div.gear-and-weapon-hud div.gear-slots div.gear-slot-two p.hotkey, div.gear-and-weapon-hud div.gear-slots div.gear-slot-three p.hotkey {
  color: #fff;
  text-shadow: 3px 3px #000;
  z-index: 9999;
  text-align: center;
  pointer-events: none;
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: bold;
  position: absolute;
  top: -20px;
  left: 0;
}

div.gear-and-weapon-hud div.gear-slots div.gear-slot-one p.charges, div.gear-and-weapon-hud div.gear-slots div.gear-slot-two p.charges, div.gear-and-weapon-hud div.gear-slots div.gear-slot-three p.charges {
  pointer-events: none;
  color: #fff;
  text-shadow: 3px 3px #000;
  z-index: 9999;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

div.gear-and-weapon-hud div.gear-slots div.gear-slot-one p.cooldown, div.gear-and-weapon-hud div.gear-slots div.gear-slot-two p.cooldown, div.gear-and-weapon-hud div.gear-slots div.gear-slot-three p.cooldown {
  pointer-events: none;
  color: #fff;
  text-shadow: 3px 3px #000;
  z-index: 9999;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

div.gear-and-weapon-hud div.gear-slots div.gear-slot-one {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 15px;
  left: 36px;
}

div.gear-and-weapon-hud div.gear-slots div.gear-slot-two {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 15px;
  left: calc(50% - 23px);
}

div.gear-and-weapon-hud div.gear-slots div.gear-slot-three {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 15px;
  right: 34px;
}

div.gear-and-weapon-hud div.weapon-slots {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 195px;
  margin-bottom: 10px;
  transition: all .3s;
  display: flex;
}

div.gear-and-weapon-hud div.weapon-slots .weapon-one, div.gear-and-weapon-hud div.weapon-slots .weapon-two {
  pointer-events: all;
}

div.gear-and-weapon-hud div.weapon-slots .weapon-one.on-cooldown, div.gear-and-weapon-hud div.weapon-slots .weapon-two.on-cooldown {
  opacity: .45;
}

div.gear-and-weapon-hud div.weapon-slots .weapon-one.inactive, div.gear-and-weapon-hud div.weapon-slots .weapon-two.inactive {
  opacity: .65;
  transform: scale(.8);
}

div.gear-and-weapon-hud div.weapon-slots .weapon-one.active, div.gear-and-weapon-hud div.weapon-slots .weapon-two.active {
  transform: scale(1.1);
}

div.gear-and-weapon-hud div.weapon-slots .weapon-one p.hotkey, div.gear-and-weapon-hud div.weapon-slots .weapon-two p.hotkey {
  color: #fff;
  text-shadow: 3px 3px #000;
  z-index: 9999;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 48px;
  font-weight: bold;
  position: absolute;
  top: -20px;
  left: 7px;
}

div.gear-and-weapon-hud div.weapon-slots .weapon-one p.cooldown, div.gear-and-weapon-hud div.weapon-slots .weapon-two p.cooldown {
  pointer-events: none;
  color: #fff;
  text-shadow: 3px 3px #000;
  z-index: 9999;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

div.gear-and-weapon-hud div.weapon-slots .weapon-one p.cooldown span, div.gear-and-weapon-hud div.weapon-slots .weapon-two p.cooldown span {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 10px;
  position: absolute;
  top: -8px;
  left: 21px;
}

.user-identity-error {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.user-identity-error .user-identity-error-panel {
  text-align: center;
  border-image: url("basic-frame.79f9f824.png") 29% 28% fill / 40px / 0 repeat;
  max-width: 500px;
  padding: 25px;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

.user-identity-error .user-identity-error-panel h2 {
  font-size: 36px;
}

.user-identity-error .user-identity-error-panel p {
  font-size: 22px;
}

.user-identity-error .user-identity-error-panel button {
  pointer-events: all;
  padding-left: 60px;
  padding-right: 60px;
}

.character-skin-slot {
  cursor: pointer;
  border-image: url("slot-character-skin.d4cc69b3.png") 43% fill / 20px / 0;
  justify-content: center;
  align-items: center;
  width: 90px;
  height: 155px;
  padding: 25px;
  display: flex;
  position: relative;
  background-color: none !important;
  background: none !important;
}

.character-skin-slot img {
  transform-origin: center;
  transform: scale(4);
}

.character-skin-slot:hover div.selection-parent, .character-skin-slot.selected div.selection-parent {
  display: flex;
}

.character-skin-slot div.selection-parent {
  display: none;
}

.character-skin-slot div.selection-parent div.selection-corner {
  background-image: url("selection-corner.9194430c.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 16px;
  height: 16px;
  position: absolute;
}

.character-skin-slot div.selection-parent div.selection-corner.top-left {
  animation: .8s linear infinite topleftanim;
  top: 0%;
  left: 0%;
}

.character-skin-slot div.selection-parent div.selection-corner.top-right {
  animation: .8s linear infinite toprightanim;
  top: 0%;
  right: 0%;
  rotate: 90deg;
}

.character-skin-slot div.selection-parent div.selection-corner.bottom-left {
  animation: .8s linear infinite bottomleftanim;
  bottom: 0%;
  left: 0%;
  rotate: 270deg;
}

.character-skin-slot div.selection-parent div.selection-corner.bottom-right {
  animation: .8s linear infinite bottomrightanim;
  bottom: 0%;
  right: 0%;
  rotate: 180deg;
}

div.item-container-panel {
  pointer-events: all;
  -webkit-user-select: all;
  user-select: all;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 288px;
  height: 375px;
  padding: 20px 40px;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
}

div.item-container-panel div.loot {
  width: 100%;
  height: 265px;
}

div.item-container-panel div.loot h3 {
  text-align: center;
  text-overflow: ellipsis;
  margin: 10px 0;
  padding: 0;
  font-size: 38px;
  overflow: hidden;
}

div.item-container-panel div.loot .item-container {
  background-color: #0009;
  width: 100%;
  height: 188px;
  margin: 0;
  padding: 0;
  overflow-y: scroll;
}

div.item-container-panel div.actions {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

div.item-container-panel div.actions button {
  font-size: 24px;
}

div.extraction-bar {
  background-color: #000;
  border: 1px solid #eee;
  border-radius: 5px;
  width: 400px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  position: fixed;
  top: 300px;
  left: 0;
  right: 0;
}

div.extraction-bar .extraction-bar-progress {
  background-color: #b64f4f99;
  border-radius: 5px;
  height: 100%;
  position: absolute;
  top: 0;
}

div.extraction-bar .extraction-bar-text {
  text-align: center;
  color: #eee;
  z-index: 99999;
  width: 100%;
  font-size: 32px;
  line-height: 40px;
}

div.extraction-successful-panel {
  pointer-events: all;
  -webkit-user-select: all;
  user-select: all;
  border-image: url("metal-frame.465d04c1.png") 30% 21% fill / 35px / 0 repeat;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  animation-duration: 3s;
  display: flex;
  transform: translateZ(1px);
  background-color: none !important;
  background: none !important;
  height: 508px !important;
}

div.extraction-successful-panel div.socials-wrapper {
  position: absolute;
  top: 31px;
  right: 17px;
}

div.extraction-successful-panel h2 {
  text-shadow: 3px 3px #000;
  text-align: center;
  color: #006700;
  margin: 20px 0 0;
  padding: 0;
  font-size: 56px;
}

div.extraction-successful-panel h2 img {
  height: 60px;
  top: 10px;
}

div.extraction-successful-panel h2 img.left-skull {
  position: relative;
  left: -20px;
}

div.extraction-successful-panel h2 img.right-skull {
  position: relative;
  right: -20px;
}

div.extraction-successful-panel div.stats {
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-right: 20px;
  padding-top: 30px;
  display: flex;
}

div.extraction-successful-panel div.stats div.run-metric {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 35px;
}

div.extraction-successful-panel div.stats div.run-metric p {
  margin: 0;
  padding: 0;
}

div.extraction-successful-panel div.stats div.run-metric p.metric-title {
  text-align: center;
  font-size: 24px;
}

div.extraction-successful-panel div.stats div.run-metric p.metric-value {
  text-align: center;
  font-size: 36px;
}

div.extraction-successful-panel div.loot-acquired {
  width: 100%;
  height: 135px;
  margin-bottom: 50px;
}

div.extraction-successful-panel div.loot-acquired h3 {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 32px;
}

div.extraction-successful-panel div.loot-acquired .item-container {
  background-color: #0009;
  width: 624px;
  height: 135px;
  display: flex;
  overflow-y: scroll;
}

div.extraction-successful-panel div.actions {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

div.extraction-successful-panel div.actions span.delete-acc-cta {
  color: green;
  text-align: center;
  max-width: 375px;
  max-height: 100px;
  font-size: 16px;
  position: relative;
  top: 10px;
  left: 5px;
}

div.extraction-successful-panel div.actions button {
  flex-direction: row;
  max-width: 226px;
  margin: 0 20px 10px 0;
  font-size: 24px;
  display: flex;
  position: relative;
  left: 20px;
}

div.extraction-time-remaining {
  text-align: center;
  background-color: #0009;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  width: 400px;
  padding: 0 20px 10px;
  transition: all 5s;
  display: flex;
  position: absolute;
  top: 5px;
}

div.extraction-time-remaining h2, div.extraction-time-remaining h1, div.extraction-time-remaining h3 {
  text-align: center;
  margin: 0;
  padding: 0;
}

div.extraction-time-remaining h2 {
  color: #c00;
  text-align: center;
  width: 100%;
  font-size: 24px;
  display: none;
}

div.extraction-time-remaining h3 {
  font-size: 28px;
}

div.extraction-time-remaining h1 {
  font-size: 32px;
  position: relative;
  top: 7px;
}

div.extraction-time-remaining.close-to-expiry {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 400px;
  padding: 0 20px 7px;
  display: flex;
  top: 50px;
  transform: scale(1);
}

div.extraction-time-remaining.close-to-expiry h2, div.extraction-time-remaining.close-to-expiry h1, div.extraction-time-remaining.close-to-expiry h3 {
  text-align: center;
  color: #c00;
}

div.extraction-time-remaining.close-to-expiry h2 {
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  display: flex;
}

img.hidden-preload {
  display: none;
}

* {
  image-rendering: crisp-edges;
  image-rendering: pixelated;
  box-sizing: border-box;
  -webkit-user-select: none;
  user-select: none;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

option {
  color: #000 !important;
}

body {
  background-color: #282828;
  justify-content: center;
  align-items: center;
  display: flex;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

#stats {
  justify-content: center;
  align-items: center;
  display: none;
  position: absolute;
  bottom: 0;
}

#stats canvas {
  display: flex !important;
}

#game-container {
  background-color: #000;
  background-image: url("bg.311191da.png");
  background-position: center;
  background-size: cover;
  border: none;
  border-radius: 0;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

#game-container .drag-handle {
  background-color: green;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 5px;
  right: 5px;
}

#dom-load {
  background-color: #000;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  inset: 0;
}

#dom-load span {
  font-size: 24px;
}

#dom-load img {
  width: 100px;
  height: 100px;
}

#ui-root {
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.game-hud {
  pointer-events: none;
  z-index: 1000;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.screen-router {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.main-menu .center-content {
  pointer-events: all;
}

.left-inventory-containers {
  transform-origin: 0 0;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 50%;
  bottom: 0;
  left: 0;
  transform: translateY(-50%);
}

.left-inventory-containers div.in-run-inventory-panel {
  margin-right: 20px;
}

:root {
  --rt-color-white: #fff;
  --rt-color-dark: #222;
  --rt-color-success: #8dc572;
  --rt-color-error: #be6464;
  --rt-color-warning: #f0ad4e;
  --rt-color-info: #337ab7;
  --rt-opacity: .9;
}

.styles-module_tooltip__mnnfp {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  will-change: opacity, visibility;
  border-radius: 3px;
  width: max-content;
  padding: 8px 16px;
  font-size: 90%;
  transition: opacity .3s ease-out;
  position: absolute;
  top: 0;
  left: 0;
}

.styles-module_fixed__7ciUi {
  position: fixed;
}

.styles-module_arrow__K0L3T {
  background: inherit;
  width: 8px;
  height: 8px;
  position: absolute;
  transform: rotate(45deg);
}

.styles-module_noArrow__T8y2L {
  display: none;
}

.styles-module_clickable__Bv9o7 {
  pointer-events: auto;
}

.styles-module_show__2NboJ {
  visibility: visible;
  opacity: var(--rt-opacity);
}

.styles-module_dark__xNqje {
  background: var(--rt-color-dark);
  color: var(--rt-color-white);
}

.styles-module_light__Z6W-X {
  background-color: var(--rt-color-white);
  color: var(--rt-color-dark);
}

.styles-module_success__A2AKt {
  background-color: var(--rt-color-success);
  color: var(--rt-color-white);
}

.styles-module_warning__SCK0X {
  background-color: var(--rt-color-warning);
  color: var(--rt-color-white);
}

.styles-module_error__JvumD {
  background-color: var(--rt-color-error);
  color: var(--rt-color-white);
}

.styles-module_info__BWdHW {
  background-color: var(--rt-color-info);
  color: var(--rt-color-white);
}

/*# sourceMappingURL=index.2e67a79e.css.map */
