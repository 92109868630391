.stats-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    position: relative;

    .stats-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    div.overlay {
        position: absolute;
        top: 60px;
        left: 20px;
        right: 20px;
        bottom: 20px;
        display: flex;
        backdrop-filter: blur(12px);
        justify-content: center;
        align-items: center;
        flex-direction: column;

        span.delete-acc-cta {
            color: red;
            font-size: 28px;
            max-width: 560px;
            position: relative;
        }

        button.affirmative-button {
            font-size: 32px;
            position: relative;
            top: 20px;
        }
    }

    div.stats-container {
        width: 700px;
        height: 445px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;

        div.stat {
            width: 141px;
            height: 110px;

            div.stat-value {
                text-align: center;
                font-size: 36px;
            }

            div.stat-label {
                text-align: center;
                font-size: 17px;
            }
        }
    }

    button {
        position: relative;
        top: -10px;
        width: auto;
        margin-bottom: 30px;
        width: 200px;
        align-self: center;
    }
}
