div.gear-and-weapon-hud {
    bottom: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    // border: 1px solid red;

    div.gear-slots {
        height: 81px;
        width: 246px;
        background-image: url("../kit/in-game-gear-display.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        position: relative;
        pointer-events: all;
        top: 15px;
        // opacity: 0.75;

        div.hud-gear-slot {
            &.on-cooldown {
                opacity: 0.45;
            }
        }

        div.gear-slot-one,
        div.gear-slot-two,
        div.gear-slot-three {
            p.hotkey {
                position: absolute;
                padding: 0;
                top: -20px;
                left: 0;
                margin: 0;
                font-size: 32px;
                font-weight: bold;
                color: #fff;
                text-shadow: 3px 3px 0px #000;
                z-index: 9999;
                text-align: center;
                pointer-events: none;
            }

            p.charges {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 0;
                margin: 0;
                pointer-events: none;
                font-size: 18px;
                font-weight: bold;
                color: #fff;
                text-shadow: 3px 3px 0px #000;
                z-index: 9999;
                text-align: center;
            }

            p.cooldown {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 0;
                margin: 0;
                font-size: 16px;
                pointer-events: none;
                font-weight: bold;
                color: #fff;
                text-shadow: 3px 3px 0px #000;
                z-index: 9999;
                text-align: center;
            }
        }

        div.gear-slot-one {
            position: absolute;
            top: 15px;
            left: 36px;
            height: 50px;
            width: 50px;
        }
        div.gear-slot-two {
            position: absolute;
            top: 15px;
            left: calc(50% - 23px);
            position: absolute;
            height: 50px;
            width: 50px;
        }
        div.gear-slot-three {
            position: absolute;
            top: 15px;
            right: 34px;
            height: 50px;
            width: 50px;
        }
    }

    div.weapon-slots {
        width: 195px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
        transition: all 300ms ease;

        .weapon-one,
        .weapon-two {
            pointer-events: all;
            // transition: all 300ms ease;

            &.on-cooldown {
                opacity: 0.45;
            }

            &.inactive {
                transform: scale(0.8);
                opacity: 0.65;
            }

            &.active {
                transform: scale(1.1);
                // opacity: 0.75;
            }

            p.hotkey {
                position: absolute;
                padding: 0;
                margin: 0;
                font-size: 48px;
                font-weight: bold;
                color: #fff;
                text-shadow: 3px 3px 0px #000;
                z-index: 9999;
                text-align: center;
                top: -20px;
                left: 7px;

                // &.hotkey-1 {
                //     top: 0px;
                //     left: 14px;
                // }

                // &.hotkey-2 {
                //     top: 2px;
                //     left: 15px;
                // }
            }

            p.cooldown {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 0;
                margin: 0;
                font-size: 20px;
                pointer-events: none;
                font-weight: bold;
                color: #fff;
                text-shadow: 3px 3px 0px #000;
                z-index: 9999;
                text-align: center;
                span {
                    font-size: 10px;
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    position: absolute;
                    top: -8px;
                    left: 21px;
                }
            }
        }
    }
}
