.register-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    max-width: 600px;

    .register-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    p.terms-prompt {
        font-size: 15px;
        text-align: center;
    }

    div.checkboxes {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;

        .setting-row {
            margin: 0 10px;
            // border: 1px solid red;
            display: flex;
            flex-direction: row;
            // justify-content: space-between;
            // margin-bottom: 35px;
            padding-bottom: 3px;
            // border-bottom: 2px solid #1e2819;
            // align-items: center;
            // justify-content: center;

            .setting-label {
                font-size: 15px;
                // width: 195px;
                text-align: center;
                // margin-right: 50px;
                // text-align: center;
            }

            .setting-control {
                position: relative;
                top: -10px;
                margin: 5px;
                // margin-left: 20px;
                select {
                    width: 200px;
                    color: black;
                    font-size: 26px;
                    text-shadow: none;
                }

                div.toggle-switch {
                    background-image: url("../kit/item-slot-option-3.png");
                    height: 36px;
                    width: 36px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .toggle-switch-checked {
                        background-image: url("../kit/checkbox-checked.png");
                        height: 32px;
                        width: 32px;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                        position: relative;
                        top: -3px;
                    }
                    .toggle-switch-unchecked {
                        background-image: url("../kit/checkbox-unchecked.png");
                        height: 32px;
                        width: 32px;
                        background-size: 100% 100%;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }

    div.register-socials {
        display: flex;
        flex-direction: row;

        a {
            margin: 0 10px;
        }
    }

    div.register-or-separator {
        font-size: 36px;
        text-align: center;
        margin: 20px 0 5px 0;
    }

    .register-form {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
        }

        div.errors {
            margin-bottom: 30px;
            p {
                font-size: 24px;
                // text-shadow: 0 0;
                color: red;
                // color: lighten(red, 10%);
            }
        }

        div.form-row-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            div.form-label {
                font-size: 24px;
                display: flex;
                align-items: flex-start;
            }

            div.form-input {
                display: flex;
                align-items: flex-start;
                input {
                    color: black;
                    text-shadow: 0 0;
                    height: 30px;
                    width: 300px;
                    font-size: 24px;
                    padding: 2px 10px 0 5px;
                }
            }
        }
    }

    .register-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;

        button {
            &:last-of-type {
                width: 150px;
            }
        }
    }

    button {
        margin: 0 15px 30px 15px;
        width: auto;
        width: 200px;
        align-self: center;
    }

    .register-btn {
        width: 300px;
    }
}
