.collection-log-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    position: relative;

    .collection-log-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
        font-size: 32px;
    }

    div.log {
        display: flex;
        flex-direction: row;
        height: 300px;
        div.log-categories {
            height: 100%;
            width: 175px;
            // border: 1px solid red;
            padding: 3px 0 0 0;
            background-color: rgba(0, 0, 0, 0.4);

            div.category {
                padding: 3px 5px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                width: 100%;
                border-bottom: 1px solid #333;
                cursor: pointer;
                font-size: 22px;

                &.boss {
                    color: darken(red, 10%);
                }

                &.active {
                    background-color: rgba(0, 0, 0, 0.6);
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.6);
                }
            }
        }
        div.log-data {
            height: 100%;
            width: 400px;
            border: 1px solid #333;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
            flex-direction: column;

            div.meta {
                // border: 1px solid red;
                width: 100%;
                height: 100px;
                display: flex;
                justify-content: center;
                align-items: center;
                max-height: 100px;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 0.3);

                img {
                    &.gronk {
                        transform: scale(2);
                        position: relative;
                        top: -13px;
                    }
                    &.allenemies {
                        transform: scale(3.5);
                    }
                    &.lootchest {
                        transform: scale(2.3);
                    }
                    &.darkwizard {
                        transform: scale(1.8);
                    }
                    &.ogre {
                        transform: scale(1.3);
                        position: relative;
                        top: -7px;
                    }
                    &.treant {
                        transform: scale(1.5);
                    }
                    &.skeleton {
                        transform: scale(2);
                    }
                    &.ghost {
                        transform: scale(1.8);
                    }
                    &.giantrat {
                        transform: scale(2.5);
                        position: relative;
                        top: -9px;
                    }
                }
            }

            div.log-items {
                // border: 1px solid blue;
                width: 100%;
                height: 100%;
            }
        }
    }

    button.neutral-button {
        width: 150px;
        align-self: center;
        margin-top: 10px;
        margin-bottom: 25px;
    }
}
