div.region-pop-up {
    position: fixed;
    bottom: 145px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    left: 0;
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    transform-origin: bottom center;
    animation-duration: 3000ms;

    h2 {
        margin: 0;
        padding: 0;
        font-size: 46px;
    }

    div.underline {
        border: 1px saturate(white, 100%) solid;
        height: 1px;
        width: 300px;
        box-shadow: 0 0 40px rgba(255, 255, 255, 0.1);
        // box-shadow: inset 0 0 5px 0 #fff;
    }
}
