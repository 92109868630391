div.top-left-hud {
    top: 0;
    left: 0;
    padding: 5px;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;

    .ping,
    .kills {
        margin: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        img {
            position: relative;
            top: -3px;
            width: 30px;
            margin-right: 5px;

            &.bad {
                filter: hue-rotate(90deg);
            }
        }
    }

    button.fullscreen-button {
        pointer-events: all;
        position: absolute;
        width: 150px;
        height: 35px;
        top: 9px;
        left: 195px;
        font-size: 18px;
        text-overflow: unset;

        &.is-full-screen {
            font-size: 14px;
        }
        span {
            position: relative;
            top: -10px;
            left: -10px;
        }
    }
}
