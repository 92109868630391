.settings-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;

    .settings-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    .setting-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 35px;
        padding-bottom: 3px;
        border-bottom: 2px solid #1e2819;
        align-items: flex-end;

        .setting-label {
            font-size: 26px;
            margin-right: 50px;
        }

        .setting-control {
            select {
                width: 200px;
                color: black;
                font-size: 26px;
                text-shadow: none;
            }

            div.toggle-switch {
                background-image: url('../kit/item-slot-option-3.png');
                height: 36px;
                width: 36px;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;

                .toggle-switch-checked {
                    background-image: url('../kit/checkbox-checked.png');
                    height: 32px;
                    width: 32px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                    position: relative;
                    top: -3px;
                }
                .toggle-switch-unchecked {
                    background-image: url('../kit/checkbox-unchecked.png');
                    height: 32px;
                    width: 32px;
                    background-size: 100% 100%;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .settings-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        margin-bottom: 20px;

        button {
            &:disabled {
                padding: 0 35px 10px 35px;
            }
        }
    }
}
