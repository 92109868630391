.insurance-purchase-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    position: relative;

    .insurance-purchase-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    p.not-registered {
        font-size: 24px;
        text-align: center;
        color: red;
    }

    button {
        position: relative;
        top: -10px;
        width: auto;
        margin-bottom: 30px;
        width: 200px;
        align-self: center;
    }
}
