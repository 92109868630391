div.socials {
    margin-left: auto;
    margin-right: auto;
    border-radius: 5px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    text-align: center;
    align-items: center;
    justify-content: center;

    transform-origin: bottom center;

    div.social-links-wrapper {
        position: relative;
        // left: 15px;
        height: 63px;
        top: 5px;
        a.social-link {
            pointer-events: all;
            opacity: 0.7;
            cursor: pointer;
            text-decoration: none;
            height: 60px;
            width: 60px;
            display: inline-block;
            margin: 0 10px;
            image-rendering: auto !important;
            transition: all 200ms ease;

            &:hover {
                opacity: 1;
            }

            &.twitter {
                background-image: url(../kit/socials_twitter.png);
                background-size: 100% 100%;
            }

            &.discord {
                background-image: url(../kit/socials_discord.png);
                background-size: 100% 100%;
            }

            &.website {
                background-image: url(../kit/socials_smg.png);
                background-size: 100% 100%;
                height: 80px;
                width: 80px;
                position: relative;
                left: -10px;
                top: 8px;
            }
        }
    }
}
