div.item-container-panel {
    .metal-panel();
    border-radius: 20px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    user-select: all;
    width: 288px;
    height: 375px;

    div.loot {
        height: 265px;
        width: 100%;

        h3 {
            text-align: center;
            font-size: 38px;
            padding: 0;
            margin: 10px 0;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .item-container {
            // width: 675px;
            overflow-y: scroll;
            // height: 100%;
            height: 188px;
            width: 100%;
            padding: 0;
            margin: 0;
            background-color: rgba(0, 0, 0, 0.6);
            // border: 1px solid red;
        }
    }

    div.actions {
        // padding-top: 25px;
        // padding-bottom: 10px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        button {
            // margin: 0 40px 10px 0;
            font-size: 24px;
        }
    }
}
