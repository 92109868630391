div.top-right-hud {
    top: 0;
    right: 0;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    div.leaderboard {
        // height: 200px;
        width: 295px;
        background-color: rgba(0, 0, 0, 0.6);
        border-radius: 3px;
        margin: 3px 3px 0 0;
        padding: 5px 10px 5px 25px;
        transform: scale(0.9);
        transform-origin: top right;

        &.hidden {
            width: 350px;
            background: none;
            font-size: 24px;

            .leaderboard-close {
                font-size: 24px;
                margin-top: 0;
            }
        }

        div.leaderboard-title {
            margin-top: 2px;
            margin-bottom: 5px;
            font-size: 24px;
            font-weight: bold;
            text-decoration: underline;
            display: flex;
            justify-content: center;
        }
        div.leaderboard-legend {
            div.npc-kills,
            div.player-kills,
            div.items-looted {
                font-size: 14px;
            }
        }

        div.leaderboard-row,
        div.leaderboard-you,
        div.leaderboard-legend {
            display: flex;
            flex-direction: row;
            position: relative;

            img.registered-user-icon {
                position: absolute;
                left: -25px;
                top: -3px;
                height: 24px;
                width: 24px;
            }

            div.rank {
                flex: 1;
            }
            div.username {
                pointer-events: all !important;
                flex: 3;
                padding-left: 10px;
                text-overflow: ellipsis;
                overflow: hidden;
            }
            div.npc-kills,
            div.player-kills,
            div.items-looted {
                flex: 1;
                display: flex;
                justify-content: center;
            }
        }

        div.leaderboard-you {
            * {
                color: green !important;
            }
        }

        div.leaderboard-close {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            margin-top: 10px;
        }
    }
    div.stats-preview {
    }
}
