div.screen-too-small {
    display: none;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);

    h2 {
        padding: 0 20px;
        font-size: 36px;
        text-align: center;
        max-width: 600px;

        span {
            color: red;
            font-size: 56px;
        }
    }
}

//#region Biggest Game Size Possible Breakpoint (3840x2160)
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 3840x2160                    */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 3840px), (max-height: 2160px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(2.2) !important;
        transform-origin: top left;
        margin-top: 65px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: 0px;
        transform: translateZ(1px) scale(1.8) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(2.2) !important;
    }

    div.how-to-play-panel {
        transform: translateZ(1px) scale(2) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(2.5) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(2) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(2) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(2) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(2) !important;
    }

    div.runinprogress-panel {
        transform: translateZ(1px) scale(2) !important;
    }
    div.register-panel {
        transform: translateZ(1px) scale(2) !important;
    }
    div.login-panel {
        transform: translateZ(1px) scale(2) !important;
    }
    div.partners-panel {
        transform: translateZ(1px) scale(2.2) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(2.2) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(2.5) !important;
    }

    div.game-hud {
        div.server-event-feed {
            transform: translateZ(1px) scale(1.7) !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(2.2) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(2.2) !important;
        }
        div.top-left-hud {
            transform: translateZ(1px) scale(2) !important;
            transform-origin: top left;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(2.2) !important;
            transform-origin: top right;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(2.2) !important;
            transform-origin: bottom left;
        }
        div.extraction-time-remaining {
            top: 18px !important;
            transform: translateZ(1px) scale(2.2) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(2) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            transform: translateZ(1px) scale(2.2) !important;
        }
        div.region-pop-up {
            bottom: 175px !important;
            transform: translateZ(1px) scale(2.2) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(2) translateY(-60%) !important;
        }
    }
}
//#endregion

//#region Big Game Size Breakpoint (2560x1440)
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 2560x1440                    */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 2560px), (max-height: 1440px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(1.5) !important;
        transform-origin: top left;
        margin-top: 65px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: 0px;
        transform: translateZ(1px) scale(1.4) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(2) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }

    div.how-to-play-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }

    div.runinprogress-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }
    div.register-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }
    div.login-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }
    div.partners-panel {
        transform: translateZ(1px) scale(1.8) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(1.8) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(2.2) !important;
    }

    div.game-hud {
        div.server-event-feed {
            transform: translateZ(1px) scale(1.3) !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(1.8) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(1.8) !important;
        }
        div.top-left-hud {
            transform: translateZ(1px) scale(1) !important;
            transform-origin: top left;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(1.5) !important;
            transform-origin: top right;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(1.5) !important;
            transform-origin: bottom left;
        }
        div.extraction-time-remaining {
            top: 18px !important;
            transform: translateZ(1px) scale(1.5) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(1.25) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            transform: translateZ(1px) scale(1.5) !important;
        }
        div.region-pop-up {
            bottom: 175px !important;
            transform: translateZ(1px) scale(1.5) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(1.25) translateY(-60%) !important;
        }
    }
}
//#endregion

//#region Game Size 1920x1080
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 1920x1080                    */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1920px), (max-height: 1080px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(1.25) !important;
        transform-origin: top left;
        margin-top: 50px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: 0px;
        transform: translateZ(1px) scale(1) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.runinprogress-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }
    div.register-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }
    div.login-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }
    div.partners-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(1.5) !important;
    }
    div.how-to-play-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(1.8) !important;
    }

    div.game-hud {
        div.server-event-feed {
            transform: translateZ(1px) scale(0.9) !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(1.4) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(1.6) !important;
        }
        div.top-left-hud {
            transform: translateZ(1px) scale(0.75) !important;
            transform-origin: top left;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(1.15) !important;
            transform-origin: top right;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(1.1) !important;
            transform-origin: bottom left;
        }
        div.extraction-time-remaining {
            top: 7px !important;
            transform: translateZ(1px) scale(1.15) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(1.1) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            transform: translateZ(1px) scale(1.15) !important;
        }
        div.region-pop-up {
            bottom: 175px !important;
            transform: translateZ(1px) scale(1.5) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(1.1) translateY(-60%) !important;
        }
    }
}
//#endregion

//#region Game Size 1600x900
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 1600x900                     */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1600px), (max-height: 900px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(1) !important;
        transform-origin: top left;
        margin-top: 50px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: 0px;
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(1.2) !important;
    }

    div.runinprogress-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }
    div.register-panel {
        transform: translateZ(1px) scale(1.15) !important;
    }
    div.login-panel {
        transform: translateZ(1px) scale(1.15) !important;
    }
    div.partners-panel {
        transform: translateZ(1px) scale(1.4) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(1.2) !important;
    }
    div.how-to-play-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.game-hud {
        div.server-event-feed {
            transform: translateZ(1px) scale(0.9) !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(1.2) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(1.4) !important;
        }
        div.top-left-hud {
            transform: translateZ(1px) scale(0.65) !important;
            transform-origin: top left;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(1) !important;
            transform-origin: top right;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(1) !important;
            transform-origin: bottom left;
        }
        div.extraction-time-remaining {
            top: 5px !important;
            transform: translateZ(1px) scale(1) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(1) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            top: 175px !important;
            transform: translateZ(1px) scale(1) !important;
        }
        div.region-pop-up {
            bottom: 165px !important;
            transform: translateZ(1px) scale(1.25) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(0.9) translateY(-60%) !important;
        }
    }
}
//#endregion

//#region Game Size 1376x768
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 1376x768                     */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1376px), (max-height: 768px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(1) !important;
        transform-origin: top left;
        margin-top: 50px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: -20px;
        transform: translateZ(1px) scale(0.8) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(1) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(1.2) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(1) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(1) !important;
    }

    div.how-to-play-panel {
        transform: translateZ(1px) scale(0.8) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(1) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(1) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(1.3) !important;
    }

    div.game-hud {
        div.server-event-feed {
            transform: translateZ(1px) scale(0.8) !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(1) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(1.2) !important;
        }
        div.top-left-hud {
            transform: translateZ(1px) scale(0.6) !important;
            transform-origin: top left;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(0.8) !important;
            transform-origin: top right;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(0.75) !important;
            transform-origin: bottom left;
        }
        div.extraction-time-remaining {
            top: 5px !important;
            transform: translateZ(1px) scale(0.9) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(0.8) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            top: 125px !important;
            transform: translateZ(1px) scale(1) !important;
        }
        div.region-pop-up {
            bottom: 125px !important;
            transform: translateZ(1px) scale(1.1) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(0.8) translateY(-60%) !important;
        }
    }
}
//#endregion

//#region Game Size 1129x616
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 1129x616                     */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 1129px), (max-height: 616px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(1) !important;
        transform-origin: top left;
        margin-top: 50px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: 0px;
        transform: translateZ(1px) scale(0.7) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(0.8) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(1) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(1) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(0.8) !important;
    }

    div.how-to-play-panel {
        transform: translateZ(1px) scale(0.75) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.runinprogress-panel {
        transform: translateZ(1px) scale(1) !important;
    }
    div.register-panel {
        transform: translateZ(1px) scale(0.85) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(1.1) !important;
    }

    div.game-hud {
        div.server-event-feed {
            transform: translateZ(1px) scale(0.7) !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(0.75) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(1) !important;
        }
        div.top-left-hud {
            transform: translateZ(1px) scale(0.5) !important;
            transform-origin: top left;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(0.8) !important;
            transform-origin: top right;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(0.65) !important;
            transform-origin: bottom left;
        }
        div.extraction-time-remaining {
            top: 5px !important;
            transform: translateZ(1px) scale(0.9) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(0.65) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            top: 70px !important;
            transform: translateZ(1px) scale(1) !important;
        }
        div.region-pop-up {
            bottom: 85px !important;
            transform: translateZ(1px) scale(0.8) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(0.7) translateY(-60%) !important;
        }
    }
}
//#endregion

//#region Game Size 939x504
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 939x504                      */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 939px), (max-height: 504px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(1) !important;
        transform-origin: top left;
        margin-top: 50px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: 0px;
        transform: translateZ(1px) scale(0.6) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(0.78) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(0.7) !important;
    }

    div.how-to-play-panel {
        transform: translateZ(1px) scale(0.6) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(0.75) !important;
    }

    div.runinprogress-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }
    div.login-panel {
        transform: translateZ(1px) scale(0.85) !important;
    }
    div.register-panel {
        transform: translateZ(1px) scale(0.7) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(0.7) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(0.89) !important;
    }

    div.game-hud {
        div.server-event-feed {
            transform: translateZ(1px) scale(0.6) !important;
            width: 500px !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(0.65) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(0.85) !important;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(0.7) !important;
            transform-origin: top right;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(0.65) !important;
            transform-origin: bottom left;
        }
        div.extraction-time-remaining {
            top: 5px !important;
            transform: translateZ(1px) scale(0.9) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(0.65) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            top: 55px !important;
            transform: translateZ(1px) scale(1) !important;
        }
        div.region-pop-up {
            bottom: 85px !important;
            transform: translateZ(1px) scale(0.8) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(0.6) translateY(-60%) !important;
        }
    }
}
//#endregion

//#region Game Size 750x440
/*************************************************************/
/*                                                           */
/*                  BREAKPOINT: 750x440                      */
/*                                                           */
/*************************************************************/
@media screen and (max-width: 750px), (max-height: 440px) {
    .Toastify__toast-container--top-center {
        transform: translateZ(1px) scale(0.7) !important;
        transform-origin: top left;
        margin-top: 20px !important;

        .Toastify__toast {
            position: relative;
            left: -155px;
        }
    }
    div.main-menu div.center-content {
        position: relative;
        top: 0px;
        transform: translateZ(1px) scale(0.5) !important;
    }

    div.main-menu-right-container {
        transform-origin: right center;
        transform: translateZ(1px) scale(0.8) !important;
    }

    div.main-menu-left-container {
        transform-origin: left center;
        transform: translateZ(1px) scale(0.8) !important;
    }

    div.runprep-panel {
        transform: translateZ(1px) scale(0.65) !important;
    }

    div.settings-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.vendors-panel,
    div.rankings-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.change-username-panel {
        transform: translateZ(1px) scale(0.9) !important;
    }

    div.inventory-panel {
        transform: translateZ(1px) scale(0.65) !important;
    }

    div.how-to-play-panel {
        transform: translateZ(1px) scale(0.57) !important;
    }

    div.stats-panel {
        transform: translateZ(1px) scale(0.65) !important;
    }

    div.runinprogress-panel {
        transform: translateZ(1px) scale(0.85) !important;
    }
    div.login-panel {
        transform: translateZ(1px) scale(0.85) !important;
    }
    div.register-panel {
        transform: translateZ(1px) scale(0.65) !important;
    }
    div.patch-notes-panel {
        transform: translateZ(1px) scale(0.6) !important;
    }
    div.collection-log-panel {
        transform: translateZ(1px) scale(0.7) !important;
    }

    div.game-hud {
        div.server-event-feed {
            width: 500px !important;
            transform: translateZ(1px) scale(0.5) !important;
        }
        div.you-died-panel {
            transform: translateZ(1px) scale(0.62) !important;
        }
        div.extraction-successful-panel {
            transform: translateZ(1px) scale(0.75) !important;
        }
        div.top-right-hud {
            transform: translateZ(1px) scale(0.7) !important;
            transform-origin: top right;
            display: none;
        }
        div.bottom-left-hud {
            transform: translateZ(1px) scale(0.65) !important;
            transform-origin: bottom left;

            .inventory-texts {
                display: none !important;
            }
        }
        div.extraction-time-remaining {
            top: -5px !important;
            transform: translateZ(1px) scale(0.6) !important;
        }
        div.gear-and-weapon-hud {
            transform: translateZ(1px) scale(0.5) !important;
            transform-origin: bottom center;
        }
        div.extraction-bar {
            top: 55px !important;
            transform: translateZ(1px) scale(0.6) !important;
        }
        div.region-pop-up {
            bottom: 80px !important;
            transform: translateZ(1px) scale(0.6) !important;
            transform-origin: bottom center;
        }
        div.left-inventory-containers {
            transform: translateZ(1px) scale(0.6) translateY(-60%) !important;
        }
    }
}
//#endregion

// crazy games #1: 1128x615
// crazy games #2: 938x503
// crazy games #3: 922x487
// silver games: 960x610
// iogames space: responsive
// addicting games:

//#region Global Way Too Small Breakpoints
// Global way too small controller
@media screen and (max-width: 350px), screen and (max-height: 350px) {
    div.screen-router {
        display: none !important;
    }
    div.screen-too-small {
        display: flex !important;
    }
}

html.mobile {
    div.screen-router {
        display: none !important;
    }
    div.screen-too-small {
        display: flex !important;
    }
}
//#endregion
