div.server-event-feed {
    position: absolute;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: row;
    transform-origin: bottom right;
    right: 0;
    bottom: 0;
    // top: 50%;
    // transform: translateY(-50%);
    // border: 1px solid red;
    // max-width: 500px;
    width: 600px;
    max-height: 300px;
    overflow: hidden;

    ul {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        list-style: none;
        align-items: flex-end;
        text-align: right;
        justify-content: flex-end;

        li {
            animation-duration: 30000ms;
            text-align: right;
            font-size: 22px;
            margin: 0;
            padding: 0;
            color: darken(white, 20%);

            span {
                &.boss {
                    color: darken(red, 20%) !important;
                    text-shadow: 2px 2px #000;
                }

                &.location {
                    color: yellow;
                }

                &.username {
                    vertical-align: top;
                    display: inline-block;
                    max-width: 150px !important;
                    overflow: hidden;
                    color: #fff;
                    text-overflow: ellipsis;
                }

                &.common {
                    color: #bfbfbf;
                }

                &.uncommon {
                    color: #1eff00;
                }

                &.rare {
                    color: #4d79ff;
                }

                &.epic {
                    color: #b366ff;
                }

                &.legendary {
                    color: #ff9900;
                }
            }
        }
    }
}
