html {
    .joystick-hud {
        display: none;
    }

    &.mobile {
        .joystick-hud {
            display: block;
        }
    }
}

.joystick-hud {
    position: absolute;
    bottom: 5vw;
    height: 80vh;
    width: 45vw;
    pointer-events: all;
}

#left-joystick-zone {
    left: 2vw;
    // border: 1px solid red;
}

#right-joystick-zone {
    right: 2vw;
    // border: 1px solid blue;
}
