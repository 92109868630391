div.loading-bar {
    position: fixed;
    bottom: 100px;
    height: 40px;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    background-color: black;
    border-radius: 5px;
    left: 0;
    right: 0;
    border: 1px solid #eee;
    z-index: 999;

    .loading-bar-progress {
        position: absolute;
        border-radius: 5px;
        top: 0;
        height: 100%;
        background-color: rgba(182, 79, 79, 0.6);
    }

    .loading-bar-text {
        font-size: 32px;
        width: 100%;
        text-align: center;
        line-height: 40px;
        color: #eee;
        z-index: 99999;
    }
}
