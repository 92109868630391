.right-container {
    position: fixed;
    right: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    pointer-events: all !important;
    .right-container-inner {
        pointer-events: all !important;
        height: 600px;
        width: 160px;
        // border: 1px solid red;
        // background: red;
    }
}
