.patch-notes-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    position: relative;
    height: 600px;

    .patch-notes-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    div.patch-notes {
        padding: 10px 10px;
        background-color: rgba(0, 0, 0, 0.3);
        height: 425px;
        width: 500px;
        overflow-x: hidden;
        overflow-y: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            width: 0 !important;
        }

        div.patch-segment {
            margin-bottom: 50px;

            h3 {
                padding: 0;
                margin: 0;
                font-size: 28px;
            }

            ul {
                li {
                    font-size: 22px;
                    margin-bottom: 8px;
                }
            }
        }
    }

    button.neutral-button {
        margin-top: 10px;
        align-self: center;
        width: 150px !important;
    }
}
