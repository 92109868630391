div.extraction-successful-panel {
    .metal-panel();
    border-radius: 20px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: all;
    user-select: all;
    animation-duration: 3000ms;
    height: 508px !important;

    div.socials-wrapper {
        position: absolute;
        top: 31px;
        right: 17px;
    }

    h2 {
        text-shadow: 3px 3px black;
        margin: 20px 0 0 0;
        padding: 0;
        font-size: 56px;
        text-align: center;
        color: darken(green, 5%);

        img {
            height: 60px;
            top: 10px;

            &.left-skull {
                position: relative;
                left: -20px;
            }

            &.right-skull {
                position: relative;
                right: -20px;
            }
        }
    }
    div.stats {
        width: 100%;
        padding-top: 30px;
        margin-right: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        div.run-metric {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 35px;

            p {
                margin: 0;
                padding: 0;
            }

            p.metric-title {
                font-size: 24px;
                text-align: center;
            }

            p.metric-value {
                text-align: center;
                font-size: 36px;
            }
        }
    }

    div.loot-acquired {
        height: 135px;
        width: 100%;
        margin-bottom: 50px;

        h3 {
            text-align: center;
            font-size: 32px;
            padding: 0;
            margin: 0;
        }

        .item-container {
            width: 624px;
            overflow-y: scroll;
            height: 135px;
            background-color: rgba(0, 0, 0, 0.6);
            display: flex;
        }
    }

    div.actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        // align-items: center;
        // text-align: center;

        span.delete-acc-cta {
            color: red;
            font-size: 16px;
            max-width: 375px;
            max-height: 100px;
            position: relative;
            top: 10px;
            left: 5px;
            color: green;
            text-align: center;
        }

        button {
            max-width: 226px;
            position: relative;
            left: 20px;
            margin: 0 20px 10px 0;
            font-size: 24px;
            display: flex;
            flex-direction: row;
        }
    }
}
