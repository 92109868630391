.bottom-container {
    // position: fixed;
    // bottom: 0px;
    // left: 0;
    // right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    pointer-events: all !important;
    .bottom-container-inner {
        pointer-events: all !important;
        height: 90px;
        width: 728px;
        // border: 1px solid red;
        // background: red;
    }
}
