.finding-match {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .finding-match-panel {
        .basic-panel();
        --animate-duration: 1.5s;
        max-width: 500px;
        text-align: center;

        h2 {
            width: 400px;
            font-size: 36px;
            padding: 0;
            margin: 0;
        }
    }
}
