div.bottom-left-hud {
    bottom: 0;
    left: 0;
    height: 100px;
    // width: 300px;
    padding: 0 0 15px 15px;
    position: absolute;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex-direction: row;

    div.bottom-left-inventory {
        display: flex;
        flex-direction: row;

        div.inventory-sprite {
            height: 50px;
            width: 50px;
            margin-right: 5px;
            position: relative;

            img {
                position: relative;
                top: -20px;
                left: -20px;
                width: 75px;
            }

            .item-quantity {
                position: absolute;
                bottom: 0;
                left: 0px;
                font-size: 26px;
            }
        }

        div.inventory-texts {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            div.inventory-text {
                font-weight: bold;
                font-size: 20px;
            }
        }
    }
}
