.login-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    max-width: 600px;

    .login-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    div.login-socials {
        display: flex;
        flex-direction: row;

        a {
            margin: 0 10px;
        }
    }

    div.login-or-separator {
        font-size: 36px;
        text-align: center;
        margin: 20px 0 5px 0;
    }

    .login-form {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        p {
            margin: 0;
            padding: 0;
        }

        div.errors {
            margin-bottom: 30px;
            p {
                font-size: 24px;
                // text-shadow: 0 0;
                color: red;
                // color: lighten(red, 10%);
            }
        }

        div.form-row-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 10px;

            div.form-label {
                font-size: 24px;
                display: flex;
                align-items: flex-start;
            }

            div.form-input {
                display: flex;
                align-items: flex-start;
                input {
                    color: black;
                    text-shadow: 0 0;
                    height: 30px;
                    width: 300px;
                    font-size: 24px;
                    padding: 2px 10px 0 5px;
                }
            }
        }
    }

    .login-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 10px;

        button {
            &:last-of-type {
                width: 150px;
            }
        }
    }

    button {
        margin: 0 15px 30px 15px;
        width: auto;
        width: 200px;
        align-self: center;
    }

    .login-btn {
        width: 300px;
    }
}
