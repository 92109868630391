body.en {
    div.main-menu-profile-panel button.neutral-button.inventory-btn,
    div.main-menu-profile-panel button.neutral-button.stats-btn,
    div.main-menu-profile-panel button.destructive-button {
        font-size: 34px !important;
    }
}

body.ru {
    div.main-menu-profile-panel button.inventory-btn {
        font-size: 20px !important;
    }
    div.main-menu-profile-panel button.change-username-btn {
        font-size: 14px !important;
    }
    div.main-menu-profile-panel button.stats-btn {
        font-size: 20px !important;
    }
    div.main-menu-profile-panel button.inventory-btn.destructive-button {
        font-size: 32px !important;
    }

    div.runprep-panel div.runprep-loadout button.neutral-button.insure-btn {
        font-size: 24px;
    }

    div.item-container-panel {
        h3 {
            font-size: 18px !important;
        }
    }

    div.runinprogress-panel {
        div.runinprogress-header {
            font-size: 20px !important;
        }
        button.destructive-button {
            font-size: 32px !important;
        }
    }

    div.extraction-notification {
        p {
            font-size: 16px !important;
        }
    }

    div.you-died-panel {
        p.nothing-insured {
            font-size: 15px !important;
        }
    }

    div.inventory-panel {
        p.item-category-title {
            font-size: 14px !important;
        }
    }

    div.change-username-panel div.change-username-header {
        font-size: 20px;
    }

    div.runprep-panel {
        div.runprep-stats {
            div.stat-category-title {
                font-size: 16px !important;
            }
        }

        div.runprep-insurance {
            p.smol {
                font-size: 16px !important;
            }

            button.destructive-button {
                line-height: 30px !important;
            }
        }
        div.runprep-actions {
            button {
                span {
                    position: relative;
                    top: -5px;
                }
            }
        }
    }
}

body.es {
    div.main-menu-primary-panel button.neutral-button.menu-btn.rankings-btn {
        font-size: 28px;
    }
    div.main-menu-primary-panel button.neutral-button.menu-btn.settings-btn {
        font-size: 28px;
    }

    div.change-username-panel div.change-username-header {
        font-size: 24px;
    }

    div.change-username-panel button.change-username-btn {
        font-size: 21px;
    }

    div.in-run-inventory-panel div.extraction-notification p {
        font-size: 18px !important;
    }

    div.item-container-panel h3 {
        font-size: 30px !important;
    }

    div.runinprogress-panel {
        div.runinprogress-header {
            font-size: 30px !important;
        }

        button.destructive-button {
            font-size: 26px;
        }
    }
}

body.pt {
    div.main-menu-profile-panel button.change-username-btn {
        font-size: 17px !important;
    }

    div.main-menu-primary-panel button.neutral-button.menu-btn.rankings-btn {
        font-size: 26px;
    }

    div.main-menu-primary-panel button.neutral-button.menu-btn.settings-btn {
        font-size: 26px;
    }

    div.runinprogress-panel {
        div.runinprogress-header {
            font-size: 32px !important;
        }
        button.destructive-button {
            font-size: 24px !important;
        }
    }

    div.rankings-panel {
        div.rankings-header {
            font-size: 32px !important;
        }
    }

    div.vendors-panel {
        div.vendors-header {
            font-size: 32px !important;
        }
    }

    div.runprep-insurance {
        p.smol {
            font-size: 16px !important;
        }

        button.destructive-button {
            line-height: 30px !important;
        }
    }
    div.runprep-actions {
        button {
            span {
                position: relative;
                top: -5px;
            }
        }
    }
    div.settings-header {
        font-size: 26px !important;
    }

    div.change-username-panel div.change-username-header {
        font-size: 20px;
    }

    div.change-username-panel button.affirmative-button {
        font-size: 28px;
    }

    div.inventory-panel {
        p.item-category-title {
            font-size: 18px !important;
        }
    }

    div.extraction-notification {
        p {
            position: relative;
            top: 15px !important;
            font-size: 16px !important;
        }
    }

    div.extraction-successful-panel {
        h2 {
            font-size: 32px !important;
        }
    }
}
