.user-identity-error {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .user-identity-error-panel {
        .basic-panel();
        max-width: 500px;
        text-align: center;

        h2 {
            font-size: 36px;
        }

        p {
            font-size: 22px;
        }

        button {
            pointer-events: all;
            padding-left: 60px;
            padding-right: 60px;
        }
    }
}
