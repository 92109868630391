.inventory-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 20px 0 20px;
    --animate-duration: 0.8s;

    .inventory-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    div.totals {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;

        div.rarity-total {
            span {
                &.common {
                    color: #bfbfbf;
                }

                &.uncommon {
                    color: #1eff00;
                }

                &.rare {
                    color: #4d79ff;
                }

                &.epic {
                    color: #b366ff;
                }

                &.legendary {
                    color: #ff9900;
                }
            }
        }

        div.all-total {
            font-size: 32px;
            margin-bottom: 20px;
        }

        div.rarity-totals {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            width: 800px;
            justify-content: space-evenly;

            div.rarity-total {
                font-size: 20px;
                text-align: center;
                margin: 0px;
                width: 110px;
                height: 75px;
                // border: 1px solid red;
            }
        }
    }

    div.items {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;

        p.item-category-title {
            margin-bottom: 5px !important;
        }

        div.inventory-contents {
            margin: 0 10px 10px 10px;

            p {
                text-align: center;
                margin: 0;
                padding: 0;
                font-size: 24px;
            }

            .item-container {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                overflow-x: hidden;
                overflow-y: scroll;
                width: 374px;
                padding: 5px;
                height: 135px;
                border-radius: 5px;
                background-color: rgba(0, 0, 0, 0.6);
            }
        }
    }

    div.bottom-actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0 20px;
        align-items: center;

        span.delete-acc-cta {
            color: red;
            font-size: 20px;
            max-width: 560px;
            position: relative;
            top: -15px;
            text-align: center;
        }

        button {
            width: auto;
            margin-bottom: 30px;
            width: 200px;
            align-self: center;
        }
    }
}
