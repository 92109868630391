.confirm-skin-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    position: relative;
    width: 600px;

    .confirm-skin-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    .confirmation {
        font-size: 24px;
        text-align: center;

        span {
            color: green;
        }
    }

    p.not-registered {
        font-size: 24px;
        text-align: center;
        color: red;
    }

    .skin-preview {
        text-align: center;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        margin: 5px 0;
    }

    button {
        position: relative;
        // top: -10px;
        width: auto;
        // margin-bottom: 30px;
        // width: 200px;
        align-self: center;

        &:last-of-type {
            margin-bottom: 30px;
        }
    }
}
