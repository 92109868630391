.change-username-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    max-width: 600px;

    .change-username-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    .change-username-form {
        text-align: center;

        p {
            margin: 0;
            padding: 0;
        }

        div.errors {
            margin-bottom: 30px;
            p {
                font-size: 24px;
                // text-shadow: 0 0;
                color: red;
                // color: lighten(red, 10%);
            }
        }

        p.label {
            font-size: 24px;
        }

        p.current {
            font-size: 44px;
            margin-bottom: 40px;
        }

        input.change-username-input {
            color: black;
            text-shadow: 0 0;
            margin-top: 15px;
            margin-bottom: 40px;
            height: 40px;
            width: 300px;
            font-size: 32px;
            padding: 0 10px;
        }
    }

    .change-username-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            &:last-of-type {
                width: 150px;
            }
        }
    }

    button {
        margin: 0 15px 30px 15px;
        width: auto;
        width: 200px;
        align-self: center;
    }

    .change-username-btn {
        width: 300px;
    }
}
