.bundle-details-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    position: relative;
    max-width: 750px;

    .bundle-details-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    h3.bundle-title {
        margin: 0 0 15px 0;
        padding: 0;
        font-size: 36px;
        color: green;
        text-align: center;
    }

    p.bundle-description {
        margin: 0;
        padding: 0;
        font-size: 22px;
    }

    ul.bundle-features {
        // margin: 0;
        // padding: 0;
        li {
            font-size: 22px;
            // margin: 0;
            // padding: 0;

            span.rare-blue {
                color: #1e90ff;
            }

            span.epic-purple {
                color: #a020f0;
            }
        }
    }

    p.not-registered {
        font-size: 24px;
        text-align: center;
        color: red;
    }

    div.skins {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-bottom: 25px;
    }

    button {
        position: relative;
        top: -10px;
        width: auto;
        margin-bottom: 30px;
        width: 200px;
        align-self: center;
    }
}
