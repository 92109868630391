.character-skin-slot {
    .character-skin-slot-bg();
    height: 155px;
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    img {
        transform: scale(4);
        transform-origin: center;
    }

    &:hover {
        div.selection-parent {
            display: flex;
        }
    }

    &.selected {
        div.selection-parent {
            display: flex;
        }
    }

    div.selection-parent {
        display: none;
        div.selection-corner {
            position: absolute;
            height: 16px;
            width: 16px;
            background-image: url(../kit/selection-corner.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;

            &.top-left {
                top: 0%;
                left: 0%;
                animation: linear infinite;
                animation-name: topleftanim;
                animation-duration: 800ms;
            }
            &.top-right {
                top: 0%;
                right: 0%;
                animation: linear infinite;
                animation-name: toprightanim;
                animation-duration: 800ms;
                rotate: 90deg;
            }
            &.bottom-left {
                bottom: 0%;
                left: 0%;
                animation: linear infinite;
                animation-name: bottomleftanim;
                animation-duration: 800ms;
                rotate: 270deg;
            }
            &.bottom-right {
                bottom: 0%;
                right: 0%;
                animation: linear infinite;
                animation-name: bottomrightanim;
                animation-duration: 800ms;
                rotate: 180deg;
            }
        }
    }
}
