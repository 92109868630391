div.runprep-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 70px 40px 0 40px;
    --animate-duration: 0.8s;
    // width: 900px;
    // height: 545px;

    .runprep-header {
        .panel-header();
        align-self: center;
        position: absolute;
        top: -15px;
    }

    button.fullscreen-button {
        position: absolute;
        width: 150px;
        height: 35px;
        top: 25px;
        left: 25px;
        font-size: 18px;
        text-overflow: unset;

        &.is-full-screen {
            font-size: 14px;
        }
        span {
            position: relative;
            top: -10px;
            left: -10px;
        }
    }

    .runprep-content {
        display: flex;
        flex-direction: row;

        img.divider {
            position: relative;
            height: 250px;
            top: 95px;

            &.divider-one {
                left: -5px;
            }

            &.divider-two {
                // left: 5px;
                margin-left: 5px;
            }
        }

        .runprep-loadout {
            width: 300px;
            display: flex;
            flex-direction: column;
            align-items: center;

            p {
                width: 100%;
                text-align: center;
                margin: 0;
                padding: 0;
                font-size: 20px;
            }

            button {
                margin: 0;
            }

            div.character {
                justify-content: center;
                align-items: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 10px;
                div.character-skin-slot {
                }
            }

            div.weapons {
                p.weps-title {
                    margin-bottom: 2px;
                }
                div.weapon-slots {
                    display: flex;
                    flex-direction: row;
                    div.weapon-parent {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 125px;
                        button.insure-btn {
                            position: relative;
                            top: -12px;
                            transform: scale(0.5);
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;

                            img {
                                position: relative;
                                left: -5px;
                                top: 0px;
                                height: 50px;
                                width: 50px;
                                color: white;
                            }

                            &.insured {
                                opacity: 0;
                            }
                        }
                    }
                }
            }

            div.gear {
                position: relative;
                top: -20px;
                p.insurance-credits-label {
                    position: absolute;
                    bottom: 4px;
                    font-size: 18px;
                }
                p.gear-title {
                    margin-bottom: 2px;
                }
                div.gear-slots {
                    display: flex;
                    flex-direction: row;
                    div.gear-parent {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        width: 100px;
                        button.insure-btn {
                            position: relative;
                            top: -12px;
                            transform: scale(0.4);
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-start;

                            img {
                                position: relative;
                                left: -5px;
                                top: 0px;
                                height: 50px;
                                width: 50px;
                                color: white;
                            }

                            &.insured {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }

        .runprep-inventory {
            .runprep-selector {
                width: 300px;
                height: 300px;
                background-color: rgba(0, 0, 0, 0.6);
                padding: 8px;

                overflow-x: hidden;
                overflow-y: scroll;

                scrollbar-width: none;
                -ms-overflow-style: none;

                &::-webkit-scrollbar {
                    width: 0 !important;
                }

                h4 {
                    font-size: 24px;
                    margin: 0 0 7px 0;
                    padding: 0 0 0 8px;
                    width: 100%;
                    text-align: center;
                }

                .no-selection {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    background-color: none;

                    h4 {
                        width: 250px;
                        text-align: center;
                        font-size: 24px;
                    }
                }

                .character-skin-select {
                    display: flex;
                    justify-content: space-evenly;
                    flex-direction: row;
                    flex-wrap: wrap;
                    height: 200px;
                    div.character-skin-slot {
                        margin: 5px 0;
                    }
                }

                div.gear-select {
                    div.item-container {
                        div.item-slot {
                            margin: 4px;
                        }
                    }
                }

                div.weapon-select {
                    height: 200px;
                    display: flex;
                    flex-direction: column;

                    div.item-container {
                        height: 200px;
                        display: flex;
                        flex-wrap: wrap;
                        padding-left: 3px;

                        div.item-slot {
                            margin: 4px;
                        }
                    }
                }
            }

            div.runprep-insurance {
                max-width: 300px;
                position: relative;
                top: 30px;
                height: 175px;
                width: 100%;
                // border: 1px solid red;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                flex-direction: column;

                p {
                    text-align: center;
                    padding: 0;
                    margin: 0;
                    font-size: 24px;

                    span {
                        &.item-rarity-common {
                            color: #bfbfbf;
                        }
                        &.item-rarity-uncommon {
                            color: #1eff00;
                        }
                        &.item-rarity-rare {
                            color: #4d79ff;
                        }
                        &.item-rarity-epic {
                            color: #b366ff;
                        }
                        &.item-rarity-legendary {
                            color: #ff9900;
                        }
                    }

                    &.nothing {
                        color: red;
                    }

                    &.smol {
                        max-height: 50px;
                        overflow: hidden;
                        overflow-y: scroll;
                        text-align: center;
                        font-size: 16px;
                        max-width: 250px;
                        margin-top: 10px;
                        margin-bottom: 7px;
                        scrollbar-width: none;
                        -ms-overflow-style: none;

                        &::-webkit-scrollbar {
                            width: 0 !important;
                        }
                    }
                }

                button {
                    transform: scale(0.75);
                }
            }
        }

        .runprep-summary {
            margin-right: 10px;

            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            .runprep-stats {
                height: 350px;
                width: 235px;
                // border: 1px solid red;
                margin-bottom: 20px;

                p {
                    margin: 0;
                    padding: 0;

                    &.health {
                        position: relative;
                        top: -15px;
                        left: 10px;
                        text-align: center;
                        font-size: 26px;
                        color: green;
                    }
                }

                div.stats {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    position: relative;
                    left: 5px;

                    div.stats-labels {
                        .stat-category-title {
                            opacity: 0;
                        }
                    }

                    div.defensive-stats,
                    div.offensive-stats,
                    div.stats-labels {
                        div.stat-category-title {
                            font-size: 20px;
                            text-align: center;
                            text-decoration: underline;
                        }

                        div.stats-row {
                            p {
                                text-align: center;
                            }
                        }
                    }
                }
            }

            div.abilities-and-passives {
                // border: 1px solid red;
                background-color: rgba(0, 0, 0, 0.6);
                height: 100px;
                width: 240px;
                margin: 10px 0 0px 5px;
                overflow-x: hidden;
                overflow-y: scroll;
                padding: 3px 5px;
                border-radius: 5px;
                // position: relative;
                // left: 5px;

                scrollbar-width: none;
                -ms-overflow-style: none;

                .no-abilities {
                    text-align: center;
                    font-size: 18px;
                }

                &::-webkit-scrollbar {
                    width: 0 !important;
                }

                h5 {
                    width: 100%;
                    margin: 0;
                    padding: 0;
                    text-align: center;
                    font-size: 32px;
                }

                div.ability {
                    margin-bottom: 5px;
                    p {
                        text-align: center;
                        span {
                            font-weight: bold;
                        }
                    }
                }
            }

            .runprep-actions {
                display: flex;
                flex-direction: column;
                position: relative;
                left: 10px;

                button {
                    margin: 0;
                    padding: 0;
                    &.start-btn {
                        position: relative;
                        top: 0px;
                        transform: scale(0.85);
                    }
                    &.back-btn {
                        position: relative;
                        top: -10px;
                        transform: scale(0.75);
                        transform-origin: center center;
                    }
                }
            }
        }
    }
}
