//#region Fonts
/*****************************************************/
/*                                                   */
/*                     Fonts                         */
/*                                                   */
/*****************************************************/

@font-face {
    font-family: "alagardmedium";
    src: url("alagard-webfont.woff2") format("woff2"), url("alagard-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}
body {
    &.en {
        * {
            font-family: "alagardmedium", monospace, Helvetica, Arial, sans-serif;
        }
    }

    &.es,
    &.ru,
    &.pt {
        * {
            font-family: "Roboto", sans-serif;
        }
    }
}

*,
html,
body {
    text-shadow: 2px 2px #000000;
    color: white;
}
//#endregion

//#region Variables
/*****************************************************/
/*                                                   */
/*                    Variables                      */
/*                                                   */
/*****************************************************/
@discord-purple: #5865f2;
@facebook-blue: #4267b2;
@google-red: #db4437;
//#endregion

//#region UI Panels
/*****************************************************/
/*                                                   */
/*                     Panels                        */
/*                                                   */
/*****************************************************/
.metal-panel {
    transform: translateZ(1px);
    background-color: none !important;
    background: none !important;
    border-image-slice: 30% 21% 30% 21% fill;
    border-image-width: 35px 35px 35px 35px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: repeat repeat;
    border-image-source: url("./metal-frame.png");
    padding: 30px 40px 30px 40px;
}

.basic-panel {
    transform: translateZ(1px);
    background-color: none !important;
    background: none !important;
    border-image-slice: 29% 28% 29% 28% fill;
    border-image-width: 40px 40px 40px 40px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: repeat repeat;
    border-image-source: url("./basic-frame.png");
    padding: 25px 25px 25px 25px;
}

.panel-header {
    transform: translateZ(1px);
    background-image: url("./header-banner.png");
    background-repeat: no-repeat;
    width: 244px;
    height: 76px;
    background-size: 100% 100%;
    text-align: center;
    line-height: 76px;
    font-size: 44px;
}
//#endregion

//#region Button Styles
/*****************************************************/
/*                                                   */
/*                     Buttons                       */
/*                                                   */
/*****************************************************/
.social-btn {
    transform: translateZ(1px);
    font-weight: 500;
    height: 40px;
    text-decoration: none;
    width: 250px;
    text-align: center;
    // border-radius: 7px;
    transition: all 150ms linear;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;

    padding-top: 3px;
    letter-spacing: 1px;
    font-size: 20px;

    &.discord-oauth-btn {
        background-color: @discord-purple;

        &:hover {
            background-color: darken(@discord-purple, 10%);
        }

        &:active {
            background-color: darken(@discord-purple, 15%);
        }
    }

    &.facebook-oauth-btn {
        background-color: @facebook-blue;

        &:hover {
            background-color: darken(@facebook-blue, 10%);
        }

        &:active {
            background-color: darken(@facebook-blue, 15%);
        }
    }

    &.google-oauth-btn {
        background-color: @google-red;

        &:hover {
            background-color: darken(@google-red, 10%);
        }

        &:active {
            background-color: darken(@google-red, 15%);
        }
    }
}

.ui-button {
    transform: translateZ(1px);
    border: 0 !important;
    opacity: 0.9;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    text-align: center;
    cursor: pointer;
    font-size: 36px;
    padding: 0 12px 10px 12px;
    height: 60px;
    padding-left: 35px;
    padding-right: 35px;
    margin-bottom: 10px;
    line-height: 55px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &.btn-large {
        letter-spacing: 6px;
        font-size: 56px;
        margin: 10px 0;
        height: 84px;
        padding: 0;

        &:active {
            padding-top: 10px;
        }

        &:disabled {
            &:active {
                padding-top: 10px;
            }
        }
    }

    &:hover {
        opacity: 1;
    }

    &:active {
        padding-top: 3px;
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &:hover {
            opacity: 0.5;
        }

        &:active {
            padding: 0 12px 10px 12px;
        }
    }
}

.affirmative-button {
    .ui-button();
    background-image: url("./button-affirmative.png");

    &:active {
        background-image: url("./button-affirmative-active.png");
    }

    &:disabled {
        &:active {
            background-image: url("./button-affirmative.png");
        }
    }
}

.destructive-button {
    .ui-button();
    background-image: url("./button-destructive.png");

    &:active {
        background-image: url("./button-destructive-active.png");
    }

    &:disabled {
        &:active {
            background-image: url("./button-destructive.png");
        }
    }
}

.neutral-button {
    .ui-button();
    background-image: url("./button-neutral.png");
    background-color: rgb(2, 251, 251);
    background-blend-mode: multiply;

    &:active {
        background-image: url("./button-neutral-active.png");
    }

    &:disabled {
        &:active {
            background-image: url("./button-neutral.png");
            padding: 0 35px 10px;
        }
    }
}
//#endregion

//#region Item Styles
/*****************************************************/
/*                                                   */
/*                       SLOTS                       */
/*                                                   */
/*****************************************************/
.character-skin-slot-bg {
    border-image-slice: 43% 43% 43% 43% fill;
    border-image-width: 20px 20px 20px 20px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-image-source: url("./slot-character-skin.png");
    background-color: none !important;
    background: none !important;
    padding: 25px 25px 25px 25px;
}

.gear-slot {
    background-image: url("./slot-gear.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.weapon-slot {
    background-image: url("./slot-weapon.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
}
//#endregion

//#region Toasty
/*****************************************************/
/*                                                   */
/*        Theming the toasts from toastify           */
/*                                                   */
/*****************************************************/
.Toastify__toast-container--top-center {
    // margin-top: 20vh;
    margin-top: 150px;
}

.Toastify {
    pointer-events: all;
}

.Toastify__toast {
    text-align: center;
    border-image-slice: 31% 18% 31% 18% fill;
    border-image-width: 40px 40px 40px 40px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-style: solid;
    border-image-source: url("./toast.png");
    font-size: 24px;

    .Toastify__progress-bar {
        background-color: lighten(#655048, 30%);
    }
}
//#endregion

//#region Tooltip
/*****************************************************/
/*                                                   */
/*     Theming the tooltips from react-tooltip       */
/*                                                   */
/*****************************************************/
//#endregion
