div.spectating {
    position: absolute;
    bottom: 150px;
    text-align: center;

    h1 {
        font-size: 56px;
    }

    button {
        pointer-events: all;
    }
}
