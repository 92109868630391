div.full-screen-error {
    pointer-events: all;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
        font-size: 100px;
        margin: 0;
        padding: 0;
    }

    p {
        font-size: 24px;
        margin: 10px 0;
        padding: 0;
        max-width: 400px;
        text-align: center;
    }

    button {
        font-size: 24px;
        margin: 10px 0;
        max-width: 400px;
        text-align: center;
        padding: 7px 14px;
    }
}
