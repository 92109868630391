@keyframes growAndShrink {
    0% {
        transform: scale(1) rotate(-25deg);
    }
    100% {
        transform: scale(1.5) rotate(-15deg);
    }
}

.main-menu {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .bottom-container {
        .bottom-container-inner {
        }
    }

    .center-content {
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        transform-origin: center center;

        .bottom-container {
            position: relative;
            left: 20px;
            top: 10px;
        }

        div.game-logo {
            text-align: center;
            position: relative;
            margin: 0;
            padding: 0;
            --animate-duration: 1.5s;

            .game-logo-image {
                height: 300px;
            }

            span {
                position: absolute;
                bottom: 45px;
                right: -20px;
                font-size: 26px;
                letter-spacing: 3px;
                color: yellow;
                transform: rotate(-15deg);
                animation: growAndShrink 0.5s ease-in-out infinite alternate;
                -webkit-animation: growAndShrink 0.5s ease-in-out infinite alternate;
            }
        }

        .maintenance-mode {
            width: 1040px;
            height: 409px;
            display: flex;
            justify-content: center;
            align-items: center;

            h1 {
                max-width: 600px;
                text-align: center;
                font-size: 44px;
            }

            .loading-spinner {
                position: relative;
                top: -50px;
            }
        }

        .panels {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .main-menu-ad-panel {
                text-align: center;
                .basic-panel();

                .partners-btn {
                    margin-bottom: 0;
                    margin-top: 10px;
                }

                .ad {
                    // border: 1px solid red;
                    width: 300px;
                    height: 250px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    p {
                        text-align: center;
                        font-size: 36px;
                    }
                }
            }

            .main-menu-primary-panel {
                text-align: center;
                .metal-panel();
                margin: 0 20px;
                pointer-events: all;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 350px;
                // height: 374px;

                select {
                    width: 100%;
                    font-size: 24px;
                    color: black;
                    text-shadow: none;
                    position: relative;
                    top: -5px;
                }

                p.cta-header {
                    text-align: center;
                    max-width: 400px;
                    font-size: 32px;
                    margin: 10px 0;
                    padding: 0;
                }

                button.play-game-btn {
                    text-transform: uppercase;
                    width: 291px;
                }

                button.menu-btn {
                    width: 270px;
                    position: relative;

                    span.shop-new-tag {
                        position: absolute;
                        top: -10px;
                        right: 0;
                        transform: rotate(30deg);
                        color: yellow;
                        font-size: 20px;
                    }

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            .main-menu-profile-panel {
                .basic-panel();
                width: 300px;
                min-height: 200px;
                display: flex;
                padding: 15px 25px;

                .loading-user-details {
                    height: 200px;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    display: flex;

                    .loading-spinner {
                        height: 80px;
                        width: 80px;
                    }
                }

                .anonymous-user-details,
                .registered-user-details {
                    .welcome-back {
                        width: 266px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .wb-header {
                            text-align: center;
                            font-size: 24px;
                        }

                        .wb-signup-cta {
                            text-align: center;
                            margin-bottom: 10px;
                            font-size: 24px;
                        }

                        .wb-username {
                            text-align: center;
                            font-weight: bold;
                            font-size: 36px;
                            color: #9cadd7;
                            text-overflow: ellipsis;
                            max-width: 250px;
                            overflow: hidden;
                            text-shadow: 3px 3px #000000;
                        }
                    }
                }

                button.collection-log-btn {
                    font-size: 30px !important;
                }

                button.create-account-btn {
                    font-size: 28px !important;
                }

                button.sign-in-btn {
                    font-size: 36px !important;
                }

                .anonymous-user-details {
                    button {
                        transform-origin: top left;
                        transform: scale(0.95);
                        width: 265px;
                        font-size: 22px;
                    }
                }

                .registered-user-details {
                    .wb-username {
                        text-align: center;
                        margin-bottom: 5px;
                    }
                    button {
                        transform-origin: top left;
                        transform: scale(0.95);
                        width: 275px;
                        font-size: 22px;
                    }
                }
            }
        }
    }
}
