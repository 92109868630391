.main-menu-left-panel-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    pointer-events: all !important;
    flex-direction: column;
    width: 250px;
    min-height: 200px;
    padding: 0;
    margin: 0;

    button.none-button {
        background: none;
        border: none;
        outline: none;
        margin: 10px 20px;
        font-size: 16px;
        cursor: pointer !important;
    }

    button.patch-notes-btn {
        transform: scale(0.75);
    }

    h1 {
        margin: 0 0 20px 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        margin-bottom: 10px;
        opacity: 0.75;
        transition: all 300ms ease;

        &:last-of-type {
            margin-bottom: 0;
        }

        &:hover {
            opacity: 1;
        }
    }

    img {
        max-width: 250px;

        &.crazygames {
            height: 51px;
        }

        &.kongregate {
            height: 151px;
            border-radius: 20px;
        }

        &.iogames {
            max-width: 200px;
        }
    }
}
