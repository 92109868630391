@keyframes topleftanim {
    0% {
        left: 0%;
        top: 0%;
    }
    50% {
        left: 2%;
        top: 2%;
    }
    100% {
        left: 0%;
        top: 0%;
    }
}

@keyframes toprightanim {
    0% {
        right: 0%;
        top: 0%;
    }
    50% {
        right: 3%;
        top: 2%;
    }
    100% {
        right: 0%;
        top: 0%;
    }
}

@keyframes bottomleftanim {
    0% {
        left: 0%;
        bottom: 0%;
    }
    50% {
        left: 2%;
        bottom: 2%;
    }
    100% {
        left: 0%;
        bottom: 0%;
    }
}

@keyframes bottomrightanim {
    0% {
        right: 0%;
        bottom: 0%;
    }
    50% {
        right: 2%;
        bottom: 2%;
    }
    100% {
        right: 0%;
        bottom: 0%;
    }
}

div.item-slot {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    cursor: pointer;
    margin: 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: center;
    border-radius: 5px;

    img.damage-type-icon {
        position: absolute;
        bottom: -5px;
        right: -3px;
        height: 22px;
        width: 22px;
    }

    img.insured-lock {
        z-index: 5;
        height: 30px;
        position: absolute;
        bottom: -7px;
        left: -5px;
    }

    img {
        position: relative;
    }

    &:hover {
        div.selection-parent {
            display: flex;
        }
    }

    &.slot-rarity-common {
        box-shadow: inset 0 0 5px #bfbfbf;
    }

    &.slot-rarity-uncommon {
        box-shadow: inset 0 0 8px #1eff00;
    }

    &.slot-rarity-rare {
        box-shadow: inset 0 0 10px #4d79ff;
    }

    &.slot-rarity-epic {
        box-shadow: inset 0 0 13px #b366ff;
    }

    &.slot-rarity-legendary {
        box-shadow: inset 0 0 15px #ff9900;
    }

    &.slot-size-large {
        &.slot-rarity-common {
            box-shadow: inset 0 0 15px #bfbfbf;
        }

        &.slot-rarity-uncommon {
            box-shadow: inset 0 0 15px #1eff00;
        }

        &.slot-rarity-rare {
            box-shadow: inset 0 0 15px #4d79ff;
        }

        &.slot-rarity-epic {
            box-shadow: inset 0 0 15px #b366ff;
        }

        &.slot-rarity-legendary {
            box-shadow: inset 0 0 15px #ff9900;
        }
    }

    &.selected {
        div.selection-parent {
            display: flex;
        }
    }

    &.disable-hover {
        cursor: default;
        &:hover {
            div.selection-parent {
                display: none !important;
            }
        }
    }

    div.selection-parent {
        display: none;
        div.selection-corner {
            position: absolute;
            height: 16px;
            width: 16px;
            background-image: url(../kit/selection-corner.png);
            background-repeat: no-repeat;
            background-size: 100% 100%;

            &.top-left {
                top: 0%;
                left: 0%;
                animation: linear infinite;
                animation-name: topleftanim;
                animation-duration: 800ms;
            }
            &.top-right {
                top: 0%;
                right: 0%;
                animation: linear infinite;
                animation-name: toprightanim;
                animation-duration: 800ms;
                rotate: 90deg;
            }
            &.bottom-left {
                bottom: 0%;
                left: 0%;
                animation: linear infinite;
                animation-name: bottomleftanim;
                animation-duration: 800ms;
                rotate: 270deg;
            }
            &.bottom-right {
                bottom: 0%;
                right: 0%;
                animation: linear infinite;
                animation-name: bottomrightanim;
                animation-duration: 800ms;
                rotate: 180deg;
            }
        }
    }

    &.slot-type-inventory {
        height: 48px;
        width: 48px;
        background-image: url(../kit/slot-inventory.png);
        // background-image: url(../kit/item-slot-option-2.png);
    }

    &.slot-type-gear {
        height: 48px;
        width: 48px;
        background-image: url(../kit/slot-gear.png);

        &.slot-size-large {
            height: 72px;
            width: 72px;
        }
    }

    &.slot-type-weapon {
        height: 48px;
        width: 48px;
        background-image: url(../kit/slot-weapon.png);

        &.slot-size-large {
            height: 96px;
            width: 96px;

            img.damage-type-icon {
                height: 33px;
                width: 33px;
                bottom: 5px;
                right: 7px;
            }
        }
    }

    span.quantity {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 18px;
        z-index: 2;
    }
}

div.react-tooltip {
    z-index: 2000;
}

div.react-tooltip.fancy-tooltip {
    width: 400px;
    opacity: 1;
    z-index: 2000;
    border-image-slice: 48% 48% 48% 48% fill;
    border-image-width: 40px 40px 40px 40px;
    border-image-outset: 0px 0px 0px 0px;
    border-image-repeat: stretch stretch;
    border-image-source: url("../kit/tooltip-frame.png");
    border-style: solid;
    background: none;
    padding: 22px 30px 30px 30px;

    h3,
    p {
        padding: 0;
        margin: 0;
    }

    h3.item-name {
        margin-left: 45px;
        font-size: 24px;

        &.common {
            color: #bfbfbf;
        }

        &.uncommon {
            color: #1eff00;
        }

        &.rare {
            color: #4d79ff;
        }

        &.epic {
            color: #b366ff;
        }

        &.legendary {
            color: #ff9900;
        }
    }

    img.item-image {
        position: absolute;
        top: 47px;
        left: 35px;
    }

    p.item-type {
        margin-left: 45px;
        font-size: 22px;
        color: #9aadd8;
    }

    p.item-extract-info {
        font-size: 20px;
        margin-top: 10px;
        text-align: center;
    }

    p.item-click-info {
        text-align: center;
        vertical-align: top;
        font-size: 20px;

        img {
            height: 30px;
            position: relative;
            left: -10px;
            top: 7px;
        }
    }

    p.item-description,
    div.item-gameplay-info {
        // border: 1px solid red;
        margin-top: 5px;
        font-size: 18px;

        span.ability-line-item {
            display: flex;
            margin-top: 2px;
            margin-left: 25px;
        }
    }

    div.damage-type-line {
        margin-top: 5px;
        margin-bottom: 3px;
        display: flex;
        // justify-content: center;
        align-items: center;
        font-size: 19px;
        font-weight: bold;
        .damage-type-icon {
            margin-right: 5px;
            height: 22px;
            width: 22px;
        }

        span {
            position: relative;
            top: 1px;
        }
    }
}
