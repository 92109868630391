div.extraction-time-remaining {
    position: absolute;
    top: 5px;
    text-align: center;
    width: 400px;
    // transform: scale(0.5);
    transition: all 5000ms ease;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 0px 20px 10px 20px;
    border-radius: 20px;
    justify-content: center;
    flex-direction: column;
    display: flex;

    h2,
    h1,
    h3 {
        padding: 0;
        margin: 0;
        text-align: center;
    }

    h2 {
        display: none;
        font-size: 24px;
        color: darken(red, 10%);
        text-align: center;
        width: 100%;
    }

    h3 {
        font-size: 28px;
    }

    h1 {
        position: relative;
        top: 7px;
        font-size: 32px;
    }

    &.close-to-expiry {
        width: 400px;
        transform: scale(1);
        top: 50px;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        display: flex;
        padding: 0px 20px 7px 20px;

        h2,
        h1,
        h3 {
            text-align: center;
            color: darken(red, 10%);
        }

        h2 {
            text-align: center;
            align-items: center;
            justify-content: center;
            display: flex;
            margin-top: 10px;
        }
    }
}
