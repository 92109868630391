.item-test-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;

    .item-test-section {
        height: 325px;
        width: 800px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        .item-test-select {
            width: 600px;
            font-size: 30px;
            color: black;
            text-shadow: none;
            padding: 5px 0 0 5px;
        }

        .selected-item-details {
            display: flex;
            flex-direction: column;
            width: 600px;
            margin-top: 10px;

            .selected-item-details-row {
                display: flex;
                flex-direction: row;

                .selected-item-details-label {
                    font-weight: bold;
                    font-size: 24px;
                }

                .selected-item-details-value {
                    font-size: 24px;
                }
            }
        }

        .selected-item-slots {
            margin-top: 30px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            width: 600px;
        }
    }

    .item-test-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    .item-test-actions {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
    }
}
