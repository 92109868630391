.matchmaking-error-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    max-width: 600px;
    text-align: center;
    justify-content: center;
    align-items: center;

    .matchmaking-error-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    h3 {
        text-align: center;
        font-size: 36px;
        margin: 50px 0 20px 0;
        padding: 0;
    }

    p {
        font-size: 24px;

        margin: 0 0 20px 0;
        padding: 0;
    }

    .abandon-actions {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    button {
        width: auto;
        margin-bottom: 30px;
        width: 200px;
        align-self: center;
    }
}
