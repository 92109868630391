.vendors-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    max-width: 625px;
    text-align: center;

    .vendors-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    h1 {
        font-size: 42px;
        margin: 0px 40px 20px 40px;
    }

    button {
        width: auto;
        margin-bottom: 30px;
        width: 200px;
        align-self: center;
    }
}
