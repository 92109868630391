div.shop-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 70px 40px 0 40px;
    --animate-duration: 0.8s;
    width: 950px;
    height: 800px;

    .shop-header {
        .panel-header();
        align-self: center;
        position: absolute;
        top: -15px;
    }

    .back {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 25px;
        left: 41px;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
            margin: 0;
        }
    }

    .currencies {
        align-self: center;
        position: absolute;
        top: 25px;
        right: 45px;
        height: 50px;
        width: 300px;
        display: flex;
        flex-direction: row;

        .gems,
        .coins {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            img {
                margin: 0;
                padding: 0;
                height: 40px;
            }
        }
    }

    .insurance-actions {
        position: relative;
        left: 18px;
        margin-top: 35px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;

        button {
            font-size: 25px;
            margin: 0 10px;
        }
    }

    .top-shop-content {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        height: 64%;
        width: 100%;

        .left-shop-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 2;

            .bundles {
                display: flex;
                flex-direction: column;
                flex: 2;
                // border: 1px solid red;
                margin-right: 10px;

                .bundle {
                    height: 160px;
                    width: 100%;
                    cursor: pointer;
                    opacity: 1;
                    .metal-panel();

                    &:hover {
                        opacity: 0.75;
                    }
                    display: flex;
                    flex-direction: row;

                    .bundle-info {
                        // border: 1px solid red;
                        flex: 3;

                        .bundle-title {
                            font-weight: bold;
                            color: green;
                            font-size: 20px;
                            margin-bottom: 9px;
                        }

                        .bundle-description {
                            font-size: 18px;
                        }
                    }
                    .bundle-img {
                        // border: 1px solid red;
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        img {
                            height: 75px;
                        }
                    }
                }
            }
        }

        .right-shop-content {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 2;

            .skins {
                flex: 2;
                // border: 1px solid red;
                display: flex;
                flex-direction: row;
                position: relative;
                width: 100%;

                .skins-title {
                    position: absolute;
                    top: 0px;
                    left: 10px;
                    font-size: 36px;
                    pointer-events: none;
                }

                .skins-content {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-evenly;
                    align-items: center;

                    .skin-purchase {
                        flex-direction: column;
                        display: flex;
                        flex: 1;
                        width: 100px;

                        .price {
                            text-align: center;
                            position: relative;
                            font-size: 20px;
                            margin-top: 10px;
                            // left: -8px;

                            img {
                                display: inline;
                                height: 45px;
                                position: absolute;
                                left: 0;
                                top: -11px;
                            }
                        }
                    }
                }
            }

            .gems {
                flex: 1;
                // border: 1px solid red;
                display: flex;
                flex-direction: row;
                position: relative;
                margin-bottom: 35px;

                .gems-title {
                    position: absolute;
                    top: -37px;
                    left: 10px;
                    font-size: 36px;
                    pointer-events: none;
                }

                .gem-bundle {
                    cursor: pointer;
                    flex: 1;
                    border: 1px solid white;
                    border-radius: 5px;
                    margin: 3px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    opacity: 1;

                    &:hover {
                        opacity: 0.75;
                    }

                    img {
                        height: 75px;
                    }

                    .gems-text {
                        font-size: 20px;
                    }

                    .gems-price {
                        font-size: 24px;
                        font-weight: bold;
                    }
                }
            }
        }
    }

    .bottom-shop-content {
        position: relative;
        display: flex;
        width: 100%;
        height: 100px;
        // border: 1px solid cyan;
        top: 25px;
        padding-top: 45px;
        padding-left: 10px;
        left: 25px;

        button {
            position: absolute;
            bottom: -13px;
            right: 50px;
        }

        .insurance-title {
            position: absolute;
            top: 0px;
            left: 10px;
            font-size: 36px;
            pointer-events: none;
            span {
                color: green;
            }
            img {
                margin: 0;
                padding: 0;
                height: 30px;
                transform: scale(1.75);
            }
        }

        .insurance-description {
            font-size: 20px;
            position: relative;

            span {
                font-size: 22px;
                color: green;
            }
        }
    }
}
