/*****************************************************/
/*                                                   */
/*                Vendor Imports                     */
/*                                                   */
/*****************************************************/

@import "./ui/kit/vendor/toast.less";
@import "./ui/kit/vendor/animate.less";

/*****************************************************/
/*                                                   */
/*                Our Imports                        */
/*                                                   */
/*****************************************************/

@import "./ui/screens/UI_Responsiveness.less";
@import "./ui/screens/UI_I18NTweaks.less";

@import "./ui/components/UI_BottomContainer.less";
@import "./ui/components/UI_LeftContainer.less";
@import "./ui/components/UI_RightContainer.less";
@import "./ui/components/UI_MainMenuLeftPanelContainer.less";
@import "./ui/components/UI_MainMenuLeftContainer.less";
@import "./ui/components/UI_MainMenuRightContainer.less";

@import "./ui/screens/UI_BundleDetails.less";
@import "./ui/screens/UI_ConfirmSkinPurchase.less";
@import "./ui/screens/UI_PurchaseSuccessful.less";
@import "./ui/screens/UI_InsurancePurchase.less";

@import "./ui/kit/UI_Joysticks.less";
@import "./ui/kit/UI_Kit.less";
@import "./ui/screens/UI_Shop.less";
@import "./ui/screens/UI_Login.less";
@import "./ui/screens/UI_Stats.less";
@import "./ui/screens/UI_KitTest.less";
@import "./ui/screens/UI_Vendors.less";
@import "./ui/screens/UI_RunPrep.less";
@import "./ui/screens/UI_Partners.less";
@import "./ui/screens/UI_Register.less";
@import "./ui/screens/UI_ItemTest.less";
@import "./ui/screens/UI_Rankings.less";
@import "./ui/screens/UI_Settings.less";
@import "./ui/screens/UI_MainMenu.less";
@import "./ui/screens/UI_Inventory.less";
@import "./ui/screens/UI_HowToPlay.less";
@import "./ui/screens/UI_PatchNotes.less";
@import "./ui/components/UI_Socials.less";
@import "./ui/components/UI_ItemSlot.less";
@import "./ui/screens/UI_CollectionLog.less";
@import "./ui/components/UI_TopLeftHud.less";
@import "./ui/screens/UI_RunInProgress.less";
@import "./ui/components/UI_LoadingBar.less";
@import "./ui/components/UI_Spectating.less";
@import "./ui/components/UI_RegionPopUp.less";
@import "./ui/components/UI_TopRightHud.less";
@import "./ui/screens/UI_ChangeUsername.less";
@import "./ui/components/UI_YouDiedPanel.less";
@import "./ui/components/UI_FindingMatch.less";
@import "./ui/components/UI_BottomLeftHud.less";
@import "./ui/components/UI_ItemContainer.less";
@import "./ui/screens/UI_MatchmakingError.less";
@import "./ui/components/UI_LoadingSpinner.less";
@import "./ui/components/UI_InRunInventory.less";
@import "./ui/components/UI_ServerEventFeed.less";
@import "./ui/components/UI_FullScreenError.less";
@import "./ui/components/UI_GearAndWeaponHUD.less";
@import "./ui/components/UI_UserIdentityError.less";
@import "./ui/components/UI_CharacterSkinSlot.less";
@import "./ui/components/UI_ItemContainerPanel.less";
@import "./ui/components/UI_ExtractionInProgress.less";
@import "./ui/components/UI_ExtractionSuccessful.less";
@import "./ui/components/UI_ExtractionTimeRemaining.less";

/*****************************************************/
/*                                                   */
/*              Global Styles                        */
/*                                                   */
/*****************************************************/

img.hidden-preload {
    display: none;
}

* {
    image-rendering: crisp-edges;
    image-rendering: pixelated;
    box-sizing: border-box;
    user-select: none;
}

html,
body {
    height: 100%;
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
}

option {
    color: black !important;
}

body {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #282828;
}

img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

#stats {
    display: none;
    position: absolute;
    align-items: center;
    justify-content: center;
    bottom: 0;
}

#stats canvas {
    display: flex !important;
}

#game-container {
    height: 100%;
    width: 100%;
    border: none;
    border-radius: 0;
    background-color: #000;
    position: relative;
    background-image: url("./ui/kit/bg.png");
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

#game-container .drag-handle {
    height: 30px;
    width: 30px;
    background-color: green;
    position: absolute;
    bottom: 5px;
    right: 5px;
}

#dom-load {
    background-color: black;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

#dom-load span {
    font-size: 24px;
}

#dom-load img {
    width: 100px;
    height: 100px;
}

#ui-root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    pointer-events: none;
}

.game-hud {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
}

.screen-router {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main-menu .center-content {
    pointer-events: all;
}

.left-inventory-containers {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    transform-origin: top left;
    left: 0;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);

    div.in-run-inventory-panel {
        margin-right: 20px;
    }
}
