.how-to-play-panel {
    .metal-panel();
    width: 900px;
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;

    .how-to-play-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }

    p {
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 15px;
    }

    h2 {
        font-size: 32px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    div.control-list-parent {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
        padding-left: 50px;

        ul.control-list {
            // border: 1px solid red;
            list-style: none;
            padding: 20px;
            margin: 0;

            &.chonky-control-list {
                li.control {
                    div.control-label {
                        width: 100px;
                    }
                }
            }

            li.control {
                font-size: 22px;
                margin-bottom: 8px;
                display: flex;
                flex-direction: row;
                align-items: center;

                &.extraction-control {
                    position: relative;
                    top: 20px;
                    left: 20px;
                    img.extraction {
                        height: 150px;
                        width: 150px;
                    }

                    .control-description {
                        text-align: center;
                        position: relative;
                        top: 120px;
                        left: -90px;
                        width: 150px;
                    }
                }

                &:last-of-type {
                    margin-bottom: 0;
                }

                div.control-label {
                    display: flex;
                    align-items: center;
                    margin-right: 10px;
                    width: 75px;

                    &.mousedrag {
                        margin-right: 50px;
                    }

                    &.mouse {
                        margin-left: 50px;
                        justify-content: center;
                        align-items: center;
                        width: 50px;
                        position: relative;

                        img {
                            position: relative;
                            top: -5px;
                            width: 40px;
                            height: 56px;
                            margin-right: 10px;

                            &.mousearrow {
                                height: 28px;
                                width: 28px;
                                position: absolute;

                                &.mar {
                                    transform: rotate(180deg);
                                    right: -35px;
                                    top: 7px;
                                }

                                &.mal {
                                    left: -35px;
                                    top: 7px;
                                }

                                &.mau {
                                    transform: rotate(90deg);
                                    left: 6px;
                                    right: 0;
                                    top: -38px;
                                }

                                &.mad {
                                    transform: rotate(-90deg);
                                    left: 6px;
                                    bottom: -28px;
                                    top: unset;
                                }
                            }

                            &.active {
                                opacity: 1;
                                transform: scale(1) !important;
                            }
                        }
                    }

                    span {
                        padding: 0 10px;
                    }

                    img {
                        height: 32px;
                        width: 32px;

                        opacity: 0.7;

                        &.space {
                            height: 32px;
                            width: 64px;
                        }

                        &.active {
                            // position: relative;
                            // top: 2px;
                            opacity: 1;
                            transform-origin: bottom;
                            transform: scaleY(0.8);
                        }
                    }
                }
                div.control-description {
                    margin-left: 5px;
                    width: 120px;
                }
            }
        }
    }

    button {
        width: auto;
        margin-bottom: 30px;
        width: 200px;
        align-self: center;
    }
}
