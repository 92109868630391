div.in-run-inventory-panel {
    .metal-panel();
    border-radius: 20px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    pointer-events: all;
    user-select: all;
    // position: absolute;
    // left: 0;
    // top: 50%;
    // transform: translateY(-50%);
    // bottom: 0;
    width: 288px;
    height: 415px;

    div.your-inventory {
        height: 285px;
        width: 100%;
        // margin-bottom: 50px;
        // padding-top: 40px;
        // padding-bottom: 100px;

        h3 {
            text-align: center;
            font-size: 20px;
            padding: 0;
            margin: 10px 0;
        }

        .item-container {
            // width: 675px;
            overflow-y: scroll;
            // height: 100%;
            height: 188px;
            width: 100%;
            padding: 0;
            margin: 0;
            background-color: rgba(0, 0, 0, 0.6);
        }

        div.extraction-notification {
            margin-top: 10px;
            display: flex;
            flex-direction: row;
            position: relative;
            p {
                position: relative;
                top: 3px;
                text-align: center;
                margin: 0;
                padding: 0 8px;
                font-size: 20px;
            }
            img.extraction-pointer {
                position: absolute;
                top: 65px;
                left: 65px;
                height: 40px;
            }
            img.extraction-preview {
                height: 100px;
            }
        }
    }
}
