div.kittest-panel {
    .metal-panel();
    pointer-events: all;
    z-index: 2;
    display: flex;
    flex-direction: column;
    padding: 0 40px 0 40px;
    --animate-duration: 0.8s;
    height: 500px;
    width: 500px;

    .kittest-header {
        .panel-header();
        align-self: center;
        position: relative;
        top: -15px;
    }
}
